import React, { useState } from 'react';
import classnames from 'classnames';
import { useAlert } from 'react-alert';

import Icon from '@/components/Icon';
import ExternalLink from '@/components/ExternalLink';

import { useTransaction, useCreateBankSlip } from '@/api';

const REFETCH_INTERVAL = 5000;

const propTypes = {};

function GenerateBankSlipButton() {
  const [isLoading, setIsLoading] = useState(false);
  const [pollingEnabled, setPollingEnabled] = useState(false);
  const [transactionId, setTransactionId] = useState(null);
  const [bankSlipUrl, setBankSlipUrl] = useState(null);

  const alert = useAlert();
  const createBankSlipMutation = useCreateBankSlip();

  const handleBankSlipError = () => {
    alert.show(
      <div>
        <h6>Infelizmente, não conseguimos gerar o seu boleto</h6>
        <div>Aguarde alguns minutos e tente novamente.</div>
        <div>Se o erro persistir, entre em contato com o nosso suporte.</div>
      </div>,
      { variant: 'danger' }
    );
  };

  const onCreate = () => {
    setIsLoading(true);

    createBankSlipMutation.mutate(null, {
      onSuccess: ({ data }) => {
        setPollingEnabled(true);
        setTransactionId(data.id);
      },
      onError: () => {
        setIsLoading(false);
        handleBankSlipError();
      }
    });
  };

  useTransaction({
    billingTransactionId: transactionId,
    config: {
      refetchInterval: REFETCH_INTERVAL,
      cacheTime: 0,
      enabled: Boolean(pollingEnabled && transactionId),
      onSuccess: ({ data }) => {
        switch (data.statusName) {
          case 'Aguardando Pagto':
          case 'Aprovado':
          case 'Completo':
            return;
          case 'Em análise':
            setPollingEnabled(false);
            setIsLoading(false);

            setBankSlipUrl(data.transactionDetails?.pdf);
            return;
          default:
            setPollingEnabled(false);
            setIsLoading(false);
            handleBankSlipError();
        }
      }
    }
  });

  return (
    bankSlipUrl
      ? <DownloadButton bankSlipUrl={bankSlipUrl} />
      : <GenerateButton onClick={onCreate} isLoading={isLoading} />
  );
}

function GenerateButton({ onClick, isLoading }) {
  const buttonText = isLoading ? 'Gerando...' : 'Gerar boleto';

  return (
    <div
      className={classnames(
        'd-inline-block',
        'text-primary',
        'cursor-pointer',
        { 'opacity-50': isLoading }
      )}
      onClick={isLoading ? null : onClick}
    >
      <Icon className='me-1' name='download' />

      <span>{buttonText}</span>
    </div>
  );
}

function DownloadButton({ bankSlipUrl }) {
  return (
    <ExternalLink target='_blank' href={bankSlipUrl}>
      <Icon className='me-1' name='download' />

      Baixar boleto
    </ExternalLink>
  );
}

GenerateBankSlipButton.propTypes = propTypes;

export default GenerateBankSlipButton;
