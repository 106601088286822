import React from 'react';
import { Col, Row } from 'react-bootstrap';

import {
  parseDate,
  getTimelineDayLabel,
  toTimeStringWithSeparator
} from '@/date';
import IconLabel from '@/components/IconLabel';

function DateField({
  labelName,
  stringDate
}) {
  const dateStringFormater = (date) => {
    if (!date) {
      return 'Indefinido';
    }

    const parsedDate = date ? parseDate(date) : null;
    const dateLabel = parsedDate && getTimelineDayLabel(parsedDate);
    const formattedDate = (
      parsedDate ? toTimeStringWithSeparator(dateLabel, parsedDate) : null
    );
    return formattedDate;
  };

  return (
    <Row className='align-items-center mb-3'>
      <Col sm={6}>
        <IconLabel
          text={labelName}
          className='me-1 text-dark-gray'
        />
      </Col>
      <Col sm={6}>
        <span className='text-nowrap'>{dateStringFormater(stringDate)}</span>
      </Col>
    </Row>
  );
}

export default DateField;
