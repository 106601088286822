import React from 'react';
import { Col, Row } from 'react-bootstrap';

import IconLabel from '@/components/IconLabel';
import { EditInPlace, MaskedInput } from '@/components/Inputs';
import { applyMask } from '@/masks';

function CnpjMaskerInputField({
  inputValue,
  labelName,
  inputName,
  onEditEntity
}) {
  const renderValue = (value, defaultText = 'Adicionar') => (
    applyMask(value, 'cnpj') || <span className='text-medium-gray'>{defaultText}</span>
  );

  return (
    <Row className='align-items-center mb-3'>
      <Col sm={6}>
        <IconLabel
          text={labelName}
          className='me-3 text-dark-gray'
        />
      </Col>
      <Col sm={6}>
        <EditInPlace
          name={inputName}
          as={MaskedInput}
          value={inputValue}
          onConfirm={onEditEntity}
          maskType='cnpj'
        >
          <div style={{ whiteSpace: 'nowrap' }}>{renderValue(inputValue)}</div>
        </EditInPlace>
      </Col>
    </Row>
  );
}

export default CnpjMaskerInputField;
