import React from 'react';

import DetailsCard from '@/components/EntityModal/DetailsCard';
import ShowMoreDescription from '@/components/EntityModal/ShowMoreDescription';
import EditSelectField from '@/components/EntityModal/EditSelectField';
import DateField from '@/components/EntityModal/DateField';
import CpfMaskerInputField from '@/components/EntityModal/CpfMaskerInputField';
import EditInputField from '@/components/EntityModal/EditInputField';
import BirthdayField from '@/components/EntityModal/BirthdayField';
import BirthYearField from '@/components/EntityModal/BirthYearField';
import UserField from '@/components/EntityModal/UserField';
import EditUserField from '@/components/EntityModal/EditUserField';
import { canEditPerson } from '@/policies';
import DetailsCustomField from '@/components/EntityModal/DetailsCustomField';
import { filter } from 'lodash';

const renderBasicDataCustomFields = (customFields, entity, entityType, onEditEntity) => (
  filter(customFields, { section: 'basic_data' }).map(
    ({ identifier, customFieldOptions, type, name }) => (
      <DetailsCustomField
        onEdit={(params, done) => onEditEntity(params, done, { ...entity, entityType })}
        key={identifier}
        identifier={identifier}
        customFieldOptions={customFieldOptions}
        type={type}
        name={name}
        rawValue={entity?.customFields[identifier]}
        placeholder='Adicionar'
      />
    )
  )
);

function PersonBasicData({
  person,
  onEditEntity,
  categories,
  organizations,
  leadOrigins,
  entityType,
  entityCustomFields
}) {
  const categoriesAdapted = categories.map((category) => ({
    label: category.name,
    value: category.id
  }));

  const leadOriginsAdapted = leadOrigins.map((origin) => ({
    label: origin.name,
    value: origin.id
  }));

  const organizationsAdapted = organizations.map((organization) => ({
    label: organization.name,
    value: organization.id
  }));

  const basicDataCustomFields = renderBasicDataCustomFields(
    entityCustomFields,
    person,
    entityType,
    onEditEntity
  );

  return (
    <DetailsCard title='Dados básicos da pessoa' className='mb-3'>
      <ShowMoreDescription
        description={person?.description}
        onEditEntity={(params, done) => onEditEntity(params, done, { ...person })}
      />
      <CpfMaskerInputField
        inputValue={person?.cpf}
        labelName='CPF'
        inputName='cpf'
        onEditEntity={(params, done) => onEditEntity(params, done, { ...person })}
      />
      <EditSelectField
        labelName='Categoria'
        selectName='category_id'
        onEditEntity={(params, done) => onEditEntity(params, done, { ...person })}
        options={categoriesAdapted}
        entity={person.category}
        entityName={person.category?.name}
        entityId={person.category?.id}
      />
      <EditSelectField
        labelName='Empresa'
        selectName='organization_id'
        onEditEntity={(params, done) => onEditEntity(params, done, { ...person })}
        options={organizationsAdapted}
        entity={person.organization}
        entityName={person.organization?.name}
        entityId={person.organization?.id}
        link={`?organizationId=${person.organizationId}`}
      />
      <EditInputField
        inputValue={person?.jobTitle}
        labelName='Cargo'
        inputName='job_title'
        onEditEntity={(params, done) => onEditEntity(params, done, { ...person })}
      />
      <BirthdayField
        inputValue={person?.birthday}
        labelName='Aniversário'
        inputName='birthday'
        onEditEntity={(params, done) => (
          onEditEntity(params, done, { ...person })
        )}
      />
      <BirthYearField
        inputValue={person?.birthYear}
        labelName='Ano nasc.'
        inputName='birth_year'
        onEditEntity={(params, done) => onEditEntity(params, done, { ...person })}
      />
      <EditSelectField
        labelName='Origem'
        selectName='lead_origin_id'
        onEditEntity={(params, done) => onEditEntity(params, done, { ...person })}
        options={leadOriginsAdapted}
        entity={person.leadOrigin}
        entityName={person.leadOrigin?.name}
        entityId={person.leadOrigin?.id}
      />
      <UserField
        labelName='Cadastrado por'
        avatarName={person.user?.name}
        avataerUrl={person.user?.avatarUrl}
      />
      <EditUserField
        labelName='Responsável'
        selectName='owner_user_id'
        avatarId={person?.ownerUser?.id}
        avatarName={person?.ownerUser?.name}
        avatarUrl={person?.ownerUser?.avatarUrl}
        onEditEntity={(params, done) => onEditEntity(params, done, { ...person })}
        canEditPolicy={canEditPerson}
      />
      <DateField labelName='Data de cadastro' stringDate={person?.createdAt} />
      <DateField labelName='Última atualização' stringDate={person?.updatedAt} />

      {basicDataCustomFields}
    </DetailsCard>
  );
}

export default PersonBasicData;
