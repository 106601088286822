import React from 'react';
import EntityTitle from '@/components/EntityModal/EntityTitle';
import Menu from '@/components/EntityModal/Menu';
import Tabs from '@/components/EntityModal/Person/Header/Tabs';
import { usePerson, useOnUpdate, useOnDelete } from '@/contexts/personModal';
import { canEditPerson } from '@/policies';
import { personEditPath } from '@/routes';
import SelectOwnerUser from '@/components/EntityModal/SelectOwnerUser';
import HeaderAvatar from '@/components/EntityModal/HeaderAvatar';
import RestrictWarningBadge from '@/components/EntityModal/RestrictWarningBadge';
import AddDealButton from '@/components/EntityModal/AddDealButton';

function Header() {
  const person = usePerson();
  const onUpdate = useOnUpdate();
  const onDelete = useOnDelete();

  return (
    <div className='bg-white rounded-top px-4 pt-4 mb-3'>
      <div className='mb-4 d-flex justify-content-between'>
        <div className='d-flex flex-fill'>
          <HeaderAvatar
            entityType='person'
            avatarUrl={person.avatar?.url}
          />

          <div className='py-1 flex-fill'>
            <div className='w-100 d-flex justify-content-between mb-2'>
              <EntityTitle
                className='flex-fill'
                onUpdate={onUpdate}
                entity={{ title: person.name }}
              />

              <div className='d-flex align-items-center'>
                <RestrictWarningBadge entity={person} loading={false} />
              </div>
            </div>

            <div className='w-100 d-flex justify-content-between'>
              <div>
                <SelectOwnerUser
                  ownerUser={person.ownerUser}
                  canEditPolicy={canEditPerson}
                  onUpdate={onUpdate}
                />
              </div>

              <div className='d-flex'>
                <AddDealButton
                  entityId={person.id}
                  entityType='person'
                />
                <Menu
                  entity={person}
                  entityType='person'
                  loading={false}
                  onDelete={onDelete}
                  editPath={personEditPath(person.id)}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <Tabs />
    </div>
  );
}

export default Header;
