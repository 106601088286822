import React from 'react';
import PropTypes from 'prop-types';
import { createPortal } from 'react-dom';
import { Col, Container, Row } from 'react-bootstrap';
import Button from '@/components/Button';
import LoadingButton from '@/components/LoadingButton';
import { useCheckoutContext } from '@/contexts';

const propTypes = {
  onClickNext: PropTypes.func.isRequired,
  onClickPrev: PropTypes.func,
  buttonNextText: PropTypes.string,
  loadingTextButton: PropTypes.string,
  isLoading: PropTypes.bool,
  disabled: PropTypes.bool,
  buttonClass: PropTypes.string,
  buttonVariant: PropTypes.string
};

const defaultProps = {
  buttonClass: 'width-4',
  buttonVariant: 'primary'
};

/**
 * Esse componente usa Portais do react para renderizar o seu conteúdo no elemento
 * que possuir o ID `billing-footer` pois em alguns casos o botão de "Próximo" deste
 * componente precisava ser usado dentro de outro componente e com ação diferente ao clicar
 */
function BillingFooter(props) {
  const {
    onClickNext,
    buttonNextText,
    isLoading,
    loadingTextButton,
    onClickPrev,
    disabled,
    buttonClass,
    buttonVariant
  } = props;

  const { prevStep } = useCheckoutContext();

  const element = document.getElementById('billing-footer');

  if (!element) {
    return null;
  }

  return createPortal(
    <Container className='py-4'>
      <Row>
        <Col className='d-flex justify-content-end'>
          <Button
            className='me-3'
            onClick={onClickPrev || prevStep}
            variant='outline-dark-gray'
            disabled={isLoading}
          >
            Voltar
          </Button>

          <LoadingButton
            className={buttonClass}
            onClick={onClickNext}
            isLoading={isLoading}
            loadingText={loadingTextButton}
            disabled={disabled}
            variant={buttonVariant}
          >
            {buttonNextText}
          </LoadingButton>
        </Col>
      </Row>
    </Container>,
    element
  );
}

const closePropTypes = {
  onClick: PropTypes.func
};

const closeDefaultProps = {
  onClick: () => { }
};

function Close({ onClick }) {
  const element = document.getElementById('billing-footer');

  if (!element) {
    return null;
  }

  return createPortal(
    <Container className='py-4'>
      <Row>
        <Col className='d-flex justify-content-end'>
          <Button
            className='px-6'
            onClick={onClick}
            variant='outline-dark-gray'
          >
            Fechar
          </Button>
        </Col>
      </Row>
    </Container>,
    element
  );
}

Close.propTypes = closePropTypes;
Close.defaultProps = closeDefaultProps;

BillingFooter.Close = Close;

BillingFooter.defaultProps = defaultProps;
BillingFooter.propTypes = propTypes;

export default BillingFooter;
