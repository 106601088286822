import React from 'react';
import PropTypes from 'prop-types';
import { useWhatsappPopoverContext } from '@/contexts/whatsappPopover';
import ContactListPerson from '@/feature/whatsapp/ContactListPerson';
import ContactListOrganization from '@/feature/whatsapp/ContactListOrganization';
import ContactListDeal from '@/feature/whatsapp/ContactListDeal';
import DetailsContactForm from '@/components/EntityModal/Deal/DetailsContactForm';

DetailsWhatsappContactList.defaultProps = {
  entity: {},
  relatedEntity: {},
  entityType: ''
};

DetailsWhatsappContactList.propTypes = {
  entity: PropTypes.object,
  relatedEntity: PropTypes.object,
  entityType: PropTypes.string
};

const ContactList = ({ entityType, entity, relatedEntity }) => {
  const components = {
    organization: ContactListOrganization,
    person: ContactListPerson,
    deal: ContactListDeal
  };

  const Component = components[entityType];

  return <Component entity={entity} relatedEntity={relatedEntity} />;
};

function DetailsWhatsappContactList() {
  const {
    entity,
    relatedEntity,
    entityType,
    selectedEntity
  } = useWhatsappPopoverContext();

  return (
    <div className='deal-voip-height overflow-hidden overflow-y-auto'>
      {
        !selectedEntity
          ? (
            <>
              <div className='d-flex justify-content-between mb-4'>
                <h4>Contatos</h4>
              </div>

              <ContactList
                entity={entity}
                relatedEntity={relatedEntity}
                entityType={entityType}
              />
            </>
          )
          : (<DetailsContactForm />)
      }
    </div>
  );
}

export default DetailsWhatsappContactList;
