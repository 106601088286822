import React from 'react';
import { useCheckoutContext } from '@/contexts';
import CardPlanDetails from '@/components/Billing/Steps/CardPlanDetails';

function ChoosePlan() {
  const {
    usersCount,
    setUsersCount,
    checkoutResumeData,
    activeUsersCount,
    accountData,
    currentPlanData
  } = useCheckoutContext();

  const isTrial = currentPlanData.subscriptionType === 'trial';

  return (
    <CardPlanDetails
      currentValuePerUser={checkoutResumeData?.monthlyInfo.valuePerUser}
      maxUsersCount={usersCount}
      onChangeUsersCount={setUsersCount}
      currentActiveUsersCount={activeUsersCount}
      periodicity={checkoutResumeData.periodicity}
      planName={checkoutResumeData.name}
      salespersonNumber={accountData.salespersonNumber}
      isTrial={isTrial}
      subscriptionMaxUsers={currentPlanData.maxUsersCount}
    />
  );
}

export default ChoosePlan;
