import React from 'react';
import { createStore } from 'zustand';

export function useOrganizationModalStore() {
  return React.useState(() => createStore((set) => ({
    changeTab: (tab) => (
      set({
        activeTab: tab
      })
    ),
    activeTab: 'activities'
  })));
}
