import { useMutation } from 'react-query';
import { post } from '@/lib/fetch';

export async function createTrial(params = {}) {
  const { data } = await post('/billing/trials', params);

  return data;
}

export async function requestTrialDowngrade(params = {}) {
  const { data } = await post('/billing/trials/request_downgrade', params);

  return data;
}

export function useCreateTrial({ config = {} } = {}) {
  return useMutation(
    createTrial,
    { ...config }
  );
}

export function useRequestTrialDowngrade({ config = {} } = {}) {
  return useMutation(
    requestTrialDowngrade,
    { ...config }
  );
}
