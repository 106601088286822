import { init, setUser } from '@sentry/react';
import jwt from 'jwt-simple';

import { RAILS_ENV, SENTRY_DSN } from '@/config';
import { getCookie } from '@/browser';

init({
  environment: RAILS_ENV,
  dsn: SENTRY_DSN
});

const authCookie = getCookie('AGENDOR');
if (authCookie) {
  const jwtContent = jwt.decode(authCookie, undefined, true);

  setUser({
    email: jwtContent.email,
    userId: jwtContent.sub,
    accountId: jwtContent.account
  });
}

export { ErrorBoundary, captureException } from '@sentry/react';
