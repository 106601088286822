import { useQuery, useInfiniteQuery, useMutation, useQueryClient } from 'react-query';
import { get, post, patch, _delete } from '@/lib/fetch';
import { dealTemplatesKeys } from '@/api/deal-template';

export const dealsKeys = {
  all: ['deals'],
  infiniteAll: ['deals', 'infinite'],
  infiniteLists: () => [...dealsKeys.infiniteAll, 'list'],
  infiniteList: (params) => ([...dealsKeys.infiniteLists(), params]),
  details: () => [...dealsKeys.all, 'detail'],
  detail: (dealId) => [...dealsKeys.details(), dealId]
};

export async function getDeals(params = {}) {
  const { data } = await get('/deals', params);

  return data;
}

export async function getDeal(dealId) {
  const { data } = await get(`/deals/${dealId}`);

  return data;
}

export async function createDeal(params = {}) {
  const { data } = await post('/deals', params);

  return data;
}

export async function updateDeal({ dealId, params = {} }) {
  const { data } = await patch(`/deals/${dealId}`, params);

  return data;
}

export async function deleteDeal({ dealId }) {
  const { data } = await _delete(`/deals/${dealId}`);

  return data;
}

export function useInfiniteDeals({ params = {}, config = {} } = {}) {
  return useInfiniteQuery(
    dealsKeys.infiniteList(params),
    ({ pageParam: page = 1 }) => getDeals({ ...params, page }),
    {
      ...config,
      getNextPageParam: (lastPage) => lastPage.meta.next
    }
  );
}

export function useDeal({ dealId, config = {} } = {}) {
  return useQuery({
    ...config,
    queryKey: dealsKeys.detail(dealId),
    queryFn: () => getDeal(dealId)
  });
}

export function useCreateDeal({ config = {} } = {}) {
  const queryClient = useQueryClient();

  const onSuccess = () => queryClient.invalidateQueries(dealTemplatesKeys.all);

  return useMutation(
    createDeal,
    {
      onSuccess,
      ...config
    }
  );
}

export function useUpdateDeal({ config = {} } = {}) {
  const queryClient = useQueryClient();

  const onSuccess = () => queryClient.invalidateQueries(dealsKeys.all);

  return useMutation(
    updateDeal,
    {
      onSuccess,
      ...config
    }
  );
}

export function useDeleteDeal({ config = {} } = {}) {
  const queryClient = useQueryClient();

  const onSuccess = () => queryClient.invalidateQueries(dealsKeys.all);

  return useMutation(
    deleteDeal,
    {
      onSuccess,
      ...config
    }
  );
}
