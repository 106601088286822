import React, { createContext, useContext, useState } from 'react';

const VoipPopoverContext = createContext(undefined);

export function useVoipPopoverContext() {
  const context = useContext(VoipPopoverContext);

  if (context === undefined) {
    throw new Error('useVoipPopoverContext must be used within VoipPopoverProvider');
  }

  return context;
}

export function useSwitchTab() {
  return useVoipPopoverContext().switchTab;
}

export function useCurrentTab() {
  return useVoipPopoverContext().currentTab;
}

export function VoipPopoverProvider({
  children, entity, entityType, onUpdatePerson, onUpdateOrganization
}) {
  const [tab, setTab] = useState('contacts');
  const [loadingCall, setLoadingCall] = useState(false);

  const value = {
    currentTab: tab,
    switchTab: setTab,
    entity,
    entityType,
    onUpdatePerson,
    onUpdateOrganization,
    loadingCall,
    setLoadingCall
  };

  return (
    <VoipPopoverContext.Provider value={value}>
      {children}
    </VoipPopoverContext.Provider>
  );
}
