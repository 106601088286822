import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useAlert } from 'react-alert';
import { FileInput } from '@uppy/react';
import { useUppy, createUppy } from '@/lib/uppy';
import { FILE_EXTENSIONS } from '@/utils';
import Button from '@/components/Button';
import Icon from '@/components/Icon';
import ActivitiesFiles from '@/components/EntityModal/Activities/ActivitiesFiles';
import Tooltip from '@/components/Tooltip';
import { captureException } from '@sentry/react';

const MAX_FILE_SIZE = 50;
const MAX_NUMBER_OF_FILES = 30;

AttachmentUpload.propTypes = {
  name: PropTypes.string,
  value: PropTypes.array,
  clearFiles: PropTypes.bool,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  onUploadStart: PropTypes.func,
  onUploadFinish: PropTypes.func,
  onUploadError: PropTypes.func,
  maxFileSize: PropTypes.number,
  maxNumberOfFiles: PropTypes.number
};

AttachmentUpload.defaultProps = {
  name: '',
  value: [],
  clearFiles: false,
  disabled: false,
  onChange: () => { },
  onUploadStart: () => { },
  onUploadFinish: () => { },
  onUploadError: () => { },
  maxFileSize: MAX_FILE_SIZE,
  maxNumberOfFiles: MAX_NUMBER_OF_FILES
};

function AttachmentUpload({
  name, value, onChange, clearFiles, disabled,
  onClearFiles, onUploadStart, onUploadFinish, onUploadError,
  automationAttachment, maxFileSize, maxNumberOfFiles
}) {
  const alert = useAlert();
  const [isAttachmentSelected, setIsAttachmentSelected] = React.useState(false);

  const uppy = useUppy(() => {
    const newUppy = createUppy({
      allowedFileTypes: FILE_EXTENSIONS,
      maxFileSizeMega: maxFileSize,
      maxNumberOfFiles: automationAttachment ? 1 : maxNumberOfFiles
    });

    function handleChange() {
      const files = newUppy.getFiles();
      const filesData = files.map((file) => file?.response?.body);
      onChange(filesData);
    }

    function getRestrictionError(fileName, errorMessage) {
      const message = errorMessage?.toLowerCase();
      if (message.includes('you can only upload')) {
        if (message.match(/[\d]+ files$/u)) {
          return `${fileName} não adicionado, o limite é de até ${maxNumberOfFiles} arquivos.`;
        } else {
          return `${fileName} é inválido.\nExtensões permitidas:\n${FILE_EXTENSIONS.join(', ')}.`;
        }
      }
      if (message.includes('exceeds maximum allowed size')) {
        return `Tamanho máximo permitido é de até ${maxFileSize} MB.`;
      }
      return `Não foi possível adicionar o arquivo ${fileName}.`;
    }

    newUppy
      .on('file-removed', () => handleChange())
      .on('restriction-failed', (file, error) => {
        alert.show(
          getRestrictionError(file?.name, error?.message),
          { variant: 'danger' }
        );
      })
      .on('upload', () => onUploadStart())
      .on('upload-progress', () => handleChange())
      .on('upload-success', () => handleChange())
      .on('upload-error', (file, error) => {
        alert.show(
          `Upload falhou do arquivo ${file.name}.`,
          { variant: 'danger' }
        );
        captureException(error);
        console.error(error);
        newUppy.removeFile(file.id);
      })
      .on('error', () => onUploadError())
      .on('complete', () => onUploadFinish());

    return newUppy;
  });

  useEffect(() => {
    if (clearFiles) {
      const files = uppy.getFiles();
      files.forEach((file) => uppy.removeFile(file.id));
      onClearFiles();
    }
  }, [clearFiles]);

  const selectedFiles = uppy.getFiles();

  useEffect(() => {
    setIsAttachmentSelected(selectedFiles.length > 0);
  }, [selectedFiles]);

  useEffect(() => {
    // Adiciona arquivos pré-selecionados via `value`.
    if (!selectedFiles.length && value?.length) {
      value.forEach((item) => {
        const fileId = uppy.addFile({
          name: item.fileName ?? item.metadata?.filename,
          data: {},
          isRemote: true,
          meta: getFileMeta(item)
        });
        uppy.setFileState(fileId, {
          progress: { uploadComplete: true, uploadStarted: true },
          response: { body: item }
        });
      });
    }
  }, [selectedFiles, value]);

  const onRemove = (fileId) => uppy.removeFile(fileId);

  return (
    <div>
      <ActivitiesFiles
        files={selectedFiles}
        onFileRemove={onRemove}
      />

      <div>
        <Tooltip
          placement='top'
          content='Só é permitido adicionar um anexo'
          className='pe-none'
          hide={!(automationAttachment && isAttachmentSelected)}
        >
          <Button
            size='md'
            variant='outline-dark-gray'
            as='label'
            disabled={
              disabled || (automationAttachment && isAttachmentSelected)}
          >
            <FileInput
              uppy={uppy}
              inputName={name}
              disabled={disabled}
              pretty={false}
            />
            <Icon name='paperclip' className='me-2' />
            <span>Adicionar anexo</span>
          </Button>
        </Tooltip>
      </div>
    </div>
  );
}

function getFileMeta(item) {
  /**
   *
   * O Uppy usa o meta.relativePath para gerar o ID interno dos arquivos, de
   * forma a poder diferenciar arquivos com o mesmo nome que foram selecionados
   * a partir de pastas diferentes.
   *
   * Estamos usando essa propriedade para poder diferenciar anexos que tenham
   * o mesmo nome, mas sejam registros diferentes.
   * Por isso, verificamos se o item tem a propriedade `type`, que deve existir
   * apenas em arquivos já persistidos, e, se sim, geramos um `relativePath`
   * usando esse tipo e o ID do item.
   *
   * ref: https://uppy.io/docs/uppy/#addfilefile
   */
  if (item.type) {
    return { relativePath: `${item.type}/${item.id}` };
  }

  return {};
}

export default AttachmentUpload;
