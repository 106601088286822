import React from 'react';
import { helpUrl, settingsVoipPanelPath } from '@/routes';
import Button from '@/components/Button';
import ExternalLink from '@/components/ExternalLink';
import Icon from '@/components/Icon';

function NoBalance() {
  return (
    <div className='px-5 py-4'>
      <span className='text-dark fw-bold'>Saldo insuficiente</span>
      <div className='my-1 mb-3'>
        <span className='text-dark'>
          Seu saldo não é suficiente. Recarregue sua conta para continuar usando o telefone virtual.
        </span>
      </div>

      <Button variant='primary' href={settingsVoipPanelPath()}>
        Recarregar
      </Button>
      <Button
        variant='light'
        className='ms-2'
        as={ExternalLink}
        target='_blank'
        href={helpUrl('activate-voip')}
      >
        <Icon name='external-link' className='text-dark me-1' />
        Saiba mais
      </Button>
    </div>
  );
}

export default NoBalance;
