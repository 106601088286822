import React from 'react';
import PropTypes from 'prop-types';
import { useAuth } from '@/lib/auth';
import { helpUrl } from '@/routes';
import Button from '@/components/Button';
import ExternalLink from '@/components/ExternalLink';
import Icon from '@/components/Icon';
import LoadingButton from '@/components/LoadingButton';

const propTypes = {
  isLoading: PropTypes.bool,
  onClickActivate: PropTypes.func
};

function Inactive({ onClickActivate, isLoading }) {
  const { user } = useAuth();
  const { isPerformanceOrHigher } = user.account;

  return (
    <div className='px-5 py-4'>
      <p className='fw-bold mb-4'>Você ganhou R$ 25 para fazer ligações!</p>
      {
        isPerformanceOrHigher && (
          <div className='justify content-end me-2 mb-3 d-flex text-purple fw-bold'>
            <Icon name='ai' size='sm' className='me-1' />
            Com ChatGPT
          </div>
        )
      }
      <div className='d-flex mb-2 text-dark-gray'>
        <Icon name='check' size='sm' className='me-1 mt-1 text-dark-gray' />
        Salva o áudio automaticamente;
      </div>
      <div className='d-flex mb-2 text-dark-gray'>
        <Icon name='check' size='sm' className='me-1 mt-1 text-dark-gray' />
        Transcreve as chamadas;
      </div>
      <div className='d-flex mb-2 text-dark-gray'>
        <Icon name='check' size='sm' className='me-1 mt-1 text-dark-gray' />
        Resume os pontos principais;
      </div>
      <div className='d-flex mb-4 text-dark-gray'>
        <Icon name='check' size='sm' className='me-1 mt-1 text-dark-gray' />
        Teste sem compromisso, sem cartão.
      </div>

      <LoadingButton
        isLoading={isLoading}
        onClick={onClickActivate}
        loadingText='Ativando...'
        variant='primary'
        className='mt-2'
      >
        Experimentar grátis
      </LoadingButton>

      <Button
        as={ExternalLink}
        target='_blank'
        href={helpUrl('activate-voip')}
        className='text-darker-gray ms-2 mt-2 rounded'
        variant='light'
      >
        <Icon className='me-1' name='external-link' />
        <span>Saiba mais</span>
      </Button>
    </div>
  );
}

Inactive.propTypes = propTypes;

export default Inactive;
