import React from 'react';
import Avatar from '@/components/Avatar';
import FormField from '@/components/FormField';
import {
  DealStatusSelect,
  DateRangeInput,
  FullDealStatusSelect,
  NextTaskSelect,
  LatestActivitySelect,
  RankingSelect,
  ProductSelect,
  UserSelect,
  FunnelSelect,
  StageSelect,
  LeadOriginSelect,
  LossReasonSelect,
  CitySelect,
  TeamSelect,
  ImportSelect
} from '@/components/Inputs';
import FilterState from '@/components/Columns/FilterState';
import DependentFormField from '@/components/DependentFormField';
import ProductsPopover from '@/components/ProductsPopover';
import Rating from '@/components/Rating';
import TaskTag from '@/components/TaskTag';
import Truncate from '@/components/Truncate';
import {
  buildQueryStringKeysFrom,
  buildTableDefaultValuesFrom,
  buildColumnsDefaultValuesFrom,
  renderColumnsFiltersFrom
} from '@/components/Columns/Utils';
import CreatedBy from '@/components/EntityModal/Deal/CreatedBy';
import { getString, concatDateForFilter, concatStringForFilter } from '@/utils';
import { DATE_RANGE_DEFAULT_PRESETS } from '@/date-range';
import { formatQueryStringDate, toISOString } from '@/date';
import isEmpty from 'lodash/isEmpty';

const dealColumns = [
  {
    name: 'name',
    humanizedName: 'Nome',
    default: true,
    getValue: (deal) => deal.title,
    fixed: true
  },
  {
    name: 'contact',
    humanizedName: 'Contato',
    default: true,
    getValue: (deal) => deal.organization?.name || deal.person?.name,
    queryString: {
      em: {
        filterKey: 'q',
        set: (value) => value,
        concat: concatStringForFilter,
        columnName: 'contact'
      },
      p: {
        filterKey: 'q',
        set: (value) => value,
        concat: concatStringForFilter,
        columnName: 'contact'
      }
    }
  },
  {
    name: 'user',
    humanizedName: 'Cadastrado por',
    default: true,
    getValue: (deal) => (
      <span className='d-inline-flex align-items-center'>
        <CreatedBy deal={deal} />
      </span>
    ),
    queryString: {
      u: {
        filterKey: 'user_or_automation',
        set: (value) => (isEmpty(value) ? [] : [Number(value)]),
        columnName: 'user'
      }
    },
    filter: {
      name: 'user_or_automation',
      defaultValue: null,
      renderComponent: (filter) => (
        <FormField
          key={filter.name}
          name={filter.name}
          as={UserSelect}
          label='Cadastrado por'
          createdBy={true}
          multiple
        />
      )
    }
  },
  {
    name: 'owner',
    humanizedName: 'Responsável',
    default: true,
    getValue: (deal) => (
      deal.ownerUser?.name
        ? (
          <span className='d-inline-flex align-items-center'>
            <Avatar
              name={deal.ownerUser?.name}
              url={deal.ownerUser?.avatarUrl}
              className='me-1'
              tooltip={false}
            />
            {deal.ownerUser?.name}
          </span>
        )
        : null
    ),
    queryString: {
      ur: {
        filterKey: 'owner_user_id_in',
        set: (value) => (isEmpty(value) ? [] : [Number(value)]),
        columnName: 'owner'
      }
    },
    filter: {
      name: 'owner_user_id_in',
      defaultValue: null,
      renderComponent: (filter) => (
        <FormField
          key={filter.name}
          name={filter.name}
          as={UserSelect}
          label='Responsável'
          multiple
          allowNull
        />
      )
    }
  },
  {
    name: 'status',
    humanizedName: 'Status',
    default: true,
    queryString: {
      sn: {
        filterKey: 'deal_status_id_eq',
        set: (value) => value,
        columnName: 'status'
      }
    },
    filter: {
      name: 'deal_status_id_eq',
      defaultValue: null,
      renderComponent: (filter) => (
        <FormField
          key={filter.name}
          name={filter.name}
          as={DealStatusSelect}
        />
      )
    },
    getValue: (deal, onUpdate) => (
      <FullDealStatusSelect
        value={deal.dealStatus?.id}
        onChange={(params) => onUpdate(deal, params)}
      />
    )
  },
  {
    name: 'loss_reason',
    humanizedName: 'Motivo de perda',
    default: false,
    queryString: {
      mp: {
        filterKey: 'loss_reason_id_eq',
        set: (value) => Number(value),
        columnName: 'loss_reason'
      }
    },
    filter: {
      name: 'loss_reason_id_eq',
      defaultValue: null,
      renderComponent: (filter) => (
        <FormField
          label='Motivo de perda'
          key={filter.name}
          name={filter.name}
          as={LossReasonSelect}
        />
      )
    },
    getValue: (deal) => (
      deal?.dealLossReason
        ? (
          <div>
            <span className='text-danger fw-normal'>
              {deal?.dealLossReason?.lossReason?.name}
            </span>
            <Truncate lines={1} className='text-dark-gray'>
              {deal?.dealLossReason?.description || ''}
            </Truncate>
          </div>
        )
        : null
    )
  },
  {
    name: 'funnel',
    humanizedName: 'Funil',
    default: false,
    getValue: (deal) => deal.stage?.funnel?.name,
    queryString: {
      funil: {
        filterKey: 'funnel_id_in',
        set: (value) => (isEmpty(value) ? [] : [Number(value)]),
        columnName: 'funnel'
      }
    },
    filter: {
      name: 'funnel_id_in',
      defaultValue: null,
      renderComponent: (filter) => (
        <FormField
          multiple
          key={filter.name}
          name={filter.name}
          as={FunnelSelect}
          label='Funil'
        />
      )
    }
  },
  {
    name: 'funnel_initial',
    humanizedName: 'Funil Inicial',
    default: false,
    hidden: true,
    queryString: {
      fi: {
        filterKey: 'funnel_initial_id',
        set: (value) => Number(value),
        columnName: 'funnel_initial'
      }
    },
    filter: {
      name: 'funnel_initial_id',
      defaultValue: null,
      renderComponent: (filter) => (
        <FormField
          key={filter.name}
          name={filter.name}
          as={FunnelSelect}
          label='Funil Inicial'
        />
      )
    }
  },
  {
    name: 'stage',
    humanizedName: 'Etapa',
    default: true,
    getValue: (deal, onUpdate) => (
      <StageSelect
        label=''
        name='deal.stage_id'
        className='py-0 my-0'
        value={deal.stage?.id}
        funnelId={deal.stage?.funnel?.id}
        onChange={
          (stageId) => onUpdate(deal, { stage_id: stageId }, (err, { tracker, user }) => {
            if (!err) {
              tracker.trackDealStageChanged({ user, page: 'Nova listagem' });
            }
          })}
      />
    ),
    queryString: {
      et: {
        filterKey: 'stage_id_in',
        set: (value) => (isEmpty(value) ? [] : [Number(value)]),
        columnName: 'stage'
      }
    },
    filter: {
      name: 'stage_id_in',
      defaultValue: null,
      renderComponent: (filter) => (
        <FormField
          multiple
          key={filter.name}
          name={filter.name}
          as={StageSelect}
          label='Etapa'
        />
      )
    }
  },
  {
    name: 'value',
    humanizedName: 'Valor',
    default: true,
    calculable: true,
    isCurrency: true,
    getValue: (deal) => deal.value
  },
  {
    name: 'started_at',
    humanizedName: 'Data de início',
    default: false,
    className: 'text-nowrap',
    getValue: (deal) => deal.startedAt,
    queryString: {
      dii: {
        filterKey: 'started_at_local_date_within',
        set: (value = '') => `${formatQueryStringDate(value) || ''}/`,
        concat: concatDateForFilter,
        columnName: 'started_at'
      },
      dif: {
        filterKey: 'started_at_local_date_within',
        set: (value = '') => `/${formatQueryStringDate(value) || ''}`,
        concat: concatDateForFilter,
        columnName: 'started_at'
      }
    },
    filter: {
      name: 'started_at_local_date_within',
      defaultValue: null,
      isPopover: true,
      renderComponent: (filter) => (
        <FormField
          key={filter.name}
          name={filter.name}
          as={DateRangeInput}
          label='Data de início'
          presets={DATE_RANGE_DEFAULT_PRESETS}
        />
      )
    }
  },
  {
    name: 'finished_at',
    humanizedName: 'Data de conclusão',
    default: false,
    className: 'text-nowrap',
    getValue: (deal) => deal.finishedAt,
    queryString: {
      dci: {
        filterKey: 'finished_at_local_date_within',
        set: (value = '') => `${formatQueryStringDate(value) || ''}/`,
        concat: concatDateForFilter,
        columnName: 'finished_at'
      },
      dcf: {
        filterKey: 'finished_at_local_date_within',
        set: (value = '') => `/${formatQueryStringDate(value) || ''}`,
        concat: concatDateForFilter,
        columnName: 'finished_at'
      }
    },
    filter: {
      name: 'finished_at_local_date_within',
      defaultValue: null,
      isPopover: true,
      renderComponent: (filter) => (
        <FormField
          key={filter.name}
          name={filter.name}
          as={DateRangeInput}
          label='Data de conclusão'
          presets={DATE_RANGE_DEFAULT_PRESETS}
        />
      )
    }
  },
  {
    name: 'products',
    humanizedName: 'Produtos',
    default: false,
    getValue: (deal) => {
      const productsEmptyListMessage = getString(
        ['messages', 'deal', 'products', 'empty_list']
      );

      return (
        <ProductsPopover
          entity={{ id: deal.id, type: 'deal' }}
          emptyListMessage={productsEmptyListMessage}
          products={deal.products}
        />
      );
    },
    queryString: {
      pro: {
        filterKey: 'products_id_in',
        set: (value) => (isEmpty(value) ? [] : [Number(value)]),
        columnName: 'products'
      }
    },
    filter: {
      name: 'products_id_in',
      defaultValue: null,
      renderComponent: (filter) => (
        <FormField
          key={filter.name}
          name={filter.name}
          as={ProductSelect}
          multiple
          isSearchable
          allowNull
          queryArgs={{ params: { per_page: 600 } }}
        />
      )
    }
  },
  {
    name: 'origin',
    humanizedName: 'Origem do contato',
    default: false,
    getValue: (deal) => deal.organization?.leadOrigin?.name || deal.person?.leadOrigin?.name,
    queryString: {
      origemLead: {
        filterKey: 'lead_origin_id_in',
        set: (value) => (isEmpty(value) ? [] : [Number(value)]),
        columnName: 'origin'
      }
    },
    filter: {
      name: 'lead_origin_id_in',
      defaultValue: null,
      renderComponent: (filter) => (
        <FormField
          key={filter.name}
          name={filter.name}
          as={LeadOriginSelect}
          allowNull
          multiple
          isSearchable
        />
      )
    }
  },
  {
    name: 'next_task',
    humanizedName: 'Próxima tarefa',
    default: false,
    getValue: (deal) => (
      <TaskTag variant='nextTask'
        task={deal?.nextTask}
        entity={{ type: 'deal', id: deal.id }}
      />
    ),
    queryString: {
      proxt: {
        filterKey: 'next_task',
        set: (value = '') => value,
        columnName: 'next_task'
      },
      forgotten: {
        filterKey: 'next_task',
        set: (value = '') => {
          const variants = {
            delayedTasks: 'overdue',
            noFutureTasks: 'no_schedule'
          };

          return variants[value] ?? 'on_time';
        },
        columnName: 'next_task'
      }
    },
    filter: {
      name: 'next_task',
      defaultValue: null,
      renderComponent: (filter) => (
        <FormField
          key={filter.name}
          name={filter.name}
          as={NextTaskSelect}
        />
      )
    }
  },
  {
    name: 'latest_task',
    humanizedName: 'Última atividade',
    default: false,
    getValue: (deal) => (
      <TaskTag variant='latestTask'
        task={deal?.nextTask}
        entity={{ type: 'deal', id: deal.id }}
      />
    ),
    queryString: {
      ulta: {
        filterKey: 'latest_activity',
        set: (value = '') => value,
        columnName: 'latest_task'
      }
    },
    filter: {
      name: 'latest_activity',
      defaultValue: null,
      renderComponent: (filter) => (
        <FormField
          key={filter.name}
          name={filter.name}
          as={LatestActivitySelect}
        />
      )
    }
  },
  {
    name: 'state',
    humanizedName: 'Estado',
    default: false,
    className: 'text-nowrap',
    getValue: (deal) => {
      const relatedEntity = deal?.person || deal?.organization;
      return relatedEntity?.address?.state;
    },
    queryString: {
      e: {
        filterKey: 'state_in',
        set: (value = '') => [value.toUpperCase()],
        columnName: 'state'
      }
    },
    filter: {
      name: 'state_in',
      defaultValue: null,
      onClear: ({ setFieldValue }) => {
        setFieldValue('city_id_in', null);
      },
      renderComponent: FilterState
    }
  },
  {
    name: 'city',
    humanizedName: 'Cidade',
    default: false,
    className: 'text-nowrap',
    getValue: (deal) => {
      const relatedEntity = deal?.person || deal?.organization;
      return relatedEntity?.address?.city?.name;
    },
    queryString: {
      c: {
        filterKey: 'city_id_in',
        set: (value) => (isEmpty(value) ? [] : [Number(value)]),
        columnName: 'city'
      }
    },
    filter: {
      name: 'city_id_in',
      defaultValue: null,
      renderComponent: (filter) => (
        <DependentFormField
          key={filter.name}
          name={filter.name}
          as={CitySelect}
          source='state_in'
          target='state'
          multiple
          allowNull
          isSearchable
        />
      )
    }
  },
  {
    name: 'team',
    humanizedName: 'Equipe',
    default: false,
    className: 'text-nowrap',
    getValue: (deal) => deal?.ownerUser?.teams?.map((team) => team.name).toString(),
    queryString: {
      team: {
        filterKey: 'team_id_in',
        set: (value) => (isEmpty(value) ? [] : [Number(value)]),
        columnName: 'team'
      }
    },
    filter: {
      name: 'team_id_in',
      defaultValue: null,
      renderComponent: (filter) => (
        <DependentFormField
          key={filter.name}
          name={filter.name}
          as={TeamSelect}
          source='team_id_in'
          target='team'
          multiple
          isSearchable
        />
      )
    }
  },
  {
    name: 'import',
    humanizedName: 'Importações',
    default: false,
    getValue: (deal) => deal?.importedAt,
    className: 'text-nowrap',
    queryString: {
      import: {
        filterKey: 'import_id_in',
        set: (value) => (isEmpty(value) ? [] : [Number(value)]),
        columnName: 'import'
      }
    },
    filter: {
      name: 'import_id_in',
      defaultValue: null,
      renderComponent: (filter) => (
        <DependentFormField
          key={filter.name}
          name={filter.name}
          as={ImportSelect}
          source='import_id_in'
          target='import'
          entity='deals'
          allowNull
          multiple
          isSearchable
        />
      )
    }
  },
  {
    name: 'ranking',
    humanizedName: 'Ranking',
    default: true,
    getValue: (deal, onUpdate) => (
      <Rating
        value={deal.ranking ?? 0}
        onChange={(ranking, done) => onUpdate(deal, { ranking }, done)}
      />
    ),
    filter: {
      name: 'ranking_in',
      defaultValue: null,
      renderComponent: (filter) => (
        <FormField
          key={filter.name}
          name={filter.name}
          as={RankingSelect}
        />
      )
    }
  },
  {
    name: 'updated_at',
    humanizedName: 'Última atualização',
    default: false,
    className: 'text-nowrap',
    getValue: (deal) => deal.updatedAt
  },
  {
    name: 'created_at',
    humanizedName: 'Data de cadastro',
    default: false,
    className: 'text-nowrap',
    getValue: (deal) => deal.createdAt,
    queryString: {
      createdAtStart: {
        filterKey: 'created_at_utc_date_within',
        set: (value = '') => `${toISOString(value) || ''}/`,
        concat: concatDateForFilter,
        columnName: 'created_at'
      },
      createdAtEnd: {
        filterKey: 'created_at_utc_date_within',
        set: (value = '') => `/${toISOString(value) || ''}`,
        concat: concatDateForFilter,
        columnName: 'created_at'
      }
    },
    filter: {
      name: 'created_at_utc_date_within',
      defaultValue: null,
      isPopover: true,
      renderComponent: (filter) => (
        <FormField
          key={filter.name}
          name={filter.name}
          as={DateRangeInput}
          label='Data de cadastro'
          presets={DATE_RANGE_DEFAULT_PRESETS}
        />
      )
    }
  }
];

const columnsFilters = renderColumnsFiltersFrom(dealColumns);

const columnsDefaultValues = buildColumnsDefaultValuesFrom(dealColumns);

const tableDefaultValues = buildTableDefaultValuesFrom(dealColumns);

const queryStringKeys = buildQueryStringKeysFrom(dealColumns);

export default dealColumns;
export { columnsDefaultValues, columnsFilters, tableDefaultValues, queryStringKeys };
