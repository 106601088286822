import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import Icon from '@/components/Icon';
import Truncate from '@/components/Truncate';
import Button from '@/components/Button';
import { MaskedInput, WhatsAppInput } from '@/components/Inputs';
import LoadingButton from '@/components/LoadingButton';
import Form from '@/components/Form';
import FormField from '@/components/FormField';

const propTypes = {
  contact: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    entity: PropTypes.oneOf(['organization', 'person']),
    phones: PropTypes.arrayOf(
      PropTypes.shape({
        number: PropTypes.string,
        type: PropTypes.oneOf(['whatsapp', 'work_phone', 'mobile_phone'])
      })
    )
  }).isRequired,
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func
};

/* eslint-disable no-magic-numbers */
const schema = Yup.object().shape({
  contact: Yup.object().shape({
    whatsapp: Yup.string()
      .nullable()
      .min(12, 'Número inválido.')
      .max(16, 'Número inválido.'),
    work_phone: Yup.string()
      .nullable()
      .min(10, 'Número inválido.')
      .max(11, 'Número inválido.'),
    mobile_phone: Yup.string()
      .nullable()
      .min(10, 'Número inválido.')
      .max(11, 'Número inválido.')
  })
});
/* eslint-enable no-magic-numbers */

function ContactForm({ contact, onSubmit, onCancel }) {
  const defaultValues = useMemo(() => {
    const contactPhones = contact.phones.reduce((acc, phone) => {
      acc[phone.type] = phone.number || '';
      return acc;
    }, {});

    return { contact: contactPhones };
  }, [contact.phones]);

  return (
    <div>
      <div className='d-flex'>
        <div className='d-flex bg-light-gray rounded p-2 align-self-baseline'>
          <Icon name={contact.entityType} />
        </div>
        <div className='ms-3 flex-fill'>
          <Truncate as='div' className='width-5 text-darker-gray fw-bold mt-2 mb-1'>
            {contact.name}
          </Truncate>
        </div>
      </div>

      <Form
        name='voip-contact-form'
        defaultValues={defaultValues}
        onSubmit={onSubmit}
        validationSchema={schema}
      >
        {({ isSubmitting }) => (
          <>
            <div className='align-items-start mt-6'>
              <FormField
                as={WhatsAppInput}
                name='contact.whatsapp'
                label='Whatsapp'
                autoComplete='off'
                variant='light'
              />
              <FormField
                as={MaskedInput}
                name='contact.mobile_phone'
                label='Celular'
                maskType='phone'
                placeholder='(00) 0000-0000'
              />
              <FormField
                as={MaskedInput}
                maskType='phone'
                name='contact.work_phone'
                label='Telefone'
                autoComplete='off'
                placeholder='(00) 0000-0000'
                variant='light'
              />

              <div className='d-flex justify-content-end mt-2'>
                <Button
                  className='fw-bold'
                  variant='link'
                  disabled={isSubmitting}
                  onClick={onCancel}
                >
                  Cancelar
                </Button>
                <LoadingButton
                  variant='primary'
                  type='submit'
                  isLoading={isSubmitting}
                  loadingText='Salvando...'
                >
                  Salvar
                </LoadingButton>
              </div>
            </div>
          </>
        )}
      </Form>
    </div>
  );
}

ContactForm.propTypes = propTypes;

export default ContactForm;
