import React from 'react';
import EntityTitle from '@/components/EntityModal/EntityTitle';
import Menu from '@/components/EntityModal/Menu';
import Tabs from '@/components/EntityModal/Organization/Header/Tabs';
import { useOrganization, useOnUpdate, useOnDelete } from '@/contexts/organizationModal';
import { canEditOrganization } from '@/policies';
import { organizationEditPath } from '@/routes';
import SelectOwnerUser from '@/components/EntityModal/SelectOwnerUser';
import HeaderAvatar from '@/components/EntityModal/HeaderAvatar';
import RestrictWarningBadge from '@/components/EntityModal/RestrictWarningBadge';
import AddDealButton from '@/components/EntityModal/AddDealButton';

function Header() {
  const organization = useOrganization();
  const onUpdate = useOnUpdate();
  const onDelete = useOnDelete();

  return (
    <div className='bg-white rounded-top px-3 pt-3 mb-3'>
      <div className='mb-4 d-flex justify-content-between'>
        <div className='d-flex flex-fill'>
          <HeaderAvatar
            entityType='organization'
            avatarUrl={organization.logo?.url}
          />

          <div className='py-1 flex-fill'>
            <div className='w-100 d-flex justify-content-between mb-2'>
              <EntityTitle
                className='width-5'
                onUpdate={onUpdate}
                entity={{ title: organization.name }}
              />

              <div className='d-flex align-items-center'>
                <RestrictWarningBadge entity={organization} loading={false} />
              </div>
            </div>

            <div className='w-100 d-flex justify-content-between'>
              <div>
                <SelectOwnerUser
                  ownerUser={organization.ownerUser}
                  canEditPolicy={canEditOrganization}
                  onUpdate={onUpdate}
                />
              </div>

              <div className='d-flex'>
                <AddDealButton
                  entityId={organization.id}
                  entityType='organization'
                />
                <Menu
                  entity={organization}
                  entityType='organization'
                  loading={false}
                  onDelete={onDelete}
                  editPath={organizationEditPath(organization.id)}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <Tabs />
    </div>
  );
}

export default Header;
