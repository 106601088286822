import React, { useMemo } from 'react';
import { useAlert } from 'react-alert';
import * as Yup from 'yup';
import Button from '@/components/Button';
import Icon from '@/components/Icon';
import Popover from '@/components/Popover';
import { Col, Row } from 'react-bootstrap';
import LoadingButton from '@/components/LoadingButton';
import Form from '@/components/Form';
import FormField from '@/components/FormField';
import {
  CurrencyInput,
  FunnelSelect,
  TextArea,
  TextInput,
  UserSelect
} from '@/components/Inputs';
import { useCreateDeal, useDealTemplate } from '@/api';
import { useAuth } from '@/lib/auth';
import LoadingWrapper from '@/components/LoadingWrapper';
import APIErrorMessage from '@/components/APIErrorMessage';
import { getValidationErrors } from '@/utils';
import PropTypes from 'prop-types';
import { isUnprocessableEntityError } from '@/errors';
import { useTracking } from '@/lib/tracking';


const propTypes = {
  entityId: PropTypes.number.isRequired,
  entityType: PropTypes.oneOf(['organization', 'person']).isRequired
};

/* eslint-disable no-magic-numbers */
const schema = Yup.object().shape({
  title: Yup.string()
    .max(150, 'O nome do negócio deve ter no máximo 150 caracteres.')
    .required('Por favor, informe o nome do negócio.'),
  value: Yup.number()
    .nullable()
    .typeError('O valor do negócio deve ser um número.')
    .min(0, 'O valor do negócio não pode ser negativo.'),
  description: Yup.string()
    .max(2000, 'A descrição do negócio deve ter no máximo 2000 caracteres.')
});
/* eslint-enable no-magic-numbers */

function CreateDealForm({ entityId, entityType, onHide, createDealMutation }) {
  const alert = useAlert();
  const { user } = useAuth();
  const entityParams = getQueryParams({ entityId, entityType });
  const tracker = useTracking();

  const { data: { data: templateData } = {}, isLoading, refetch } = useDealTemplate({
    params: entityParams,
    config: { staleTime: 1000 }
  });

  const initialValues = useMemo(() => {
    const { account } = user;
    if (!templateData) {
      return {};
    }

    return {
      ...entityParams,
      title: templateData.name,
      value: templateData.value ? templateData.value.toString() : '',
      description: templateData.description ?? '',
      owner_user_id: user.id,
      funnel_id: account.defaultFunnel.id,
      privacy: {
        type: account.privacy
      }
    };
  }, [templateData, user]);

  const onSubmit = (formData, { setFieldError, setSubmitting, setFieldValue }) => {
    createDealMutation.mutate(formData, {
      onSuccess: ({ data }) => {
        tracker.trackDealCreated({
          user,
          quickAdded: true,
          source: entityType
        });
        alert.show(
          `O negócio ${data.title} foi criado!`,
          { variant: 'success', timeout: 5000 }
        );

        onHide();
      },
      onError: async (error) => {
        const errors = getValidationErrors(error, 'deal');

        Object.entries(errors).forEach(([key, message]) => {
          setFieldError(key, message);
        });

        alert.show(
          <APIErrorMessage err={error} resource='deal' action='create' />,
          { variant: 'danger' }
        );

        // Caso o título seja duplicado, atualiza com um novo título da rota de deal-template
        if (isUnprocessableEntityError(error)) {
          const titleErrors = error.data.errors.title;
          if (titleErrors && titleErrors.includes('taken')) {
            const { data: { data } = {} } = await refetch();
            setFieldValue('title', data.name);
          }
        }

        setSubmitting(false);
      }
    });
  };

  return (
    <div>
      <LoadingWrapper isLoading={isLoading}>
        <h4 className='mb-4'>Adicionar negócio</h4>

        <Form
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={schema}
        >
          {({ isSubmitting }) => (
            <>
              <Row className='gx-3'>
                <Col sm={6}>
                  <FormField
                    as={TextInput}
                    name='title'
                    label='Título do negócio'
                    autoComplete='off'
                    placeholder='Dê um nome nome para o negócio'
                    maxLength={150}
                    variant='light'
                    data-autofocus='true'
                    autoFocus
                  />
                </Col>
                <Col sm={6}>
                  <FormField
                    as={UserSelect}
                    name='owner_user_id'
                    label='Responsável'
                    isSearchable
                  />
                </Col>

                <Col sm={6}>
                  <FormField
                    as={CurrencyInput}
                    name='value'
                    label='Valor estimado'
                    autoComplete='off'
                    placeholder='R$ 0,00'
                  />
                </Col>

                <Col sm={6}>
                  <FormField
                    as={FunnelSelect}
                    name='funnel_id'
                    label='Funil'
                    isSearchable
                  />
                </Col>

                <Col sm={12}>
                  <FormField
                    as={TextArea}
                    name='description'
                    label='Descrição'
                    autoComplete='off'
                    variant='light'
                    maxLength={2000}
                    placeholder='Descreva detalhes importantes sobre essa negociação'
                  />
                </Col>
              </Row>

              <Row className='gx-3 mt-4'>
                <Col sm={6}>
                  <Button
                    className='w-100'
                    onClick={onHide}
                    disabled={isSubmitting}
                    variant='outline-dark-gray'
                  >
                    Cancelar
                  </Button>
                </Col>
                <Col sm={6}>
                  <LoadingButton
                    className='w-100'
                    loadingText='Salvando...'
                    type='submit'
                    isLoading={isSubmitting}
                  >
                    Salvar
                  </LoadingButton>
                </Col>
              </Row>
            </>
          )}
        </Form>
      </LoadingWrapper>
    </div>
  );
}

function AddDealButton({ entityId, entityType }) {
  const createDealMutation = useCreateDeal();

  return (
    <Popover
      rootClose={!createDealMutation.isLoading}
      rootCloseEvent='mousedown'
      placement='left-start'
      flip
      showArrow
      content={({ onHide }) => (
        <CreateDealForm
          onHide={onHide}
          entityId={entityId}
          entityType={entityType}
          createDealMutation={createDealMutation}
        />
      )}
    >
      <div>
        <Button
          size='sm'
          className='d-flex align-items-center text-nowrap mx-2 text-primary'
          variant='light'
          aria-label='Fazer ligação'
        >
          <Icon name='plus' className='me-2' size='fixed' />
          <span className='me-3'>Adicionar negócio</span>
        </Button>
      </div>
    </Popover>
  );
}

function getQueryParams({ entityId, entityType }) {
  switch (entityType) {
    case 'organization':
      return { organization_id: entityId };
    case 'person':
      return { person_id: entityId };
    default:
      return {};
  }
}

AddDealButton.propTypes = propTypes;

export default AddDealButton;
