import React from 'react';
import PropTypes from 'prop-types';

import { applyMask } from '@/masks';
import { useCurrentAccount } from '@/api';

import Card from '@/components/Card';
import Form from '@/components/Form';
import FormField from '@/components/FormField';
import { RadioButton } from '@/components/Inputs';
import StripeCreditCardForm from '@/components/Billing/StripeComponents/StripeCreditCardForm';
import StripeElement from '@/components/Billing/StripeComponents/StripeElement';
import Tooltip from '@/components/Tooltip';

const propTypes = {
  paymentType: PropTypes.string,
  setPaymentType: PropTypes.func,
  setPaymentData: PropTypes.func,
  isUpdatePaymentMethod: PropTypes.bool,
  period: PropTypes.string,
  isCreditCardSubscription: PropTypes.bool,
  allowAllPaymentMethods: PropTypes.bool,
  disabledBankSlip: PropTypes.bool
};

const defaultProps = {
  paymentType: 'credit_card',
  setPaymentType: () => { },
  setPaymentData: () => { },
  isUpdatePaymentMethod: false,
  period: null,
  isCreditCardSubscription: true,
  allowAllPaymentMethods: false,
  disabledBankSlip: false
};

const PAYMENT_OPTIONS = [
  {
    label: 'Cartão de crédito',
    value: 'credit_card'
  },
  {
    label: 'Pix',
    value: 'pix'
  },
  {
    label: 'Boleto',
    value: 'bank_slip'
  }
];

function PaymentMethodForm(props) {
  const {
    paymentType,
    setPaymentType,
    setPaymentData,
    isUpdatePaymentMethod,
    period,
    isCreditCardSubscription,
    allowAllPaymentMethods,
    disabledBankSlip
  } = props;

  const { data: { data: accountData } = {} } = useCurrentAccount();

  const isPix = paymentType === 'pix';
  const isCreditCard = paymentType === 'credit_card';
  const isBankSlip = paymentType === 'bank_slip';

  if (!accountData) {
    return null;
  }

  return (
    <PaymentFormWrapper
      setPaymentType={setPaymentType}
      isUpdatePaymentMethod={isUpdatePaymentMethod}
      allowAllPaymentMethods={allowAllPaymentMethods}
      period={period}
      disabledBankSlip={disabledBankSlip}
    >
      {isCreditCard && (
        <CreditCardForm
          setPaymentData={setPaymentData}
          isCreditCardSubscription={isCreditCardSubscription}
        />
      )}

      {isPix && <PixInfo isUpdatePaymentMethod={isUpdatePaymentMethod} />}

      {isBankSlip && (
        <BankSlipInfo
          accountData={accountData}
          isUpdatePaymentMethod={isUpdatePaymentMethod}
        />
      )}
    </PaymentFormWrapper>
  );
}

function PaymentFormWrapper({ children, ...props }) {
  return (
    <Card className='p-3 border-light'>
      <div className='min-height-5'>
        <PaymentTypeForm {...props} />

        <hr className='my-3 text-dark-gray' />

        {children}
      </div>
    </Card>
  );
}

function PaymentTypeForm(props) {
  const {
    setPaymentType,
    isUpdatePaymentMethod,
    allowAllPaymentMethods,
    period,
    disabledBankSlip
  } = props;

  const isRadioDisabled = (label) => {
    if (isUpdatePaymentMethod && !allowAllPaymentMethods) {
      return period === 'monthly' && label !== 'credit_card';
    }

    if (disabledBankSlip && label === 'bank_slip') {
      return true;
    }

    return false;
  };

  const disabledRadioMessage = disabledBankSlip
    ? 'Pagamento com boleto não está disponível para valores abaixo de R$ 5,00'
    : 'Boleto ou PIX não está disponível como forma de pagamento mensal.';

  return (
    <Form
      name='payment-type-form'
      className={'w-100'}
      initialValues={{ type: 'credit_card' }}
    >
      {() => (
        <div className='d-flex'>
          {
            PAYMENT_OPTIONS.map(({ label, value }, index) => (
              <div key={index}>
                <Tooltip
                  hide={!isRadioDisabled(value)}
                  content={disabledRadioMessage}
                >
                  <div>
                    <FormField
                      noFlex
                      as={RadioButton}
                      name='type'
                      className='me-4 fw-bold text-dark-gray text-small'
                      checkClassName='mb-0'
                      key={value}
                      id={value}
                      radioValue={value}
                      label={label}
                      onChange={setPaymentType}
                      disabled={isRadioDisabled(value)}
                    />
                  </div>
                </Tooltip>
              </div>
            ))
          }
        </div>
      )}
    </Form>
  );
}

function CreditCardForm({ setPaymentData, isCreditCardSubscription }) {
  return (
    <StripeElement>
      <StripeCreditCardForm
        setPaymentData={setPaymentData}
        showRegistrationMessage={isCreditCardSubscription}
      />
    </StripeElement>
  );
}

function PixInfo({ isUpdatePaymentMethod }) {
  const updatePaymentMethodInfo = <>
    <li>
      O código Pix será enviado por e-mail 10 dias antes do vencimento da sua assinatura
    </li>
    <li>
      Você, os outros administradores e os emails do financeiro receberão esse e-mail
    </li>
    <li>
      Após o pagamento, a transação por Pix é aprovada e em alguns segundos seu plano é renovado
    </li>
  </>;

  const upgradePaymentMethodInfo = <>
    <li>
      Clique em &quot;Pagar com Pix&quot; para gerar um código e QR code.
    </li>
    <li>
      O código Pix é válido por 2 horas.
    </li>
    <li>
      Seu plano será liberado após a aprovação do pagamento.
    </li>
  </>;

  const info = isUpdatePaymentMethod ? updatePaymentMethodInfo : upgradePaymentMethodInfo;

  return (
    <div className='text-small'>
      <div className='fw-bold mb-2'>Informações importantes:</div>

      <ul className='text-dark-gray'>
        {info}
      </ul>
    </div>
  );
}

function BankSlipInfo({ accountData, isUpdatePaymentMethod }) {
  const updatePaymentMethodInfo = <>
    <li>
      O boleto será enviado por e-mail 10 dias antes do vencimento da sua assinatura
    </li>
    <li>
      Você, os outros administradores e os emails do financeiro receberão esse e-mail
    </li>
    <li>
      Após o pagamento, a compensação do boleto pelo banco pode levar até 2 dias úteis
    </li>
    <li>
      Seu plano será renovado após o pagamento ser processado
    </li>
  </>;

  const upgradePaymentMethodInfo = <>
    <li>
      Seu pagamento será confirmado em até 2 dias úteis após o pagamento do Boleto.
    </li>
    <li>
      <span>Caso o pagamento não seja realizado até a data de vencimento, </span>
      <span>é necessário emitir segunda via ou alterar a forma de pagamento.</span>
    </li>
  </>;

  const info = isUpdatePaymentMethod ? updatePaymentMethodInfo : upgradePaymentMethodInfo;

  const formattedCpfCnpj = applyMask(accountData.cpfCnpj, 'cpf_cnpj');
  const address = accountData.address;

  return (
    <div className='text-small'>
      <div className='fw-bold mb-1'>Dados de faturamento</div>

      <div className='text-dark-gray'>
        <div>
          <span className='fw-bold'>Nome/Razão Social: </span>
          {accountData.legalName}
        </div>

        <div>
          <span className='fw-bold'>CNPJ/CPF: </span>
          {formattedCpfCnpj}
        </div>

        <div>
          <span className='fw-bold'>Endereço: </span>
          <span>{address.streetName}, {address.streetNumber} - </span>
          <span>{address.district} - {address.city.name} - {address.state}</span>
        </div>
      </div>

      <div className='fw-bold mt-3 mb-1' > Informações importantes:</div>

      <ul className='text-dark-gray'>
        {info}
      </ul>
    </div >
  );
}

PaymentMethodForm.propTypes = propTypes;
PaymentMethodForm.defaultProps = defaultProps;

export default PaymentMethodForm;
