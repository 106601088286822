import React from 'react';
import { Col, Row } from 'react-bootstrap';

import Truncate from '@/components/Truncate';
import IconLabel from '@/components/IconLabel';
import { EditInPlace, TextInput } from '@/components/Inputs';

function EditInputField({
  inputValue,
  labelName,
  inputName,
  onEditEntity
}) {
  const renderValue = (value, defaultText = 'Adicionar') => (
    value || <span className='text-medium-gray'>{defaultText}</span>
  );

  return (
    <Row className='align-items-center mb-3'>
      <Col sm={6}>
        <IconLabel
          text={labelName}
          className='me-3 text-dark-gray'
        />
      </Col>
      <Col sm={6}>
        <EditInPlace
          name={inputName}
          as={TextInput}
          value={inputValue}
          onConfirm={onEditEntity}
        >
          <Truncate>
            {renderValue(inputValue)}
          </Truncate>
        </EditInPlace>
      </Col>
    </Row>
  );
}

export default EditInputField;
