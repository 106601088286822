import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import InfiniteScroll from '@/components/InfiniteScroll';
import DragScrollable from '@/components/DragScrollable';
import Table from '@/components/Table';
import TableIndex from '@/components/TableIndex';
import TableCell from '@/components/TableCell';
import PaginationCounter from '@/components/List/PaginationCounter';
import { toCurrencyString } from '@/number';
import DealTabEmpty from '@/components/EntityModal/DealTabEmpty';
import EntitySkeleton from '@/components/List/EntitySkeleton';
import EntityLink from '@/components/EntityLink';

const propTypes = {
  isFetchingNextPage: PropTypes.bool,
  fetchNextPage: PropTypes.func,
  hasNextPage: PropTypes.bool,
  isLoading: PropTypes.bool,
  entityId: PropTypes.string,
  entityType: PropTypes.string,
  itemsMetadata: PropTypes.object,
  items: PropTypes.array
};

function DealTabTable({
  fetchNextPage,
  hasNextPage,
  isLoading,
  entityId,
  entityType,
  itemsMetadata,
  items
}) {
  const hasItems = Boolean(items?.length);

  return (
    <div>
      <InfiniteScroll
        height='400px'
        length={items?.length || 0}
        loadItems={fetchNextPage}
        hasMore={hasNextPage || false}
      >
        <DragScrollable className='flex-grow-1'>
          <Table className='table-border-separate'>
            <thead className='position-sticky top-0 bg-white z-index-1'>
              <tr className='text-nowrap'>
                <th className={classnames(
                  'width-6',
                  'border-end',
                  'border-1',
                  'position-sticky',
                  'start-0',
                  'bg-white'
                )}>

                  <span className='px-3 invisible'>
                    <TableIndex index={0} />
                  </span>

                  <span className='px-3'>
                    Nome
                  </span>
                </th>
                <th>Funil</th>
                <th>Status</th>
                <th>Etapa</th>
                <th>Valor</th>
              </tr>
            </thead>

            <tbody className='position-relative'>
              {
                isLoading && <EntitySkeleton maxCols={4} iconless />
              }
              {
                !hasItems && !isLoading && (
                  <div className='w-100 d-flex justify-content-center position-absolute'>
                    <DealTabEmpty entityId={entityId} entityType={entityType} />
                  </div>
                )
              }
              {
                !isLoading && hasItems && items.map((item, index) => (
                  <tr key={index}>
                    <td className={classnames(
                      'align-middle',
                      'border-end',
                      'border-1',
                      'position-sticky',
                      'start-0',
                      'bg-white'
                    )}>
                      <span className='px-3'>
                        <TableIndex index={index + 1} />
                      </span>

                      <EntityLink
                        className='px-3 text-darker-gray fw-bold'
                        entity={{ id: item.id, type: 'deal' }}
                      >
                        {item.title}
                      </EntityLink>
                    </td>

                    <td className='align-middle'>
                      <TableCell value={item.stage?.funnel.name} />
                    </td>

                    <td className='align-middle'>
                      <TableCell value={item.dealStatus?.name} />
                    </td>

                    <td className='align-middle'>
                      <TableCell value={item.stage?.name} />
                    </td>

                    <td className='align-middle'>
                      {toCurrencyString(item.value)}
                    </td>
                  </tr>
                ))
              }
            </tbody>
          </Table>
        </DragScrollable>
      </InfiniteScroll>

      <div className='py-2'>
        <PaginationCounter
          show={!isLoading}
          model='deal'
          count={items?.length}
          max={itemsMetadata?.count}
        />
      </div>
    </div>
  );
}

DealTabTable.propTypes = propTypes;

export default DealTabTable;
