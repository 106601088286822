import React, { createContext, useContext } from 'react';
import { useStore } from 'zustand';

import { useOrganizationModalStore } from '@/store/organization-modal';

const OrganizationModalContext = createContext(undefined);

export function useOrganizationModalContext() {
  const context = useContext(OrganizationModalContext);

  if (context === undefined) {
    throw new Error('useOrganizationModalContext must be used within OrganizationModalProvider');
  }

  return context;
}

function useOrganizationModalStoreContext(selector) {
  const context = useOrganizationModalContext();

  return useStore(context.store, selector);
}

export const useOrganization = () => useOrganizationModalContext().organization;

export const useOnUpdate = () => useOrganizationModalContext().onUpdate;

export const useOnUpdatePerson = () => useOrganizationModalContext().onUpdatePerson;

export const useOnDelete = () => useOrganizationModalContext().onDelete;

export const useActiveTab = () => useOrganizationModalStoreContext(
  (state) => state.activeTab
);

export const useChangeTab = () => useOrganizationModalStoreContext(
  (state) => state.changeTab
);

export function OrganizationModalProvider({ children, value: componentValue }) {
  const { organization, onUpdate, onUpdatePerson, onDelete } = componentValue;
  const [store] = useOrganizationModalStore();

  const value = {
    store,
    organization,
    onUpdate,
    onUpdatePerson,
    onDelete
  };

  return (
    <OrganizationModalContext.Provider value={value}>
      {children}
    </OrganizationModalContext.Provider>
  );
}
