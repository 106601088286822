import { IMask } from 'react-imask';
import { format, parse } from 'date-fns';
import { MIN_YEAR, MAX_YEAR, REFERENCE_YEAR } from '@/date';
import { isBlank } from '@/utils';

// Referência para configurar uma nova/atual máscara
/** @type {Record<string, import('react-imask').IMask.AnyMaskedOptions>} */
export const MASK_CONFIGS = {
  cpf: {
    mask: '000.000.000-00',
    lazy: true
  },
  cnpj: {
    mask: '00.000.000/0000-00',
    lazy: true
  },
  cpf_cnpj: {
    mask: [
      { mask: '000.000.000-00' },
      { mask: '00.000.000/0000-00' }
    ],
    lazy: true
  },
  bank_slip: {
    mask: '00000.00000 00000.000000 00000.000000 0 00000000000000[0]',
    lazy: true
  },
  email: {
    mask: /^\S*$/u,
    lazy: true
  },
  date: {
    mask: Date,
    lazy: true,
    pattern: 'd{/}`m{/}`Y',
    min: new Date(MIN_YEAR, 0, 1),
    max: new Date(MAX_YEAR, 0, 1),
    format: (date) => format(date, 'dd/MM/yyyy'),
    parse: (value) => parse(value, 'dd/MM/yyyy', new Date())
  },
  phone: {
    mask: [
      { mask: '(00) 0000-0000' },
      { mask: '(00) 00000-0000' }
    ],
    lazy: true
  },
  whatsapp: {
    mask: [
      { mask: '{+}00 (00) 0000-0000' },
      { mask: '{+}00 (00) 00000-0000' },
      { mask: '{+}0[0][0] (00[0]) 000-0000' }
    ],
    lazy: true
  },
  postalCode: {
    mask: '00000-000',
    lazy: true
  },
  time: {
    mask: 'h{:}`m',
    lazy: true,
    blocks: {
      h: {
        mask: IMask.MaskedRange,
        from: 0,
        to: 23
      },
      m: {
        mask: IMask.MaskedRange,
        from: 0,
        to: 59
      }
    }
  },
  natural: {
    mask: Number,
    lazy: true,
    min: 0,
    scale: 0
  },
  monthDay: {
    mask: Date,
    lazy: true,
    pattern: 'd{/}`m',
    format: (date) => format(date, 'dd/MM'),
    parse: (value) => parse(value, 'dd/MM', new Date(REFERENCE_YEAR, 0, 1))
  },
  integer: {
    mask: Number,
    lazy: true,
    scale: 0,
    thousandsSeparator: '.',
    signed: true
  },
  credit_card: {
    mask: '0000 0000 0000 0000',
    lazy: true
  },
  cvv: {
    mask: '0000',
    lazy: true
  }
};

export function applyMask(value, maskConfig) {
  if (MASK_CONFIGS[maskConfig] && !isBlank(value)) {
    return IMask.pipe(value, MASK_CONFIGS[maskConfig]);
  }
  return null;
}

export function unmaskValue(value, maskConfig) {
  if (MASK_CONFIGS[maskConfig] && !isBlank(value)) {
    const mask = IMask.createMask(MASK_CONFIGS[maskConfig]);
    mask.resolve(value);
    return mask.unmaskedValue;
  }
  return null;
}
