import React from 'react';
import PropTypes from 'prop-types';
import { toCurrencyString } from '@/number';
import { pluralize } from '@/utils';

import DotList from '@/components/DotList';
import Icon from '@/components/Icon';
import Badge from '@/components/Badge';
import DealTabTable from '@/components/EntityModal/DealTabTable';

import { useInfiniteDeals } from '@/api';

DealTab.propTypes = {
  entityId: PropTypes.number.isRequired,
  entityType: PropTypes.string.isRequired
};

DealTab.defaultProps = {
  entityType: 'organization'
};

function DealTab({ entityId, entityType }) {
  const params = { [`${entityType}_id_eq`]: entityId };

  const {
    data:
    deals,
    isLoading: isLoadingDeals,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage
  } = useInfiniteDeals({
    config: {
      staleTime: 1000
    },
    params
  });

  const dealsData = deals?.pages.flatMap((page) => page.data) || [];
  const dealsMetadata = deals?.pages?.[0]?.meta;

  const dealTotal = toCurrencyString(dealsMetadata?.total_amount);

  const statusBadges = [
    {
      count: dealsMetadata?.won_count,
      icon: 'won-deal',
      text: pluralize('Ganho', dealsMetadata?.won_count)
    },
    {
      count: dealsMetadata?.ongoing_count,
      icon: 'active-deal',
      text: 'Em andamento'
    },
    {
      count: dealsMetadata?.lost_count,
      icon: 'lost-deal',
      text: pluralize('Perdido', dealsMetadata?.lost_count)
    }
  ];

  return (
    <div className='mx-3 mb-3 bg-white p-4 rounded'>
      <div className='d-flex align-items-center gap-3'>
        <h2 className='mb-0'>Negócios</h2>
        <h4 className='mb-0'>{dealTotal}</h4>
      </div>
      <div className='pt-3 pb-5'>
        <DotList
          className='d-flex'
          items={[
            <Badge variant='dark-gray' className='fs-6 h-100 d-flex align-items-center' key='badge'>
              {dealsMetadata?.total_count || 0} {pluralize('negócio', dealsMetadata?.total_count)}
            </Badge>,
            <div className='d-flex gap-2' key='status'>
              {
                statusBadges.map(({ text, count, icon }) => (
                  <Badge
                    key={text}
                    variant='light'
                    className='text-dark-gray fs-6 d-flex align-items-center'
                  >
                    <Icon name={icon} size='sm' className='me-1' />
                    {count || 0} {text}
                  </Badge>
                ))
              }

            </div>
          ]}
        />
      </div>
      <DealTabTable
        entityId={entityId}
        entityType={entityType}
        isFetchingNextPage={isFetchingNextPage}
        fetchNextPage={fetchNextPage}
        hasNextPage={hasNextPage}
        isLoading={isLoadingDeals}
        itemsMetadata={dealsMetadata}
        items={dealsData}
      />
    </div>
  );
}

export default DealTab;
