import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import FetchableSelect from '@/components/Inputs/FetchableSelect';
import { useAuth } from '@/lib/auth';
import { useEmailRecipients } from '@/api';
import Icon from '@/components/Icon';

const propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  multiple: PropTypes.bool,
  isClearable: PropTypes.bool,
  isSearchable: PropTypes.bool,
  defaultValue: PropTypes.string,
  onChange: PropTypes.func,
  error: PropTypes.string,
  entityId: PropTypes.number,
  entityType: PropTypes.oneOf(['organization', 'person', 'deal'])
};

const defaultProps = {
  name: '',
  label: '',
  placeholder: '',
  multiple: false,
  isClearable: false,
  isSearchable: false,
  defaultValue: null,
  error: null,
  entityId: 0,
  entityType: 'deal',
  onChange: () => {}
};

function EmailSelect(props) {
  const { error, entityId, entityType } = props;
  const { user } = useAuth();

  const transformer = useCallback((fetchedData) => (
    fetchedData.data.map((item) => ({
      label: `${item.name} <${item.email}>`,
      value: item.email,
      leftAdornment: <Icon name={item.type ?? 'person'} />
    }))
  ), [user]);

  return (
    <FetchableSelect
      {...props}
      transformer={transformer}
      query={useEmailRecipients}
      queryArgs={{ params: { entity_id: entityId, entity_type: entityType } }}
      error={error}
      isCreatable={true}
    />
  );
}

EmailSelect.propTypes = propTypes;
EmailSelect.defaultProps = defaultProps;

export default EmailSelect;
