import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useAuth } from '@/lib/auth';
import { useCreateACContact } from '@/api';
import Icon from '@/components/Icon';
import Button from '@/components/Button';
import LoadingButton from '@/components/LoadingButton';
import ModalAgendorChat from '@/components/WhatsApp/ModalAgendorChat';
import IframeAgendorChat from '@/components/AgendorChat/IframeAgendorChat';
import { useTracking } from '@/lib/tracking';
import { isOptin } from '@/utils';

WhatsappAndACButtons.propTypes = {
  name: PropTypes.string,
  phone: PropTypes.string
};

WhatsappAndACButtons.defaultProps = {
  name: '',
  phone: ''
};

function WhatsappAndACButtons({ name, phone }) {
  const { user } = useAuth();
  const tracker = useTracking();
  const [showACModal, setShowACModal] = useState(false);
  const [showIframeAgendorChat, setShowIframeAgendorChat] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [iframeUrl, setIframeUrl] = useState(false);
  const mutation = useCreateACContact();
  const hasActiveAccountChat = user?.account?.accountChat?.active;
  const isIframeAllowedAccount = isOptin(user?.features?.agendorChatIframeEnabled);

  const handleOpenAgendorChat = () => {
    tracker.trackWhatsappPopoverClicked({ user, action: 'Ac' });
    setIsLoading(true);
    if (!hasActiveAccountChat) {
      setIsLoading(false);
      setShowACModal(true);
    } else {
      const params = { phone_number: phone, name };
      mutation.mutate(params, {
        onSuccess: ({ url }) => {
          setIsLoading(false);
          if (isIframeAllowedAccount) {
            setIframeUrl(url);
            setShowIframeAgendorChat(true);
          } else {
            window.open(url, '_blank');
          }
        }
      });
    }
  };

  const handleOpenWhatsApp = () => {
    tracker.trackWhatsappPopoverClicked({ user, action: 'Extensao' });
    window.open(`https://wa.me/${phone?.replace(/[\D]+/gu, '')}`, '_blank');
  };

  return (
    <>
      <ModalAgendorChat
        show={showACModal}
        onClose={() => setShowACModal(false)}
      />
      <IframeAgendorChat
        show={showIframeAgendorChat}
        onClose={() => setShowIframeAgendorChat(false)}
        iframeSrc={iframeUrl}
      />
      <div className='d-flex'>
        <LoadingButton
          size='sm'
          variant='light'
          loadingText='Carregando...'
          isLoading={isLoading}
          className='w-100 mb-2 me-2'
          onClick={handleOpenAgendorChat}
        >
          <Icon name='whatsapp' className='me-1 text-primary' />
          <span className='text-primary text-nowrap'>
            Agendor Chat
          </span>
        </LoadingButton>
        <Button
          size='sm'
          variant='light'
          className='w-100 mb-2'
          onClick={handleOpenWhatsApp}
        >
          <Icon name='whatsapp' className='me-1 text-primary' />
          <span className='text-primary text-nowrap'>
            WhatsApp
          </span>
        </Button>
      </div>
    </>
  );
}

export default WhatsappAndACButtons;
