import React, { useState } from 'react';
import { Nav, Tab } from 'react-bootstrap';
import classnames from 'classnames';

import History from '@/components/EntityModal/Activities/History';
import IconLabel from '@/components/IconLabel';
import Skeleton from '@/components/Skeleton';
import { useScreenSize } from '@/hooks';
import Icon from '@/components/Icon';
import Tooltip from '@/components/Tooltip';

const NAVS = {
  activitiesHistory: 'activitiesHistory',
  entityDetails: 'entityDetails'
};

function ActivityNavItem({ label, iconName, active, loading, onClick }) {
  if (loading) {
    return <Skeleton width={200} height={30} />;
  }

  return (
    <Nav.Item>
      <Nav.Link
        onClick={onClick}
        className={classnames(
          'me-4 px-2',
          { 'border-bottom border-darker-gray': active },
          { 'text-dark-gray': !active }
        )}
      >
        <IconLabel
          icon={{ name: iconName }}
          text={label}
          textSize='h4'
        />
      </Nav.Link>
    </Nav.Item>
  );
}

function ActivityNavs({
  entity,
  entityName,
  activities,
  isFetchingActivities,
  isFetchingNextPageActivities,
  fetchNextPageActivities,
  hasNextPageActivities,
  isLoading,
  onUpdateActivity,
  onDeleteActivity,
  detailsNavName,
  infoText,
  children
}) {
  const { isMD } = useScreenSize();
  const [navKey, setNavKey] = useState(NAVS.activitiesHistory);
  return (
    <div className='pt-2'>
      {
        navKey === NAVS.activitiesHistory && (isMD
          ? (
            <div className='d-flex align-items-center'>
              <Tooltip content={infoText} placement='left'>
                <div className={`d-flex align-items-center bg-white rounded
                  px-3 py-1 mb-n9 w-20 ms-auto`}>
                  <Icon name='info' size='sm' />
                </div>
              </Tooltip>
            </div>
          )
          : <div className='text-end fw-bold mb-n9 pb-2'>{infoText}</div>
        )
      }
      <Tab.Container
        activeKey={navKey}
        defaultActiveKey={NAVS.activitiesHistory}
      >
        <Nav>
          <ActivityNavItem
            label='Histórico de atividades'
            iconName='task'
            active={navKey === NAVS.activitiesHistory}
            loading={isLoading}
            onClick={() => setNavKey(NAVS.activitiesHistory)}
          />
          {
            isMD
              ? (
                <ActivityNavItem
                  label={detailsNavName}
                  iconName='info'
                  active={navKey === NAVS.entityDetails}
                  loading={isLoading}
                  onClick={() => setNavKey(NAVS.entityDetails)}
                />
              )
              : null
          }
        </Nav>
        <Tab.Content
          style={{ marginTop: '-1px' }}
          className={classnames(
            'p-4',
            'border-light-gray border-top',
            { 'bg-white': navKey === NAVS.activitiesHistory }
          )}
        >
          <Tab.Pane eventKey={NAVS.activitiesHistory}>
            <History
              entity={entity}
              activities={activities}
              isFetchingActivities={isFetchingActivities}
              isFetchingNextPageActivities={isFetchingNextPageActivities}
              fetchNextPageActivities={fetchNextPageActivities}
              hasNextPageActivities={hasNextPageActivities}
              loading={isLoading}
              onUpdateActivity={onUpdateActivity}
              onDeleteActivity={onDeleteActivity}
              entityName={entityName}
            />
          </Tab.Pane>
          {
            isMD
              ? (
                <Tab.Pane eventKey={NAVS.entityDetails}>
                  {children}
                </Tab.Pane>
              )
              : null
          }
        </Tab.Content>
      </Tab.Container>
    </div>
  );
}

export default ActivityNavs;
