import React from 'react';
import { Col, Row } from 'react-bootstrap';

import IconLabel from '@/components/IconLabel';
import { EditInPlace, MaskedInput } from '@/components/Inputs';

function BirthYearField({
  inputValue,
  labelName,
  inputName,
  onEditEntity
}) {
  const renderValue = (value, defaultText = 'Adicionar') => (
    value || <span className='text-medium-gray'>{defaultText}</span>
  );

  return (
    <Row className='align-items-center mb-3'>
      <Col sm={6}>
        <IconLabel
          text={labelName}
          className='me-3 text-dark-gray'
        />
      </Col>
      <Col sm={6}>
        <EditInPlace
          name={inputName}
          as={MaskedInput}
          value={inputValue?.toString()}
          placeholder='0000'
          onConfirm={onEditEntity}
          maskType='natural'
          autoComplete='off'
          maxLength={4}
        >
          {renderValue(inputValue)}
        </EditInPlace>
      </Col>
    </Row>
  );
}

export default BirthYearField;
