import React, { useState } from 'react';
import { useAlert } from 'react-alert';
import PropTypes from 'prop-types';
import { useCreatePerson } from '@/api';
import Icon from '@/components/Icon';
import Button from '@/components/Button';
import Popover from '@/components/Popover';
import APIErrorMessage from '@/components/APIErrorMessage';
import CreatePersonForm from '@/components/CreatePersonForm';
import { getValidationErrors } from '@/utils';

const propTypes = {
  organizationId: PropTypes.number.isRequired
};

const defaultProps = {};

function PersonFormPopover({ organizationId }) {
  const [showModal, setShowModal] = useState(false);
  const alert = useAlert();
  const createPersonMutation = useCreatePerson();

  const handleSubmit = async (formData, { setFieldError }) => {
    try {
      await createPersonMutation.mutateAsync(formData);
      alert.show(
        'Pessoa salva com sucesso!',
        { variant: 'success', timeout: 5000 }
      );
      setShowModal(false);
    } catch (err) {
      const errors = getValidationErrors(err, 'person');

      Object.entries(errors).forEach(([key, message]) => {
        setFieldError(key, message);
      });
      alert.show(
        <APIErrorMessage err={err} resource='person' action='create' />,
        { variant: 'danger' }
      );
    }
  };

  return (
    <Popover
      flip
      hide={!showModal}
      className='max-width-6 me-2'
      placement='left-start'
      showArrow
      rootCloseEvent='mousedown'
      content={({ onHide }) => (

        <div className='mt-2'>
          <h3>Adicionar pessoa</h3>

          <CreatePersonForm
            initialData={{ organization_id: organizationId }}
            onSubmit={handleSubmit}
            onCancel={onHide}
            focusName
            hideCollapse
            saveMessageModal
          >
          </CreatePersonForm>
        </div>
      )}
    >
      <Button
        variant='light'
        className='text-primary py-1 px-3'
        onClick={() => setShowModal(true)}
      >
        <Icon name='plus' size='sm' className='me-1' />
        Adicionar pessoa
      </Button>
    </Popover>
  );
}

PersonFormPopover.propTypes = propTypes;
PersonFormPopover.defaultProps = defaultProps;

export default PersonFormPopover;
