import React, { useState } from 'react';
import { useAlert } from 'react-alert';

import Form from '@/components/EntityModal/Activities/Form';
import ActivityNavs from '@/components/EntityModal/Activities/ActivityNavs';
import { useOrganization } from '@/contexts/organizationModal';
import APIErrorMessage from '@/components/APIErrorMessage';
import {
  useCreateActivity,
  useUpdateActivity,
  useDeleteActivity,
  useInfiniteActivities
} from '@/api';
import sortBy from 'lodash/sortBy';
import { useScreenSize } from '@/hooks';
import Sidebar from '@/components/EntityModal/Organization/Sidebar/Sidebar';

const PER_PAGE = 30;

const ENTITY_NAME = 'organization';

function createActivityListParams(entity, id) {
  return { per_page: PER_PAGE, entity_id: Number(id), entity };
}

function Activities() {
  const [, setIsModified] = useState(false);
  const organization = useOrganization();
  const id = organization.id;
  const alert = useAlert();
  const listParams = createActivityListParams('organization', id);
  const createActivityMutation = useCreateActivity({ listParams });
  const updateActivityMutation = useUpdateActivity();
  const deleteActivityMutation = useDeleteActivity();
  const isActivitiesEnabled = Boolean(organization?.id);
  const { isMD } = useScreenSize();
  const {
    data: { pages: activityPages } = {},
    isLoading,
    isFetching: isFetchingActivities,
    isFetchingNextPage: isFetchingNextPageActivities,
    fetchNextPage: fetchNextPageActivities,
    hasNextPage: hasNextPageActivities
  } = useInfiniteActivities({
    params: listParams,
    config: { enabled: isActivitiesEnabled, staleTime: 1000 }
  });
  const activities = sortBy(activityPages?.flatMap((page) => page.data), 'createdAt').reverse();

  const onCreateActivity = (params, done) => {
    createActivityMutation.mutate(params, {
      onError: (err) => {
        alert.show(
          <APIErrorMessage err={err} resource='activities' action='create' />,
          { variant: 'danger', timeout: 5000 }
        );
        done?.(err);
      },
      onSuccess: () => {
        alert.show(
          'Atividade cadastrada com sucesso!',
          { variant: 'success', timeout: 5000 }
        );
        done?.();
      }
    });
  };

  const onUpdateActivity = (activityId, params, done) => {
    updateActivityMutation.mutate({ activityId, params }, {
      onError: (err) => {
        alert.show(
          <APIErrorMessage err={err} resource='activities' action='update' />,
          { variant: 'danger', timeout: 5000 }
        );
        done?.(err);
      },
      onSuccess: () => {
        alert.show(
          'Atividade atualizada com sucesso!',
          { variant: 'success', timeout: 5000 }
        );
        setIsModified(true);
        done?.();
      }
    });
  };

  const onDeleteActivity = (activityId, done) => {
    deleteActivityMutation.mutate({ activityId }, {
      onError: (err) => {
        alert.show(
          <APIErrorMessage err={err} resource='activities' action='destroy' />,
          { variant: 'danger', timeout: 5000 }
        );
        done?.(err);
      },
      onSuccess: () => {
        alert.show(
          'Atividade excluída com sucesso!',
          { variant: 'success', timeout: 5000 }
        );
        setIsModified(true);
        done?.();
      }
    });
  };

  return (
    <div className={isMD ? 'mx-3' : 'ms-3'}>
      <Form entityName={ENTITY_NAME} entityId={id} onCreate={onCreateActivity} />
      <ActivityNavs
        entity={organization}
        entityName={ENTITY_NAME}
        activities={activities}
        isFetchingActivities={isFetchingActivities}
        isFetchingNextPageActivities={isFetchingNextPageActivities}
        fetchNextPageActivities={fetchNextPageActivities}
        hasNextPageActivities={hasNextPageActivities}
        isLoading={isLoading}
        onUpdateActivity={onUpdateActivity}
        onDeleteActivity={onDeleteActivity}
        detailsNavName='Detalhes da Empresa'
        infoText='Mostrando atividades da empresa, pessoas e negócios relacionados'
      >
        <Sidebar/>
      </ActivityNavs>
    </div>
  );
}

export default Activities;
