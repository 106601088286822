import React, { useRef, useState } from 'react';
import { useAlert } from 'react-alert';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import {
  useEmailSignatures,
  useCreateEmailSignature,
  useUpdateEmailSignature,
  useDeleteEmailSignature,
  useCreateEmail,
  useEmailSender
} from '@/api';
import { useAuth } from '@/lib/auth';
import { useTracking } from '@/lib/tracking';
import { filterForImages, filterForDocuments } from '@/utils';
import Modal from '@/components/Modal';
import Form from '@/components/Form';
import APIErrorMessage from '@/components/APIErrorMessage';
import EmailSignatureModal from '@/components/Email/EmailSignatureModal';
import { EmailVerifyAlert } from '@/feature/email';
import EmailFormContent from '@/components/Email/EmailFormContent';
import { useStoredState } from '@/hooks';

const propTypes = {
  show: PropTypes.bool,
  onClose: PropTypes.func,
  entityId: PropTypes.number,
  entityType: PropTypes.oneOf(['organization', 'person', 'deal']),
  subject: PropTypes.string,
  body: PropTypes.string,
  to: PropTypes.arrayOf(PropTypes.string),
  cc: PropTypes.arrayOf(PropTypes.string),
  bcc: PropTypes.arrayOf(PropTypes.string)
};

const defaultProps = {
  show: false,
  onClose: () => { },
  entityId: null,
  subject: '',
  body: '',
  to: [],
  cc: [],
  bcc: [],
  entityType: 'deal'
};

const storageKeyLib = (entityType) => {
  const defaultStorage = 'deals-emails';
  const lib = {
    'deal': defaultStorage,
    'organization': 'organizations-emails',
    'person': 'people-emails'
  };
  return lib[entityType] || defaultStorage;
};

const emailRequired = Yup.string()
  .required()
  .email('Existe e-mail(s) inválido(s).');

const emailSchema = Yup.object().shape({
  to: Yup.array()
    .of(emailRequired)
    .min(1, 'Informe pelo menos um destinatário.'),
  cc: Yup.array()
    .of(emailRequired)
    .nullable(),
  bcc: Yup.array()
    .of(emailRequired)
    .nullable(),
  subject: Yup.string().required('Assunto do e-mail é obrigatório.'),
  body: Yup.string()
    .test('body', 'Corpo do e-mail é obrigatório.', (value) => {
      const text = Boolean(value.replace(/<[^>]*>?/gu, ''));
      const image = Boolean(value.match(/<img.*?src="(?:.*?)"[^>]+>/gu, ''));
      return text || image;
    })
});

function CreateEmailModal({
  entityId, entityType, show, onClose, subject, body,
  to, cc, bcc, isNewEmail
}) {
  const activityListParams = { per_page: 30, entity_id: Number(entityId), entity: entityType };
  const createEmailMutation = useCreateEmail({ activityListParams });
  const { user } = useAuth();
  const tracker = useTracking();
  const formRef = useRef(null);

  const alert = useAlert();
  const [isSubmitting, setSubmitting] = useState(false);
  const [ccHidden, setCcHidden] = useState(!cc?.length);
  const [bccHidden, setBccHidden] = useState(!bcc?.length);
  const [disableAction, setDisableAction] = useState(false);
  const [clearFiles, setClearFiles] = useState(false);
  const [hasTemplate, setHasTemplate] = useState(false);
  const [showEmailSignatureModal, setShowEmailSignatureModal] = useState(false);
  const [showEmailSignatureDeleteModal, setShowEmailSignatureDeleteModal] = useState(false);

  const {
    store: storeEmail,
    value: storedEmail
  } = useStoredState(storageKeyLib(entityType), {});

  const defaultStoredEmail = () => ({
    subject: defaultProps.subject,
    body: defaultProps.body,
    to: defaultProps.to,
    cc: defaultProps.cc,
    bcc: defaultProps.bcc
  });

  const storedEmailData = storedEmail[entityId] ?? defaultStoredEmail();

  const {
    data: emailSenderData,
    isLoading
  } = useEmailSender({
    config: { enabled: show }
  });
  const emailSender = emailSenderData?.data ?? {};
  const verified = emailSender.confirmed;
  const from = emailSender.email;

  const {
    data: emailSignaturesData,
    isLoading: isLoadingEmailSignatures
  } = useEmailSignatures({
    config: { enabled: show }
  });

  const emailSignatures = isLoadingEmailSignatures ? [] : emailSignaturesData?.data ?? [];
  const emailSignature = emailSignatures?.at?.(0) ?? {};

  const createMutation = useCreateEmailSignature();
  const updateMutation = useUpdateEmailSignature();
  const deleteMutation = useDeleteEmailSignature();

  const [initialValues, setInitialValues] = useState({
    ...(
      isNewEmail
        ? {
          to: storedEmailData.to,
          subject: storedEmailData.subject,
          body: storedEmailData.body,
          cc: storedEmailData.cc,
          bcc: storedEmailData.bcc
        }
        : {
          to,
          subject,
          body,
          cc,
          bcc
        }
    ),
    entity_id: entityId,
    entity_type: entityType,
    user_id: user?.id
  });

  const setBodyValue = (value) => {
    formRef.current.values.body = value;
    setInitialValues({ ...initialValues, body: value });
  };

  const showCC = () => setCcHidden(!ccHidden);
  const showBCC = () => setBccHidden(!bccHidden);

  const handleEmailSignatureSubmit = (formData) => {
    const isUpdate = emailSignature.id && emailSignature.id > 0;
    if (isUpdate) {
      updateMutation.mutate({ id: emailSignature.id, params: formData }, {
        onSuccess: () => {
          setShowEmailSignatureModal(false);
          alert.show(
            'Assinatura de e-mail atualizada com sucesso',
            { variant: 'success', timeout: 5000 }
          );
        },
        onError: (err) => {
          alert.show(
            <APIErrorMessage err={err} resource='emailSignature' action='update' />,
            { variant: 'danger', timeout: 5000 }
          );
        }
      });
    } else {
      createMutation.mutate(formData, {
        onSuccess: () => {
          setShowEmailSignatureModal(false);
          alert.show(
            'Assinatura de e-mail adicionada com sucesso',
            { variant: 'success', timeout: 5000 }
          );
        },
        onError: (err) => {
          alert.show(
            <APIErrorMessage err={err} resource='emailSignatures' action='create' />,
            { variant: 'danger', timeout: 5000 }
          );
        }
      });
    }
  };

  const handleEmailSignatureDelete = () => {
    deleteMutation.mutate({ id: emailSignature.id }, {
      onSuccess: () => {
        alert.show(
          'Assinatura excluída!',
          { variant: 'success', timeout: 5000 }
        );
        setShowEmailSignatureDeleteModal(false);
      },
      onError: () => {
        alert.show(
          'Erro ao excluir assinatura!',
          { variant: 'danger', timeout: 5000 }
        );
        setShowEmailSignatureDeleteModal(false);
      }
    });
  };

  const closeModal = () => {
    setCcHidden(true);
    setBccHidden(true);
    onClose?.();
  };

  const removeStoredEmail = () => {
    const newStoredEmail = { ...storedEmail };
    newStoredEmail[entityId] = defaultStoredEmail();
    storeEmail(newStoredEmail);
  };

  const resetInitialValues = () => {
    setInitialValues({
      to: defaultProps.to,
      subject: defaultProps.subject,
      body: defaultProps.body,
      cc: defaultProps.cc,
      bcc: defaultProps.bcc,
      entity_id: entityId,
      entity_type: entityType,
      user_id: user.id
    });
  };

  const persistInitialValues = () => {
    setInitialValues({
      to: storedEmailData.to,
      subject: storedEmailData.subject,
      body: storedEmailData.body,
      cc: storedEmailData.cc,
      bcc: storedEmailData.bcc,
      [`${entityType}_id`]: entityId,
      user_id: user.id
    });
  };

  const handleCloseAndRemoveStoredEmail = () => {
    if (isNewEmail) {
      removeStoredEmail();
      resetInitialValues();
    }

    closeModal();
  };

  const handleHide = () => {
    if (isNewEmail) {
      persistInitialValues();
    }

    closeModal();
  };

  const handleFormSubmit = (formData) => {
    setSubmitting(true);
    const emailData = {
      ...formData,
      images: filterForImages(formData?.attachments),
      documents: filterForDocuments(formData?.attachments)
    };
    createEmailMutation.mutate(emailData, {
      onSuccess: () => {
        tracker.trackEmailSent({
          user,
          hasTemplate,
          entity: entityType,
          entityId,
          hasCc: emailData?.cc?.length,
          hasBcc: emailData?.bcc?.length
        });
        setHasTemplate(false);
        setSubmitting(false);
        alert.show(
          'E-mail enviado com sucesso',
          { variant: 'success', timeout: 3000 }
        );
        handleCloseAndRemoveStoredEmail();
      },
      onError: (err) => {
        setSubmitting(false);
        alert.show(
          <APIErrorMessage err={err} resource='emails' action='send' />,
          { variant: 'danger', timeout: 5000 }
        );
      }
    });
  };

  return (
    <Modal
      show={show}
      onHide={handleHide}
      size='xxl'
      backdrop='static'
      bodyClassName='p-8'
    >
      <EmailSignatureModal
        isCreating={!emailSignature?.id}
        onSubmit={handleEmailSignatureSubmit}
        onCancel={() => setShowEmailSignatureModal(false)}
        emailSignature={emailSignature}
        show={showEmailSignatureModal}
        renderName={false}
      />
      <h2 className='text-darker-gray me-2'>Novo e-mail</h2>

      <EmailVerifyAlert userHasVerified={verified} isLoading={isLoading} />

      <Form
        enableReinitialize
        name='email-signature-modal'
        ref={formRef}
        className='mt-3'
        onSubmit={handleFormSubmit}
        initialValues={initialValues}
        validationSchema={emailSchema}
      >
        {
          ({ handleBlur, values }) => (
            <EmailFormContent
              from={from}
              entityId={entityId}
              entityType={entityType}
              ccHidden={ccHidden}
              showCC={showCC}
              bccHidden={bccHidden}
              showBCC={showBCC}
              handleBlur={handleBlur}
              setShowEmailSignatureModal={setShowEmailSignatureModal}
              clearFiles={clearFiles}
              isSubmitting={isSubmitting}
              disableAction={disableAction}
              setClearFiles={setClearFiles}
              setDisableAction={setDisableAction}
              handleCancel={handleCloseAndRemoveStoredEmail}
              setHasTemplate={setHasTemplate}
              emailSignatures={emailSignatures}
              setBodyValue={setBodyValue}
              values={values}
              setShowEmailSignatureDeleteModal={setShowEmailSignatureDeleteModal}
              handleEmailSignatureDelete={handleEmailSignatureDelete}
              showEmailSignatureDeleteModal={showEmailSignatureDeleteModal}
              storedEmail={storedEmail}
              storeEmail={storeEmail}
              isNewEmail={isNewEmail}
            />
          )
        }
      </Form>
    </Modal>
  );
}

CreateEmailModal.propTypes = propTypes;
CreateEmailModal.defaultProps = defaultProps;

export default CreateEmailModal;
