import React from 'react';

import DetailsCard from '@/components/EntityModal/DetailsCard';
import { canEditOrganization } from '@/policies';
import ShowMoreDescription from '@/components/EntityModal/ShowMoreDescription';
import EditInputField from '@/components/EntityModal/EditInputField';
import EditSelectField from '@/components/EntityModal/EditSelectField';
import DateField from '@/components/EntityModal/DateField';
import CnpjMaskerInputField from '@/components/EntityModal/CnpjMaskerInputField';
import DetailsCustomField from '@/components/EntityModal/DetailsCustomField';
import UserField from '@/components/EntityModal/UserField';
import EditUserField from '@/components/EntityModal/EditUserField';
import { filter } from 'lodash';

const renderBasicDataCustomFields = (customFields, entity, entityType, onEditEntity) => (
  filter(customFields, { section: 'basic_data' }).map(
    ({ identifier, customFieldOptions, type, name }) => (
      <DetailsCustomField
        onEdit={(params, done) => onEditEntity(params, done, { ...entity, entityType })}
        key={identifier}
        identifier={identifier}
        customFieldOptions={customFieldOptions}
        type={type}
        name={name}
        rawValue={entity?.customFields[identifier]}
        placeholder='Adicionar'
      />
    )
  )
);

function OrganizationBasicData({
  organization,
  onEditEntity,
  categories,
  sectors,
  leadOrigins,
  entityCustomFields,
  entityType
}) {
  const categoriesAdapted = categories.map((category) => ({
    label: category.name,
    value: category.id
  }));

  const sectorsAdapted = sectors.map((sector) => ({
    label: sector.name,
    value: sector.id
  }));

  const leadOriginsAdapted = leadOrigins.map((origin) => ({
    label: origin.name,
    value: origin.id
  }));

  const basicDataCustomFields = renderBasicDataCustomFields(
    entityCustomFields,
    organization,
    entityType,
    onEditEntity
  );

  return (
    <DetailsCard title='Dados básicos da empresa' className='mb-3'>
      <ShowMoreDescription
        description={organization?.description}
        onEditEntity={(params, done) => onEditEntity(params, done, { ...organization })}
      />
      <CnpjMaskerInputField
        inputValue={organization?.cnpj}
        labelName='CNPJ'
        inputName='cnpj'
        onEditEntity={(params, done) => onEditEntity(params, done, { ...organization })}
      />
      <EditInputField
        inputValue={organization?.legalName}
        labelName='Razão social'
        inputName='legal_name'
        onEditEntity={(params, done) => onEditEntity(params, done, { ...organization })}
      />
      <EditSelectField
        labelName='Categoria'
        selectName='category_id'
        onEditEntity={(params, done) => onEditEntity(params, done, { ...organization })}
        options={categoriesAdapted}
        entity={organization.category}
        entityName={organization.category?.name}
        entityId={organization.category?.id}
      />
      <EditSelectField
        labelName='Origem'
        selectName='lead_origin_id'
        onEditEntity={(params, done) => onEditEntity(params, done, { ...organization })}
        options={leadOriginsAdapted}
        entity={organization.leadOrigin}
        entityName={organization.leadOrigin?.name}
        entityId={organization.leadOrigin?.id}
      />
      <UserField
        labelName='Cadastrado por'
        avatarName={organization.user?.name}
        avataerUrl={organization.user?.avatarUrl}
      />
      <EditUserField
        labelName='Responsável'
        selectName='owner_user_id'
        avatarId={organization?.ownerUser?.id}
        avatarName={organization?.ownerUser?.name}
        avatarUrl={organization?.ownerUser?.avatarUrl}
        onEditEntity={(params, done) => onEditEntity(params, done, { ...organization })}
        canEditPolicy={canEditOrganization}
      />
      <EditSelectField
        labelName='Setor'
        selectName='sector_id'
        onEditEntity={(params, done) => onEditEntity(params, done, { ...organization })}
        options={sectorsAdapted}
        entity={organization.sector}
        entityName={organization.sector?.name}
        entityId={organization.sector?.id}
      />
      <DateField labelName='Data de cadastro' stringDate={organization?.createdAt} />
      <DateField labelName='Última atualização' stringDate={organization?.updatedAt} />

      {basicDataCustomFields}
    </DetailsCard>
  );
}

export default OrganizationBasicData;
