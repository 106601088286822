import React, { useMemo, useState } from 'react';
import { useAlert } from 'react-alert';
import { useCurrentAccount, useUpdateVoipToggleAccount, useVoipVerify } from '@/api';
import APIErrorMessage from '@/components/APIErrorMessage';
import LoadingWrapper from '@/components/LoadingWrapper';
import Popover from '@/components/Popover';
import NoBalance from '@/components/Voip/CallPopover/Content/NoBalance';
import VoipUpdateAccountModal from '@/components/Voip/UpdateAccountModal';
import Inactive from '@/components/Voip/CallPopover/Content/Inactive';
import {
  VoipPopoverProvider,
  useCurrentTab
} from '@/contexts/voipPopover';
import Calling from '@/components/Voip/CallPopover/Content/Calling';
import ContactList from '@/feature/voip/ContactList/ContactList';
import Button from '@/components/Button';
import Icon from '@/components/Icon';
import { useAuth } from '@/lib/auth';
import Tooltip from '@/components/Tooltip';
import { useTracking } from '@/lib/tracking';

const VoipButton = ({ disabled, onClick }) => (
  <Button
    id='voip-button'
    className='d-flex align-items-center text-nowrap w-100'
    disabled={disabled}
    aria-label='Fazer ligação'
    onClick={onClick}
  >
    <Icon name='call' className='me-2' size='fixed' />
    <span>Fazer ligação</span>
  </Button>
);

function PopoverContent({ voipStatus, onClickActivate, isActivatingVoip }) {
  const currentTab = useCurrentTab();

  const popoverContent = useMemo(() => {
    switch (voipStatus) {
      case 'disabled':
        return (
          <Inactive
            onClickActivate={onClickActivate}
            isLoading={isActivatingVoip}
          />
        );
      case 'no-balance':
        return <NoBalance />;
      default:
        return <ContactList />;
    }
  }, [voipStatus, onClickActivate, isActivatingVoip]);

  return (
    <div>
      {currentTab === 'contacts' ? popoverContent : <Calling />}
    </div>
  );
}

function VoipPopover({ entity, entityType, onUpdatePerson, onUpdateOrganization }) {
  const [showUpdateAccountModal, setShowUpdateAccountModal] = useState(false);
  const tracker = useTracking();

  const { user } = useAuth();
  const alert = useAlert();
  const { data: voip, isSuccess } = useVoipVerify({ config: { staleTime: 1000 } });
  const { data: { data: accountData } = {} } = useCurrentAccount();
  const voipToggleAccountMutation = useUpdateVoipToggleAccount();

  const enableVoip = (done) => {
    voipToggleAccountMutation.mutate({}, {
      onError: (err) => {
        alert.show(
          <APIErrorMessage err={err} resource='modules/voip' action='update' />,
          { variant: 'danger', timeout: 5000 }
        );
      },
      onSuccess: (data) => {
        if (data.created) {
          const alertLabel = data.enabled ? 'ativado' : 'desativado';
          alert.show(
            `Telefone virtual ${alertLabel} com sucesso!`,
            { variant: 'success', timeout: 5000 }
          );
          done?.();
        } else {
          alert.show(
            `Falha ao alterar estado do telefone
            virtual.${data.error ? ` Motivo: ${data.error}` : ''}`,
            { variant: 'danger', timeout: 5000 }
          );
        }
      }
    });
  };

  const voipStatus = useMemo(() => getVoipStatus(voip), [voip]);
  const popoverPlacement = voipStatus === 'available' ? 'left-start' : 'bottom';

  const onUpdateAccount = () => {
    enableVoip(() => setShowUpdateAccountModal(false));
  };

  const onClickActivate = () => {
    if (!accountData?.cpfCnpj) {
      return setShowUpdateAccountModal(true);
    }

    return enableVoip();
  };

  if (!user.admin) {
    if (['disabled', 'no-balance'].includes(voipStatus)) {
      const messageMap = {
        'no-balance': `Seu saldo não é suficiente.
              Solicite ao administrador da conta para adicionar créditos.`,
        'disabled': `O módulo de telefone virtual está desativado.
            Peça para o administrador da conta ativá-lo.`
      };

      return (
        <Tooltip
          placement='top'
          content={messageMap[voipStatus]}
        >
          <div className='w-100'>
            <VoipButton disabled />
          </div>
        </Tooltip>
      );
    }
  }

  return (
    <>
      <Popover
        placement={popoverPlacement}
        showArrow
        contentClassName='p-0'
        content={() => (
          <LoadingWrapper isLoading={!isSuccess}>
            <VoipPopoverProvider
              entity={entity}
              entityType={entityType}
              onUpdatePerson={onUpdatePerson}
              onUpdateOrganization={onUpdateOrganization}
            >
              <PopoverContent
                voipStatus={voipStatus}
                onClickActivate={onClickActivate}
                isActivatingVoip={voipToggleAccountMutation.isLoading}
              />
            </VoipPopoverProvider>
          </LoadingWrapper>
        )}
      >
        <div className='w-100'>
          <VoipButton
            onClick={
              () => tracker.trackActionButtonClicked({ user, action: 'Fazer ligacao', entityType })
            }
          />
        </div>
      </Popover>

      <VoipUpdateAccountModal
        show={showUpdateAccountModal}
        accountData={accountData}
        onHide={() => setShowUpdateAccountModal(false)}
        onUpdate={onUpdateAccount}
        activatingVoip={voipToggleAccountMutation.isLoading}
      />
    </>
  );
}

function getVoipStatus(voip) {
  if (!voip?.enabled) {
    return 'disabled';
  }
  const accountBalance = Number(voip?.balance || 0);
  const pricePerMinute = Number(voip?.pricePerMinute);
  const hasNotBalance = accountBalance < pricePerMinute;
  if (hasNotBalance) {
    return 'no-balance';
  }
  return 'available';
}

export default VoipPopover;
