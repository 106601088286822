import React from 'react';
import PropTypes from 'prop-types';
import Icon from '@/components/Icon';
import Authorization from '@/components/Authorization';
import Button from '@/components/Button';

import { canCreateDeal } from '@/policies';
import { dealCreatePath } from '@/routes';

import emptyImage from 'images/empty-list-deals.png';

DealTabEmpty.propTypes = {
  entityId: PropTypes.number.isRequired,
  entityType: PropTypes.string.isRequired
};

function DealTabEmpty({ entityId, entityType }) {
  const entityName = entityType === 'organization' ? 'empresa' : 'pessoa';

  return (
    <>
      <div
        className='d-flex flex-column text-center justify-content-center'
      >
        <img className='mx-auto mb-4' src={emptyImage} />

        <h4 className='mb-4'>
          Crie oportunidades de vendas
        </h4>

        <p className='mb-5 text-dark-gray'>
          Você ainda não criou nenhum negócio nesta {entityName}, que tal começar
          <br />
          adicionando um agora mesmo!
        </p>

        <div>
          <Authorization policy={canCreateDeal}>
            <Button href={dealCreatePath({ entityId, entityType })}>
              <Icon name='add' className='me-2' />
              Adicionar negócio
            </Button>
          </Authorization>
        </div>
      </div>
    </>
  );
}

export default DealTabEmpty;
