import { useMutation, useQueryClient } from 'react-query';
import { post } from '@/lib/fetch';
import { historicSuggestionKeys } from '@/api';


export async function createActivityIntents(params = {}) {
  const { data } = await post('/activity_intents', params);

  return data;
}

export function useCreateActivityIntents({ config = {} } = {}) {
  const queryClient = useQueryClient();
  const onSuccess = () => queryClient.invalidateQueries(historicSuggestionKeys.all);
  return useMutation(
    createActivityIntents,
    { ...config, onSuccess }
  );
}

