import React, { createContext, useContext, useState } from 'react';

const WhatsappPopoverContext = createContext(undefined);

export function useWhatsappPopoverContext() {
  const context = useContext(WhatsappPopoverContext);

  if (context === undefined) {
    throw new Error('useWhatsappPopoverContext must be used within WhatsappPopoverProvider');
  }

  return context;
}

export function WhatsappPopoverProvider({
  children,
  entity,
  entityType,
  onUpdatePerson,
  onUpdateOrganization,
  relatedEntity
}) {
  const [selectedEntity, setSelectedEntity] = useState(null);

  const value = {
    entity,
    entityType,
    onUpdatePerson,
    onUpdateOrganization,
    relatedEntity,
    selectedEntity,
    setSelectedEntity
  };

  return (
    <WhatsappPopoverContext.Provider value={value}>
      {children}
    </WhatsappPopoverContext.Provider>
  );
}
