import React from 'react';
import { Col, Row } from 'react-bootstrap';
import DetailsCard from '@/components/EntityModal/DetailsCard';
import VoipPopover from '@/components/Voip/CallPopover/Popover';
import DetailsEmail from '@/components/EntityModal/DetailsEmail';
import DetailsWhatsApp from '@/feature/whatsapp/DetailsWhatsApp';
import { useOnUpdateOrganization, useOnUpdate, usePerson } from '@/contexts/personModal';

function Actions() {
  const person = usePerson();
  const onUpdatePerson = useOnUpdate();
  const onUpdateOrganization = useOnUpdateOrganization();

  return (
    <DetailsCard title='Ações' className='mb-3'>
      <Row className='g-2'>
        <Col sm={6}>
          <DetailsEmail entityId={person.id} entityType='person' showText />
        </Col>
        <Col sm={6}>
          <VoipPopover
            entity={person}
            entityType='person'
            onUpdatePerson={onUpdatePerson}
            onUpdateOrganization={onUpdateOrganization}
          />
        </Col>
        <Col sm={6}>
          <DetailsWhatsApp
            entityType='person'
            entity={person}
            relatedEntity={person?.organization}
            onUpdateOrganization={onUpdateOrganization}
            onUpdatePerson={onUpdatePerson}
          />
        </Col>
      </Row>
    </DetailsCard>
  );
}

export default Actions;
