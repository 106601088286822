import React from 'react';
import { Col, Row } from 'react-bootstrap';

import Avatar from '@/components/Avatar';
import IconLabel from '@/components/IconLabel';
import Truncate from '@/components/Truncate';

function UserField({ labelName, avatarName, avataerUrl }) {
  return (
    <Row className='align-items-center mb-3'>
      <Col sm={6}>
        <IconLabel
          text={labelName}
          className='text-dark-gray'
        />
      </Col>
      <Col sm={6} className='d-flex align-items-center'>
        <Avatar
          className='me-1'
          name={avatarName}
          url={avataerUrl}
        />
        <Truncate className='max-width-4 text-medium'>
          {avatarName}
        </Truncate>
      </Col>
    </Row>
  );
}

export default UserField;


