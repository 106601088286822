import React from 'react';

import Tabs from '@/components/Tabs';
import NavLink from '@/components/NavLink';
import { peoplePath } from '@/routes';
import { useOrganization, useChangeTab, useActiveTab } from '@/contexts/organizationModal';

function OrganizationTabs() {
  const { id } = useOrganization();
  const activeTab = useActiveTab();
  const changeTab = useChangeTab();

  return (
    <Tabs className='border-bottom border-1'>
      <Tabs.Item>
        <NavLink active={activeTab === 'activities'} onClick={() => changeTab('activities')}>
          Ver histórico
        </NavLink>
      </Tabs.Item>

      <Tabs.Item>
        <NavLink active={activeTab === 'deals'} onClick={() => changeTab('deals')}>
          Ver negócios
        </NavLink>
      </Tabs.Item>

      <Tabs.Item>
        <NavLink href={peoplePath({ em: id })} target='_blank'>
          Ver pessoas
        </NavLink>
      </Tabs.Item>
    </Tabs>
  );
}

export default OrganizationTabs;
