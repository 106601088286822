import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

const propTypes = {
  /** Componentes ordenados conforme a sequência de passos. */
  children: PropTypes.node.isRequired,
  /** Callback chamado quando a sequência é resetada. */
  onReset: PropTypes.func,
  onChange: PropTypes.func
};

const defaultProps = {};

/**
 * Componente que recebe uma sequência de componentes filhos que representam
 * uma sequência de passos a serem exibidos.
 *
 * Caso algum dos passos seja nulo, ele será pulado.
 *
 */
function Stepper({ children: steps, onReset, onChange, Header, headerClassName, headerLabels }) {
  const count = steps.length;

  const [index, setIndex] = useState(0);

  useEffect(() => onChange?.(index), [index]);

  const advance = useCallback(() => {
    setIndex((prevIndex) => {
      const nextIndex = prevIndex + 1;

      return nextIndex < count ? nextIndex : prevIndex;
    });
  }, [setIndex, count]);

  const reset = useCallback(() => {
    setIndex(0);
    onReset();
  }, [setIndex, onReset]);

  const _return = useCallback(() => {
    setIndex((prevIndex) => {
      const previousIndex = prevIndex - 1;

      return prevIndex > 0 ? previousIndex : prevIndex;
    });
  }, [setIndex]);

  const step = steps[index] || <NullStep />;

  return (
    <>
      {
        Header && <Header
          headerClassName={headerClassName}
          headerLabels={headerLabels}
          currentStep={index}
          totalSteps={count}
        />
      }
      {React.cloneElement(step, {
        advanceStep: advance,
        resetStepper: reset,
        returnStep: _return,
        stepPosition: index + 1,
        totalSteps: count
      })}
    </>
  );
}

function NullStep(props) {
  const { advanceStep } = props;

  useEffect(() => {
    advanceStep();
  });

  return null;
}

Stepper.propTypes = propTypes;
Stepper.defaultProps = defaultProps;

export default Stepper;
