import React from 'react';
import Container from 'react-bootstrap/Container';

import Main from '@/components/Main';
import Stepper from '@/components/Stepper';
import Header from '@/feature/profile_form/Header';
import NeedStep from '@/feature/profile_form/NeedStep';
import CRMExperienceStep from '@/feature/profile_form/CRMExperienceStep';
import IndustryStep from '@/feature/profile_form/IndustryStep';
import FindUs from '@/feature/profile_form/FindUsStep';
import ABTest from '@/components/ABTest';
import { useAuth } from '@/lib/auth';

function ProfileForm() {
  const { user } = useAuth();
  const identifier = user.account.id.toString();

  return (
    <Main
      overflow
      fluid
      flex
      className='justify-content-lg-center align-items-center bg-profile container-full-height'
    >
      <Container>
        <ABTest name='profile_form_order' identifier={identifier}>
          <ABTest.Variant name='A'>
            <Stepper Header={Header}>
              <CRMExperienceStep />
              <IndustryStep />
              <NeedStep />
              <FindUs />
            </Stepper>
          </ABTest.Variant>

          <ABTest.Variant name='B'>
            <Stepper Header={Header}>
              <NeedStep />
              <CRMExperienceStep />
              <IndustryStep />
              <FindUs />
            </Stepper>
          </ABTest.Variant>
        </ABTest>
      </Container>
    </Main >
  );
}

export default ProfileForm;
