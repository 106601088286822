import React, { useMemo, useState, Fragment } from 'react';
import ContactItem from '@/feature/voip/ContactList/ContactItem';
import ContactForm from '@/feature/voip/ContactList/ContactForm';
import { useVoipPopoverContext } from '@/contexts/voipPopover';
import { applyMask, unmaskValue } from '@/masks';
import EmptyContact from '@/components/EntityModal/EmptyContact';

function Layout({ children }) {
  return (
    <div className='deal-voip-height overflow-hidden overflow-y-auto px-5 py-4'>
      {children}
    </div>
  );
}

function ContactList() {
  const [editingContact, setEditingContact] = useState(null);
  const { entity, entityType, onUpdatePerson, onUpdateOrganization } = useVoipPopoverContext();

  const contacts = useMemo(
    () => getContactList(entity, entityType),
    [entity, entityType]
  );

  const onSubmit = (values, { setSubmitting }) => {
    const updaterMethod = editingContact.entityType === 'organization'
      ? onUpdateOrganization
      : onUpdatePerson;

    updaterMethod(
      values,
      (result) => {
        if (result?.error) {
          setSubmitting(false);
        } else {
          setEditingContact(null);
        }
      },
      { id: editingContact.id }
    );
  };

  if (editingContact) {
    return (
      <Layout>
        <ContactForm
          contact={editingContact}
          onSubmit={onSubmit}
          onCancel={() => setEditingContact(null)}
        />
      </Layout>
    );
  }

  if (contacts.length === 0) {
    return (
      <Layout>
        <EmptyContact deal={entity} />
      </Layout>
    );
  }

  return (
    <Layout>
      <div className='text-darker-gray fw-bold mb-6'>
        <h4>Contatos</h4>
      </div>

      {contacts.map((contact, index) => (
        <Fragment key={contact.id}>
          {index > 0 && <hr className='mb-4 mt-2 text-light-gray opacity-50' />}

          <ContactItem
            contact={contact}
            onClickAddContact={() => setEditingContact(contact)}
          />
        </Fragment>
      ))}
    </Layout>
  );
}

function generateEntityContact(entity, entityType) {
  const { contact } = entity;

  const phoneNumbers = [
    { number: contact.whatsapp, type: 'whatsapp' },
    { number: contact.mobilePhone, type: 'mobile_phone' },
    { number: contact.workPhone, type: 'work_phone' }
  ].map((phone) => {
    const unmaskedValue = unmaskValue(
      phone.number,
      phone.type !== 'whatsapp' ? 'phone' : 'whatsapp'
    );
    let callableNumber = '';
    let displayNumber = '';

    if (unmaskedValue) {
      const phoneWithoutDdi = phone.number.replace('+55', '');
      callableNumber = phoneWithoutDdi;
      displayNumber = applyMask(phoneWithoutDdi, 'phone');
    }

    return {
      displayNumber,
      callableNumber,
      number: phone.number,
      type: phone.type
    };
  });

  return {
    entityType,
    id: entity.id,
    name: entity.name,
    phones: phoneNumbers
  };
}

function getOrganizationContacts(organization) {
  return [
    generateEntityContact(organization, 'organization'),
    ...organization.people.map((person) => generateEntityContact(person, 'person'))
  ];
}

function getPersonContacts(person) {
  return [
    generateEntityContact(person, 'person'),
    person.organization && generateEntityContact(person.organization, 'organization')
  ].filter(Boolean);
}

function getContactList(entity, entityType) {
  if (entityType === 'organization') {
    return getOrganizationContacts(entity);
  } else if (entityType === 'person') {
    return getPersonContacts(entity);
  } else if (entityType === 'deal' && Boolean(entity.organization)) {
    return getOrganizationContacts(entity.organization);
  } else if (entityType === 'deal' && Boolean(entity.person)) {
    return getPersonContacts(entity.person);
  } else {
    return [];
  }
}

export default ContactList;
