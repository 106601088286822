import React from 'react';
import { Col, Row } from 'react-bootstrap';

import IconLabel from '@/components/IconLabel';
import { EditInPlace } from '@/components/Inputs';
import CustomFieldInput from '@/components/CustomFields/CustomFieldInput';
import Link from '@/components/Link';
import Tooltip from '@/components/Tooltip';

function TrucatedLinkField({ link, entitySelectedName }) {
  const linkEmpty = !link;

  const truncateTextStyle = {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    width: '140px'
  };

  if (linkEmpty) {
    return (
      <Tooltip content={entitySelectedName} placement='top'>
        <div style={truncateTextStyle}>
          {entitySelectedName}
        </div>
      </Tooltip>
    );
  } else {
    return (
      <Link href={link}>
        <Tooltip content={entitySelectedName} placement='top'>
          <div style={truncateTextStyle}>
            {entitySelectedName}
          </div>
        </Tooltip>
      </Link>
    );
  }
}

function EditSelectField({
  labelName,
  selectName,
  onEditEntity,
  options,
  entity,
  entityName,
  entityId,
  link
}) {
  const isEmptyEntity = !entity;

  return (
    <Row className='align-items-center mb-3'>
      <Col sm={6}>
        <IconLabel
          text={labelName}
          className='me-3 text-dark-gray'
        />
      </Col>
      <Col sm={6}>
        <EditInPlace
          name={selectName}
          as={CustomFieldInput}
          value={entityId}
          field={{
            identifier: entityId,
            value: entityName,
            type: 'select'
          }}
          options={options}
          isSelect
          isClearable
          onConfirm={onEditEntity}
        >
          {
            isEmptyEntity
              ? <span className='text-medium-gray'>Adicionar</span>
              : <TrucatedLinkField link={link} entitySelectedName={entityName}/>
          }
        </EditInPlace>
      </Col>
    </Row>
  );
}

export default EditSelectField;
