import { useQuery, useMutation, useQueryClient } from 'react-query';
import { get, patch } from '@/lib/fetch';

export const historicSuggestionKeys = {
  all: ['historic-suggestions'],
  lists: () => [...historicSuggestionKeys.all, 'list'],
  list: (params) => ([...historicSuggestionKeys.lists(), params])
};

export async function getHistoricSuggestions(params = {}) {
  const { data } = await get('/historic_suggestions', params);

  return data;
}

export async function updateHistoricSuggestion({ id, params = {} }) {
  const { data } = await patch(`/historic_suggestions/${id}`, params);

  return data;
}

export async function updateHistoricSuggestionsFromActivity({ params = {} }) {
  const { data } = await patch('/historic_suggestions', params);

  return data;
}

export function useHistoricSuggestions({ params = {}, config = {} } = {}) {
  return useQuery({
    ...config,
    queryKey: historicSuggestionKeys.list(params),
    queryFn: () => getHistoricSuggestions(params)
  });
}

export function useUpdateHistoricSuggestion({ params = {}, config = {} } = {}) {
  const queryClient = useQueryClient();

  const onMutate = ({ id: historicSuggestionId }) => {
    const listKey = historicSuggestionKeys.list(params);
    const { data: historicSuggestions } = queryClient.getQueryData(listKey);
    const newData = historicSuggestions.filter(({ id }) => id !== historicSuggestionId);
    queryClient.setQueryData(listKey, { data: newData });
  };

  return useMutation(
    updateHistoricSuggestion,
    { onMutate, ...config }
  );
}

export function useUpdateHistoricSuggestionsFromActivity({ config = {} } = {}) {
  return useMutation(
    updateHistoricSuggestionsFromActivity,
    { ...config }
  );
}
