import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';
import { applyMask } from '@/masks';
import { getSocialMedias } from '@/utils';
import { personEditPath, personPath } from '@/routes';
import DetailsCard from '@/components/EntityModal/DetailsCard';
import IconLabel from '@/components/IconLabel';
import EntityIcon from '@/components/EntityIcon';
import Icon from '@/components/Icon';
import Popover from '@/components/Popover';
import ExternalLink from '@/components/ExternalLink';
import Truncate from '@/components/Truncate';
import DetailsCustomField from '@/components/EntityModal/DetailsCustomField';
import { WhatsAppInput, EditInPlace, TextInput, MaskedInput } from '@/components/Inputs';
import PersonFormPopover from '@/components/EntityModal/PersonFormPopover';
import EntityAddressPopover from '@/components/EntityModal/EntityAddressPopover';
import ContactPhoneOptions from '@/components/WhatsApp/ContactPhoneOptions';

DetailsContactEntity.defaultProps = {
  dropboxEmail: null,
  entity: {},
  entityType: null,
  entityTypeModal: '',
  entityCustomFields: [],
  loading: false,
  onEditEntity: () => {},
  showTitle: true
};

DetailsContactEntity.propTypes = {
  dropboxEmail: PropTypes.string,
  entity: PropTypes.object,
  entityType: PropTypes.string,
  entityTypeModal: PropTypes.string,
  entityCustomFields: PropTypes.array,
  loading: PropTypes.bool,
  onEditEntity: PropTypes.func,
  canEditPolicy: PropTypes.func,
  showTitle: PropTypes.bool
};

const renderValue = (value, defaultText = 'Indefinido') => (
  value || <span className='text-medium-gray'>{defaultText}</span>
);

const renderCustomFields = (customFields, entity, entityType, onEditEntity) => {
  const customFieldsObj = { basic_data: [], contact: [], address: [] };

  customFields.forEach(({ section, identifier, customFieldOptions, type, name }) => (
    customFieldsObj[section].push(
      <DetailsCustomField
        onEdit={(params, done) => onEditEntity(params, done, { ...entity, entityType })}
        key={identifier}
        identifier={identifier}
        customFieldOptions={customFieldOptions}
        type={type}
        name={name}
        rawValue={entity?.customFields[identifier]}
      />
    )
  ));

  return customFieldsObj;
};

function MainContactFields({
  contact = {},
  entityData = {},
  dropboxEmail = '',
  onEditContact,
  entityTypeModal,
  deal,
  entity,
  voip
}) {
  const [hidePopover, setHidePopover] = useState(false);

  const handleHidePopover = (value) => {
    setHidePopover(value);
  };

  const onConfirm = (params, done) => onEditContact(params, done, entityData);

  return (
    <>
      <Row className='align-items-center mb-3'>
        <Col sm={6}>
          <IconLabel
            text='Email'
            icon={{ name: 'email' }}
            className='me-3 text-dark-gray'
          />
        </Col>
        <Col sm={6}>
          <EditInPlace
            name='contact.email'
            as={TextInput}
            value={contact?.email}
            onConfirm={onConfirm}
          >
            {
              renderValue(
                contact?.email && (
                  <ExternalLink target='_blank' href={`mailto:${contact.email}?cc=${dropboxEmail}`}>
                    {contact.email}
                  </ExternalLink>
                )
              )
            }
          </EditInPlace>
        </Col>
      </Row>

      <Row className='align-items-center mb-3'>
        <Col sm={6}>
          <IconLabel
            text='Celular'
            icon={{ name: 'call' }}
            className='me-3 text-dark-gray'
          />
        </Col>
        <Col sm={6}>
          <Popover
            showArrow
            flip
            placement='right'
            hide={!contact?.mobilePhone || hidePopover}
            content={() => (
              <ContactPhoneOptions
                entityTypeModal={entityTypeModal}
                phone={contact?.mobilePhone}
                entityData={entityData}
                entity={entity}
                deal={deal}
                voip={voip}
              />
            )}>
            <div>
              <EditInPlace
                name='contact.mobile_phone'
                as={MaskedInput}
                maskType='phone'
                placeholder='(00) 0000-0000'
                value={contact?.mobilePhone}
                onConfirm={onConfirm}
                hidePopover={handleHidePopover}
              >
                {
                  renderValue(
                    contact?.mobilePhone && (
                      <span className='text-nowrap text-primary'>
                        {contact?.mobilePhone}
                      </span>
                    )
                  )
                }
              </EditInPlace>
            </div>
          </Popover>
        </Col>
      </Row>

      <Row className='align-items-center mb-3'>
        <Col sm={6}>
          <IconLabel
            text='Whatsapp'
            icon={{ name: 'whatsapp' }}
            className='me-3 text-dark-gray'
          />
        </Col>
        <Col sm={6}>
          <Popover
            showArrow
            flip
            placement='right'
            hide={!contact?.whatsapp || hidePopover}
            content={() => (
              <ContactPhoneOptions
                entityTypeModal={entityTypeModal}
                phone={contact?.whatsapp}
                entityData={entityData}
                entity={entity}
                deal={deal}
                voip={voip}
              />
            )}>
            <div>
              <EditInPlace
                name='contact.whatsapp'
                as={WhatsAppInput}
                placeholder='+00 00 00000-0000'
                value={contact?.whatsapp}
                onConfirm={onConfirm}
                hidePopover={handleHidePopover}
              >
                {
                  renderValue(
                    contact?.whatsapp && (
                      <span className='text-nowrap text-primary'>
                        {applyMask(contact?.whatsapp, 'whatsapp')}
                      </span>
                    )
                  )
                }
              </EditInPlace>
            </div>
          </Popover>
        </Col>
      </Row>

      <Row className='align-items-center mb-3'>
        <Col sm={6}>
          <IconLabel
            text='Telefone'
            icon={{ name: 'call' }}
            className='me-3 text-dark-gray'
          />
        </Col>
        <Col sm={6}>
          <Popover
            showArrow
            flip
            placement='right'
            hide={!contact?.workPhone || hidePopover}
            content={() => (
              <ContactPhoneOptions
                entityTypeModal={entityTypeModal}
                phone={contact?.workPhone}
                entityData={entityData}
                entity={entity}
                deal={deal}
                voip={voip}
              />
            )}>
            <div>
              <EditInPlace
                name='contact.work_phone'
                as={MaskedInput}
                maskType='phone'
                placeholder='(00) 0000-0000'
                value={contact?.workPhone}
                onConfirm={onConfirm}
                hidePopover={handleHidePopover}
              >
                {
                  renderValue(
                    contact?.workPhone && (
                      <span className='text-nowrap text-primary'>
                        {contact?.workPhone}
                      </span>
                    )
                  )
                }
              </EditInPlace>
            </div>
          </Popover>
        </Col>
      </Row>
    </>
  );
}

function ListSocialMedias({ contact, entityData, onEditContact }) {
  const socialMedias = getSocialMedias(contact);

  if (socialMedias.length) {
    return socialMedias.map(({ url, text, name }) => (
      <EditInPlace
        key={name}
        name={`contact.${name}`}
        as={TextInput}
        value={text}
        className='mb-4'
        onConfirm={(params, done) => onEditContact(params, done, entityData)}
      >
        <Truncate
          as={ExternalLink}
          href={url}
          target='_blank'
          rel='noreferrer'
          className='d-block mb-2'
        >
          {text}
        </Truncate>
      </EditInPlace>
    ));
  }

  return <div className='text-medium-gray'>Nenhuma rede social</div>;
}

function DetailsContactEntity({
  dropboxEmail,
  entity,
  entityType,
  entityTypeModal,
  entityCustomFields,
  onEditEntity,
  canEditPolicy,
  onEditPerson,
  showTitle,
  deal,
  voip
}) {
  const entityContact = entity?.contact || {};
  const entityAddress = entity?.address || {};
  const isOrganization = entityType === 'organization';
  const isDealModal = entityTypeModal === 'deal';

  const customFields = renderCustomFields(entityCustomFields, entity, entityType, onEditEntity);

  function renderPersonSection(person) {
    const personData = { ...person, entityType: 'person' };
    const onEdit = onEditPerson || onEditEntity;
    const onConfirm = (params, done) => onEdit(params, done, personData);
    const editLink = isDealModal ? personEditPath(person.id) : personPath(person.id);

    return (
      <div key={person.id}>
        <Row className='align-items-center mb-3'>
          <Col sm={12}>
            <ExternalLink
              className='p-0 fw-bold d-flex'
              href={editLink}
            >
              <span>{person.name}</span>
              <Icon className='ms-1' size='sm' name='external-link' />
            </ExternalLink>
          </Col>
        </Row>

        <Row className='align-items-center mb-3'>
          <Col sm={6}>
            <IconLabel
              text='Cargo'
              className='me-3 text-dark-gray'
            />
          </Col>
          <Col sm={6}>
            <EditInPlace
              name='job_title'
              as={TextInput}
              value={person?.jobTitle}
              onConfirm={onConfirm}
            >
              <Truncate>
                {renderValue(person?.jobTitle)}
              </Truncate>
            </EditInPlace>
          </Col>
        </Row>

        <MainContactFields
          entityTypeModal={entityTypeModal}
          contact={person?.contact}
          entity={entity}
          entityData={personData}
          dropboxEmail={dropboxEmail}
          onEditContact={onConfirm}
          deal={deal}
          voip={voip}
        />
        <hr />
      </div>
    );
  }

  const detailsCardTitle = entityTypeModal === 'deal'
    ? 'Dados do contato'
    : 'Informações para contato';

  return (
    <DetailsCard title={detailsCardTitle} className='mt-3'>
      {showTitle && (
        <Row className='align-items-center mb-3'>
          <Col sm={1}>
            <EntityIcon type={entityType} />
          </Col>
          <Col sm={11}>
            <EditInPlace
              as={TextInput}
              name='name'
              value={entity?.name}
              onConfirm={(params, done) => onEditEntity(params, done, { ...entity, entityType })}
              policy={canEditPolicy}
            >
              <Truncate className='text-medium fw-bold ms-2'>
                {entity?.name}
              </Truncate>
            </EditInPlace>
          </Col>
        </Row>
      )}
      <MainContactFields
        entity={entity}
        entityTypeModal={entityTypeModal}
        contact={entityContact}
        entityData={{ ...entity, entityType }}
        dropboxEmail={dropboxEmail}
        onEditContact={onEditEntity}
        deal={deal}
        voip={voip}
      />

      <Row className='align-items-center mb-3'>
        <Col sm={6}>
          <IconLabel
            text='Endereço'
            icon={{ name: 'visit' }}
            className='me-3 text-dark-gray'
          />
        </Col>
      </Row>

      <Row className='align-items-center mb-3'>
        <Col sm={12}>
          <EntityAddressPopover
            initialValues={entityAddress}
            onSubmit={(params, done) => onEditEntity(params, done, { ...entity, entityType })}
          />
        </Col>
      </Row>

      {entityTypeModal === 'deal' && customFields.basic_data}

      {customFields.contact}

      {customFields.address}

      {entity?.organization && (
        <Row className='align-items-center mb-3'>
          <Col sm={6}>
            <IconLabel
              text='Empresa'
              icon={{ name: 'organization' }}
              className='me-3 text-dark-gray'
            />
          </Col>

          <Col sm={6}>
            <Truncate>
              {renderValue(entity.organization?.name)}
            </Truncate>
          </Col>
        </Row>
      )}

      {(entityTypeModal === 'deal' && entityType === 'person') && (
        <Row className='align-items-center mb-3'>
          <Col sm={6}>
            <IconLabel
              text='Cargo'
              icon={{ name: 'organization' }}
              className='me-3 text-dark-gray'
            />
          </Col>

          <Col sm={6}>
            <EditInPlace
              name='job_title'
              as={TextInput}
              value={entity?.jobTitle}
              onConfirm={(params, done) => onEditEntity(params, done, { ...entity, entityType })}
            >
              <Truncate>
                {renderValue(entity?.jobTitle)}
              </Truncate>
            </EditInPlace>
          </Col>
        </Row>
      )}

      {isOrganization && (
        <>
          <Row className='align-items-center mb-3'>
            <Col sm={12}>
              <IconLabel
                text='Pessoas da empresa'
                icon={{ name: 'meeting' }}
                className='text-nowrap me-3 width-5 text-dark-gray'
              />
            </Col>
          </Row>
          {
            entity.people?.length
              ? entity.people.map(renderPersonSection)
              : (
                <Row className='align-items-center mb-3'>
                  <Col sm={12}>
                    <span className='text-medium-gray'>
                      Nenhuma pessoa cadastrada para essa empresa
                    </span>
                  </Col>
                </Row>
              )
          }
          <Row className='mt-4 mb-2'>
            <Col sm={12}>
              <PersonFormPopover organizationId={entity.id} />
            </Col>
          </Row>
        </>
      )}

      <Row className='align-items-center mb-3'>
        <Col sm={12}>
          <IconLabel
            text='Redes sociais'
            className='d-block me-3 py-2 text-dark-gray'
          />
        </Col>
        <Col sm={12}>
          <ListSocialMedias
            contact={entityContact}
            entityData={{ ...entity, entityType }}
            onEditContact={onEditEntity}
          />
        </Col>
      </Row>
    </DetailsCard>
  );
}

export default DetailsContactEntity;
