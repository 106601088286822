import React from 'react';
import PropTypes from 'prop-types';
import Offcanvas from '@/components/Offcanvas';

IframeAgendorChat.propTypes = {
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  iframeSrc: PropTypes.string.isRequired
};

function IframeAgendorChat({ show, onClose, iframeSrc }) {
  return (
    <Offcanvas
      show={show}
      onHide={onClose}
      placement='end'
      className='iframe-modal z-index-1090'
      style={{ boxShadow: '#CCC 0px 0px 8px 0px' }}
    >
      <Offcanvas.Body className='p-0'>
        {iframeSrc
          ? (
            <iframe
              src={iframeSrc}
              className='w-100 h-100 border-0 shadow-sm'
              title='Agendor Chat'
            />
          )
          : (
            <p>Carregando...</p>
          )}
      </Offcanvas.Body>
    </Offcanvas>
  );
}

export default IframeAgendorChat;
