import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import { Checkbox, TextArea, WhatsAppInput } from '@/components/Inputs';
import FooterButtons from '@/feature/profile_form/FooterButtons';
import Badge from '@/components/Badge';
import HookForm from '@/components/HookForm';
import HookFormField from '@/components/HookFormField';
import { useAuth } from '@/lib/auth';
import { useUpdateProfile } from '@/api';
import { useTracking } from '@/lib/tracking';
import { useNavigate } from 'react-router-dom';
import { homeUrl } from '@/routes';

const propTypes = {
  returnStep: PropTypes.func,
  stepPosition: PropTypes.number,
  totalSteps: PropTypes.number
};

const defaultProps = {
  returnStep: () => {},
  stepPosition: 0,
  totalSteps: 0
};

/* eslint-disable no-magic-numbers */
const schema = Yup.object().shape({
  account: Yup.object().shape({
    find_us: Yup.string()
      .required('Por favor, informe como conheceu o Agendor.')
  }),
  contact: Yup.object().shape({
    whatsapp: Yup.string()
      .min(13, 'Número inválido.')
      .max(16, 'Número inválido.')
      .required('Por favor, informe o seu número de WhatsApp.')
  })
});
/* eslint-enable no-magic-numbers */

function FindUsStep({ returnStep, stepPosition, totalSteps }) {
  const { user } = useAuth();
  const tracker = useTracking();

  useEffect(() => {
    tracker.trackProfileStepViewed({ user, step: stepPosition });
  }, []);

  const navigate = useNavigate();
  const { mutate, isLoading } = useUpdateProfile();
  const handleNext = (values) => {
    mutate({ params: values }, {
      onSuccess: ({ data }) => {
        /**
         * Aqui é usada a resposta da requisição (`data`) ao invés do objeto de
         * usuário (`user`) porque no momento que essa função é chamada, o `user`
         * ainda não está atualizado com os dados que foram preenchidos no formulário.
         */
        tracker.trackProfileUpdated({ user: data });

        navigate(homeUrl());
      }
    });
  };

  const defaultValues = useMemo(() => ({
    account: {
      find_us: user.account?.findUs ?? ''
    },
    contact: {
      whatsapp: user.whatsapp ?? ''
    },
    accept_whatsapp: false
  }), [user]);

  return (
    <HookForm
      onSubmit={handleNext}
      name='profile-form'
      validationSchema={schema}
      defaultValues={defaultValues}
      className='mt-4'
      mode='onSubmit'
    >
      {() => (
        <Row>
          <Col lg={{ span: 6, offset: 3 }}>
            <Badge variant='light' className='text-small mb-3'>
              Passo {stepPosition} de {totalSteps}
            </Badge>

            <HookFormField
              as={TextArea}
              name='account.find_us'
              className='mb-6'
              label={<h1 className='mb-3'>Conte como você ouviu falar de nós pela 1ª vez.</h1>}
            />

            <HookFormField
              as={WhatsAppInput}
              name='contact.whatsapp'
              autoComplete='off'
              placeholder='+00 00 00000-0000'
              label={<h1 className='mb-3'>Qual o seu celular/WhatsApp?</h1>}
            />

            <HookFormField
              as={Checkbox}
              name='accept_whatsapp'
              className='rounded-profile-form-input d-flex align-items-center'
              label={<div className='m-2'>Quero receber dicas pelo WhatsApp</div>}
            />

            <FooterButtons
              handlePrevious={returnStep}
              buttonText='Acessar minha conta'
              disabled={isLoading}
            />
          </Col>
        </Row>
      )}
    </HookForm>
  );
}

FindUsStep.propTypes = propTypes;
FindUsStep.defaultProps = defaultProps;

export default FindUsStep;
