import React from 'react';
import { Col } from 'react-bootstrap';

import Sidebar from '@/components/EntityModal/Person/Sidebar/Sidebar';
import Activities from '@/components/EntityModal/Person/Activities/Activities';
import DealTab from '@/components/EntityModal/DealTab';
import { useActiveTab } from '@/contexts/personModal';
import { useScreenSize } from '@/hooks';

function ModalContent({ personId }) {
  const activeTab = useActiveTab();
  const { isMD } = useScreenSize();

  if (activeTab === 'deals') {
    return (
      <>
        <Col sm='12'>
          <DealTab entityId={personId} entityType='person' />
        </Col>
      </>
    );
  }

  return (
    <>
      <Col lg='8' sm='12'>
        <Activities />
      </Col>
      { isMD
        ? null
        : (
          <Col lg='4'>
            <div className='me-3'>
              <Sidebar/>
            </div>
          </Col>
        ) }
    </>
  );
}

export default ModalContent;
