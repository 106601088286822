import React, { useState } from 'react';
import { useAlert } from 'react-alert';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { QRCodeSVG } from 'qrcode.react';

import { applyMask } from '@/masks';
import { useUpdateSubscription, useTransaction } from '@/api';
import {
  parseDate, toDateTimeString, toDayOfWeek,
  toTextualDateString, toTextualMonthDateString
} from '@/date';
import { pluralize } from '@/utils';
import { toCurrencyString } from '@/number';
import { useUpdateSubscriptionContext } from '@/contexts';
import Button from '@/components/Button';
import Card from '@/components/Card';
import ExternalLink from '@/components/ExternalLink';
import Icon from '@/components/Icon';
import ManagePlanFooter from '@/components/Billing/ManagePlanFooter';
import PaymentMethod from '@/components/Billing/PaymentMethod';
import PaymentMethodForm from '@/components/Billing/PaymentMethodForm';

import checkImage from 'images/check.svg';

const PAYMENT_DATA = {
  paymentMethod: { id: null }
};

const REFETCH_INTERVAL = 5000;

const periodicityText = {
  monthly: 'mensal',
  quarterly: 'trimestral',
  half_yearly: 'semestral',
  yearly: 'anual'
};

const PAYMENT_TYPE_IDS = {
  credit_card: 13,
  bank_slip: 14,
  pix: 15
};

const BANK_SLIP_MINIMUM_VALUE = 5;

function ConfirmUpdatePlanStep() {
  const {
    updatedSubscriptionPreview,
    currentPlanData,
    closePlanConfig,
    prevStep
  } = useUpdateSubscriptionContext();

  const currentPaymentInfo = currentPlanData.paymentInfo;
  const isCreditCardSubscription = currentPaymentInfo.method === 'credit_card';
  const [paymentType, setPaymentType] = useState('credit_card');
  const [paymentCompletionData, setPaymentCompletionData] = useState(null);

  const [isLoading, setIsLoading] = useState(false);
  const [transactionId, setTransactionId] = useState(null);
  const [pollingEnabled, setPollingEnabled] = useState(false);
  const [showPaymentForm, setShowPaymentForm] = useState(!isCreditCardSubscription);
  const [paymentData, setPaymentData] = useState(() => {
    if (currentPlanData.paymentInfo.method !== 'credit_card') {
      return PAYMENT_DATA;
    }
    return {
      paymentMethod: {
        id: currentPlanData.paymentInfo.paymentMethodId
      }
    };
  });

  const updateSubscriptionMutation = useUpdateSubscription();
  const alert = useAlert();

  const isUpgrade = 'proratedInfo' in updatedSubscriptionPreview;
  const isPix = paymentType === 'pix';
  const isBankSlip = paymentType === 'bank_slip';

  const planFullName =
    `Plano ${updatedSubscriptionPreview.name} ${updatedSubscriptionPreview.maxUsersCount}`;

  const pluralizedRemainingDaysText = pluralize('dia', updatedSubscriptionPreview.remainingDays);

  const totalValue = isUpgrade
    ? updatedSubscriptionPreview.proratedInfo.value
    : updatedSubscriptionPreview.finalValue;
  const formattedTotalValue = toCurrencyString(totalValue);

  const paymentDate = isUpgrade
    ? 'Hoje'
    : toTextualMonthDateString(parseDate(currentPlanData.expirationDate));

  const isAdvanceStepEnable =
    isPix ||
    isBankSlip ||
    (paymentData.paymentMethod.id && isUpgrade) ||
    (paymentData.stripe && paymentData?.event?.complete) ||
    !isUpgrade;

  const openCreditCardForm = () => {
    setPaymentData(PAYMENT_DATA);

    setShowPaymentForm(true);
  };

  const handleCreditCardPaymentError = () => {
    alert.show(
      <div>
        <h6>Infelizmente, não conseguimos processar seu pagamento</h6>
        <div>Por favor, tente novamente ou adicione um novo cartão.</div>
      </div>,
      { variant: 'danger' }
    );
  };

  const handlePaymentError = () => {
    alert.show(
      <div>
        <h6>Infelizmente, não conseguimos processar seu pagamento</h6>
        <div>Aguarde alguns minutos e tente novamente.</div>
        <div>Se o erro persistir, entre em contato com o nosso suporte.</div>
      </div>,
      { variant: 'danger' }
    );
  };

  const getNextButtonText = () => {
    if (isBankSlip) {
      return 'Gerar Boleto';
    } else if (isPix) {
      return 'Pagar com Pix';
    } else {
      return 'Finalizar';
    }
  };

  const getConfirmParams = () => ({
    product_code: updatedSubscriptionPreview.productCode,
    quantity: updatedSubscriptionPreview.maxUsersCount,
    period: currentPlanData.periodicity
  });

  const getPixConfirmParams = () => ({
    ...getConfirmParams(),
    payment_type_id: PAYMENT_TYPE_IDS.pix
  });

  const getBankSlipConfirmParams = () => ({
    ...getConfirmParams(),
    payment_type_id: PAYMENT_TYPE_IDS.bank_slip
  });

  const getCreditCardConfirmParams = async () => {
    let paymentMethodId = paymentData.paymentMethod.id;

    if (paymentData.stripe) {
      const { error, paymentMethod } = await paymentData.stripe.createPaymentMethod(
        {
          elements: paymentData.elements,
          params: {
            billing_details: {
              address: {
                country: 'BR'
              }
            }
          }
        }
      );

      if (error) {
        console.error(error);
        setIsLoading(false);

        return handleCreditCardPaymentError();
      }

      paymentMethodId = paymentMethod.id;
    }

    return {
      ...getConfirmParams(),
      payment_method_id: paymentMethodId,
      payment_type_id: PAYMENT_TYPE_IDS.credit_card
    };
  };

  const confirmUpgrade = (params, onError) => {
    updateSubscriptionMutation.mutate(params, {
      onSuccess: ({ data }) => {
        setPollingEnabled(true);
        setTransactionId(data.id);
      },
      onError
    });

    setIsLoading(false);
  };

  const onConfirmUpgrade = async () => {
    setIsLoading(true);

    let confirmParams = null;
    let onError = null;

    if (isPix) {
      confirmParams = getPixConfirmParams();
      onError = handlePaymentError;
    } else if (isBankSlip) {
      confirmParams = getBankSlipConfirmParams();
      onError = handlePaymentError;
    } else {
      confirmParams = await getCreditCardConfirmParams();
      onError = handleCreditCardPaymentError;
    }

    confirmUpgrade(confirmParams, onError);
  };

  const onConfirmDowngrade = () => {
    setIsLoading(true);

    const params = {
      product_code: updatedSubscriptionPreview.productCode,
      quantity: updatedSubscriptionPreview.maxUsersCount,
      period: currentPlanData.periodicity
    };

    updateSubscriptionMutation.mutate(params, {
      onSuccess: () => {
        alert.show(
          'Plano alterado com sucesso!',
          { variant: 'success' }
        );

        closePlanConfig();
      },
      onError: () => {
        alert.show(
          'Não foi possível alterar o plano.',
          { variant: 'danger' }
        );
      }
    });

    setIsLoading(false);
  };

  const onConfirm = () => (isUpgrade ? onConfirmUpgrade() : onConfirmDowngrade());

  useTransaction({
    billingTransactionId: transactionId,
    config: {
      refetchInterval: REFETCH_INTERVAL,
      cacheTime: 0,
      enabled: Boolean(pollingEnabled && transactionId),
      onSuccess: ({ data }) => {
        switch (data.statusName) {
          case 'Aguardando Pagto':
            return;
          case 'Em análise':
            if (isPix) {
              const details = data.transactionDetails;

              setPaymentCompletionData({
                qrCode: details.qr_code,
                expiresAt: details.expires_at
              });
            } else if (isBankSlip) {
              const details = data.transactionDetails;

              setPaymentCompletionData({
                pdf: details.pdf,
                number: details.number,
                expiresAt: details.expires_at
              });
            }

            return;
          case 'Aprovado':
          case 'Completo':
            setPollingEnabled(false);

            alert.show(
              'Plano alterado com sucesso!',
              { variant: 'success' }
            );

            closePlanConfig();

            break;
          default:
            setPollingEnabled(false);

            if (isPix) {
              handlePaymentError();
            } else if (isBankSlip) {
              handlePaymentError();
            } else {
              handleCreditCardPaymentError();
            }
        }
      }
    }
  });

  const renderPaymentSection = () => {
    if (!showPaymentForm) {
      return (
        <CurrentCreditCard
          lastFourDigits={currentPaymentInfo?.lastFourDigits}
          onClickEdit={openCreditCardForm}
        />
      );
    }

    const disabledBankSlipOption =
      updatedSubscriptionPreview.proratedInfo.value < BANK_SLIP_MINIMUM_VALUE;

    return (
      <PaymentMethodForm
        paymentType={paymentType}
        setPaymentType={setPaymentType}
        setPaymentData={setPaymentData}
        isCreditCardSubscription={isCreditCardSubscription}
        disabledBankSlip={disabledBankSlipOption}
      />
    );
  };

  const renderCompletionSection = () => {
    const CompletionComponent = isPix ? PixCompletion : BankSlipCompletion;

    return (
      <CompletionComponent
        data={paymentCompletionData}
        formattedTotalValue={formattedTotalValue}
      />
    );
  };

  return (
    <>
      <Card className='px-3 py-2 bg-light bg-opacity-25 border-0 mb-4'>
        <h5 className='mb-0'>{planFullName}</h5>

        <hr className='my-3 text-dark-gray' />

        <h5>Pró-rata</h5>

        <RowContent
          title='Data de pagamento'
          content={paymentDate}
        />

        {isUpgrade && (
          <RowContent
            title='Período'
            content={`${updatedSubscriptionPreview.remainingDays} ${pluralizedRemainingDaysText}`}
          />
        )}

        <RowContent
          title={`Valor ${isUpgrade ? 'total' : periodicityText[currentPlanData.periodicity]}`}
          content={isUpgrade ? <h3>{formattedTotalValue}</h3> : formattedTotalValue}
        />
      </Card>

      {isUpgrade && (
        paymentCompletionData ? renderCompletionSection() : renderPaymentSection()
      )}

      {paymentCompletionData
        ? <ManagePlanFooter.Close onClick={closePlanConfig} />
        : (
          <ManagePlanFooter
            buttonNextText={getNextButtonText()}
            loadingTextButton='Finalizando...'
            onClickPrev={prevStep}
            onClickNext={onConfirm}
            disabled={!isAdvanceStepEnable}
            isLoading={isLoading || updateSubscriptionMutation.isLoading || pollingEnabled}
          />
        )
      }
    </>
  );
}

function CurrentCreditCard({ lastFourDigits, onClickEdit }) {
  return (
    <Card className='p-3 border-light'>
      <div className='d-flex align-items-center justify-content-between py-6 fw-bold'>
        <span className='text-dark-gray'>Forma de pagamento</span>
        <div className='d-flex'>
          <PaymentMethod
            method='credit_card'
            lastFourDigits={lastFourDigits}
          />
          <Button
            size='sm'
            variant='outline-dark-gray'
            className='p-1 ms-3'
            onClick={onClickEdit}
          >
            <Icon size='sm' name='edit-field' />
          </Button>
        </div>
      </div>
    </Card>
  );
}

function PixCompletion({ data }) {
  return (
    <Card className='p-4 border-light'>
      <div className='d-flex flex-column align-items-center mb-2'>
        <h2 className='mb-4'>Código do Pix gerado</h2>

        <div className='mb-4 text-body'>
          <QRCodeSVG
            fgColor='currentColor'
            value={data.qrCode}
            size='172'
          />
        </div>

        <CopyToClipboard text={data.qrCode}>
          <Button
            variant='lighter-blue'
            className='mb-2 rounded-sm text-primary text-small'
          >
            <Icon name='copy' size='sm' className='me-2' />

            Copiar código Pix
          </Button>
        </CopyToClipboard>

        <span className='text-smaller text-dark-gray'>
          Vencimento: {toDateTimeString(parseDate(data.expiresAt))}
        </span>
      </div>

      <ol type='1' className='ps-4 text-smaller text-dark-gray'>
        <li>
          Acesse o app do seu banco ou internet banking e escolha &quot;Pagar com Pix&quot;.
        </li>

        <li>
          Copie e cole o código acima ou escaneie o QR code.
        </li>

        <li>
          Confira as informações.
        </li>

        <li>
          Finalize o pagamento.
        </li>
      </ol>
    </Card>
  );
}

function BankSlipCompletion({ data, formattedTotalValue }) {
  const expirationDate = parseDate(data.expiresAt);

  return (
    <Card className='p-4 border-light align-items-center'>
      <img className='width-3 mb-2' src={checkImage} />

      <h4 className='mb-2'>Boleto foi gerado</h4>

      <div className='text-dark-gray'>
        Quando o pagamento for processado, seu novo plano será liberado.
      </div>

      <hr className='w-100 mt-5 mb-6 text-dark-gray' />

      <div className='d-flex flex-column align-items-center text-small fw-bold text-dark-gray mb-4'>
        <div className='mb-1'>Cód. do boleto</div>

        <h4 className='mb-1 text-body'>{applyMask(data.number, 'bank_slip')}</h4>

        <div>Vencimento: {toDayOfWeek(expirationDate)}, {toTextualDateString(expirationDate)}</div>

        <div>Valor: {formattedTotalValue}</div>
      </div>

      <div className='d-flex align-items-center mb-4'>
        <CopyToClipboard text={data.number}>
          <Button
            variant='outline-dark-gray'
            className='me-2'
          >
            <Icon name='copy' size='sm' />

            <span className='ms-2'>Copiar cód. do boleto</span>
          </Button>
        </CopyToClipboard>

        <Button
          as={ExternalLink}
          target='_blank'
          href={data.pdf}
        >
          <Icon name='download' size='sm' />

          <span className='ms-2'>Baixar boleto</span>
        </Button>
      </div>
    </Card>
  );
}

function RowContent({ title, content }) {
  return (
    <div className='d-flex align-items-baseline justify-content-between text-small fw-bold'>
      <div className='text-dark-gray me-8'>{title}</div>

      <div className='ms-2'>{content}</div>
    </div>
  );
}

export default ConfirmUpdatePlanStep;
