import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { Col, Row } from 'react-bootstrap';
import { useAlert } from 'react-alert';

import Modal from '@/components/Modal';
import Header from '@/components/EntityModal/Organization/Header/Header';
import ModalContent from '@/components/EntityModal/Organization/ModalContent';
import { OrganizationModalProvider } from '@/contexts/organizationModal';
import {
  useOrganization,
  useUpdateOrganization,
  useUpdatePerson,
  useDeleteOrganization
} from '@/api';
import APIErrorMessage from '@/components/APIErrorMessage';
import { useScreenSize } from '@/hooks';
import { getScreenSizeLabel } from '@/utils';
import { useAuth } from '@/lib/auth';
import { useTracking } from '@/lib/tracking';


const propTypes = {
  id: PropTypes.oneOfType([PropTypes.string]).isRequired
};

function OrganizationModal({ id }) {
  const alert = useAlert();
  const navigate = useNavigate();
  const updateOrganizationMutation = useUpdateOrganization();
  const updatePersonMutation = useUpdatePerson();
  const deleteOrganizationMutation = useDeleteOrganization();
  const screenSize = useScreenSize();
  const modalSize = getScreenSizeLabel(screenSize);
  const { user } = useAuth();
  const tracker = useTracking();


  const { data: organization, isLoading, isSuccess } = useOrganization({
    organizationId: id,
    params: { logo_size: 'medium' },
    config: { staleTime: 1000 }
  });

  useEffect(() => {
    if (isSuccess) {
      tracker.trackModalViewed({
        entityType: 'organization',
        user
      });
    }
  }, [isSuccess]);

  const onUpdate = (params, done) => {
    updateOrganizationMutation.mutate({ organizationId: id, params }, {
      onError: (err) => {
        alert.show(
          <APIErrorMessage err={err} resource='organization' action='update' />,
          { variant: 'danger', timeout: 5000 }
        );
        done?.({ error: err });
      },
      onSuccess: (data) => {
        alert.show(
          'Empresa atualizada com sucesso!',
          { variant: 'success', timeout: 5000 }
        );
        done?.(data);
      }
    });
  };

  const onUpdatePerson = (params, done, personData) => {
    updatePersonMutation.mutate({ personId: personData.id, params }, {
      onError: (err) => {
        alert.show(
          <APIErrorMessage err={err} resource='person' action='update' />,
          { variant: 'danger', timeout: 5000 }
        );
        done?.({ error: err });
      },
      onSuccess: (data) => {
        alert.show(
          'Pessoa atualizada com sucesso!',
          { variant: 'success', timeout: 5000 }
        );
        done?.(data);
      }
    });
  };

  const handleClose = () => {
    navigate(location?.pathname, { replace: true });
  };

  const onDelete = (_params, done) => {
    deleteOrganizationMutation.mutate({ organizationId: id }, {
      onError: (err) => {
        alert.show(
          <APIErrorMessage err={err} resource='organization' action='delete' />,
          { variant: 'danger', timeout: 5000 }
        );
        done?.(err);
      },
      onSuccess: () => {
        alert.show(
          'Empresa Excluida',
          { variant: 'success', timeout: 5000 }
        );
        done?.();
        handleClose();
      }
    });
  };

  if (!organization && isLoading) {
    return null;
  }

  return (
    <Modal
      id='organization-modal'
      size={modalSize}
      baseClassName='entity-modal overflow-y-scroll'
      bodyClassName='bg-light p-0 rounded'
      show={Boolean(id)}
      onHide={handleClose}
    >
      <OrganizationModalProvider
        value={{
          organization: organization.data,
          onUpdate,
          onUpdatePerson,
          onDelete
        }}
      >
        <Row className='mb-2 pb-4'>
          <Col sm='12'>
            <Header />
          </Col>
          <ModalContent organizationId={id} />
        </Row>
      </OrganizationModalProvider>
    </Modal>
  );
}

OrganizationModal.prototype = propTypes;

export default OrganizationModal;
