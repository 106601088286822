import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Tooltip from '@/components/Tooltip';

const propTypes = {
  tooltipText: PropTypes.string,
  className: PropTypes.string
};

const defaultProps = {
  tooltipText: '',
  className: ''
};

const NUM_DOTS = 3;
const DOTS = Array.from({ length: NUM_DOTS });
function LoadDots({ className, tooltipText }) {
  return (
    <Tooltip content={tooltipText}>
      <div className={classnames('typing-indicator', className)}>
        {DOTS.map((_, index) => <span key={index} className='text-primary dot' />)}
      </div>
    </Tooltip>
  );
}

LoadDots.propTypes = propTypes;
LoadDots.defaultProps = defaultProps;

export default LoadDots;
