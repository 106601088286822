import React, { useState } from 'react';
import { Outlet, useMatch } from 'react-router-dom';
import Nav from 'react-bootstrap/Nav';
import classnames from 'classnames';
import Button from '@/components/Button';
import Collapse from '@/components/Collapse';
import Icon from '@/components/Icon';
import NavLink from '@/components/NavLink';
import ExternalLink from '@/components/ExternalLink';
import * as Settings from '@/feature/settings';
import {
  legacyPlansUrl,
  legacySettingsUrl,
  settingsCustomFieldsPath,
  settingsProductsPath,
  settingsFunnelsPath,
  settingsProductCategoriesPath,
  settingsAutomationTemplatesPath,
  settingsAutomationsPath,
  settingsEmailTemplatesPath,
  settingsModulesVoipPath,
  settingsVoipPanelPath
} from '@/routes';
import { useAuth } from '@/lib/auth';

function SettingsBase() {
  const match = useMatch('/settings/:resource/*');

  const matchResource = match?.params.resource;
  const [formMenuExpanded, setFormExpanded] = useState(matchResource === 'custom_fields');
  const toggleFormMenu = () => setFormExpanded((prev) => !prev);

  const [productsMenuExpanded, setProductsExpanded] = useState(matchResource === 'products');
  const toggleProductsMenu = () => setProductsExpanded((prev) => !prev);

  const { user } = useAuth();

  const isAdmin = user?.admin;

  const canSeePlansTab = user.privileges.account_admin;

  return (
    <div className='d-flex flex-grow-1 flexible-height flexible-width'>
      <Nav
        className={classnames(
          'flex-column',
          'width-5',
          'border-end',
          'border-1',
          'flex-nowrap',
          'overflow-auto',
          'flex-shrink-0'
        )}
        // Para que a Nav não controle internamente o item ativo e fique só a partir da URL.
        activeKey=''
      >
        <div className='d-flex align-items-center p-5'>
          <Icon name='config' className='me-2' />
          <h4 className='mb-0'>Configurações</h4>
        </div>

        <hr className='opacity-50' />

        <div className='px-4 py-3'>
          {
            canSeePlansTab && (
              <>
                <div className='d-flex align-items-center'>
                  <Icon name='calendar' size='sm' className='me-1' />
                  <Settings.NavTitle>
                    Planos e pagamentos
                  </Settings.NavTitle>
                </div>

                <NavLink as={ExternalLink} href={legacyPlansUrl()} className='mb-2 mt-1 ms-4'>
                  Plano
                </NavLink>

                <hr className='opacity-50 mb-3' />
              </>
            )
          }

          <div className='d-flex align-items-center'>
            <Icon name='edit' size='sm' className='me-1' />
            <Settings.NavTitle>
              Personalização
            </Settings.NavTitle>
          </div>

          <NavLink href={settingsFunnelsPath()} className='ms-4'>
            Funis e etapas do processo
          </NavLink>

          <NavLink
            onClick={toggleFormMenu}
            as={Button}
            variant='link'
            className='w-100 border-0'
          >
            <span className={classnames('ms-4', { 'fw-bold me-2': formMenuExpanded })} >
              Campos customizados
            </span>

            <Icon
              name={formMenuExpanded ? 'arrow-up' : 'arrow-down'}
              className='ms-auto'
            />
          </NavLink>

          <Collapse in={formMenuExpanded}>
            <div className='ps-5'>
              <NavLink href={settingsCustomFieldsPath('organizations')}
                className=''>
                <Icon name='organization' className='me-2' />
                <span>Formulário de empresa</span>
              </NavLink>

              <NavLink href={settingsCustomFieldsPath('people')}
                className=''>
                <Icon name='person' className='me-2' />
                <span>Formulário de pessoa</span>
              </NavLink>

              <NavLink href={settingsCustomFieldsPath('deals')}
                className=''>
                <Icon name='deal' className='me-2' />
                <span>Formulário de negócio</span>
              </NavLink>
            </div>
          </Collapse>

          <NavLink
            onClick={toggleProductsMenu}
            as={Button}
            variant='link'
            className='rounded-0 w-100 border-0'
          >
            <span className={classnames('ms-4', { 'fw-bold': productsMenuExpanded })}>
              Produtos e serviços
            </span>

            <Icon
              name={productsMenuExpanded ? 'arrow-up' : 'arrow-down'}
              className='ms-auto'
            />
          </NavLink>

          <Collapse in={productsMenuExpanded}>
            <div className='ps-5'>
              <NavLink href={settingsProductsPath()} end>
                <Icon name='listing' className='me-1' />
                <span>Listagem de produtos</span>
              </NavLink>

              <NavLink href={settingsProductCategoriesPath()}>
                <Icon name='tag' className='me-1' />
                <span>Categorias de produtos</span>
              </NavLink>
            </div>
          </Collapse>

          <NavLink
            as={ExternalLink}
            href={legacySettingsUrl('Categorias')}
            target='_blank'
            className='ms-4'
          >
            Categorias de clientes
          </NavLink>

          <NavLink
            as={ExternalLink}
            href={legacySettingsUrl('MotivoPerda')}
            target='_blank'
            className='ms-4'
          >
            Motivos de perda
          </NavLink>

          <NavLink
            as={ExternalLink}
            href={legacySettingsUrl('OrigemLead')}
            target='_blank'
            className='ms-4'
          >
            Origens de clientes
          </NavLink>

          <NavLink
            as={ExternalLink}
            href={legacySettingsUrl('Setores')}
            target='_blank'
            className='ms-4'
          >
            Setores
          </NavLink>

          <NavLink href={settingsEmailTemplatesPath()} className='mb-2 ms-4'>
            Modelos de e-mail
          </NavLink>

          <hr className='opacity-50 mb-3' />

          <div className='d-flex align-items-center'>
            <Icon name='lightning' size='sm' className='me-1' />
            <Settings.NavTitle>
              Automações
            </Settings.NavTitle>
          </div>

          <NavLink href={settingsAutomationTemplatesPath()} className='ms-4'>
            Nova automação
          </NavLink>

          <NavLink href={settingsAutomationsPath()}
            aria-label='Automações criadas'
            className='mb-2 ms-4'
            end
          >
            Automações criadas
          </NavLink>

          <hr className='opacity-50 mb-3' />

          {isAdmin
            ? <>
              <div className='d-flex align-items-center'>
                <Icon name='call' size='sm' className='me-1' />
                <Settings.NavTitle>
                  Telefone Virtual
                </Settings.NavTitle>
              </div>

              <NavLink href={settingsModulesVoipPath()} className='ms-4'>
                Ativar telefone
              </NavLink>

              <NavLink href={settingsVoipPanelPath()} className='mb-2 ms-4'>
                Painel
              </NavLink>
            </>
            : null
          }

          <hr className='opacity-50 mb-3' />

          <div className='d-flex align-items-center'>
            <Icon name='lock' size='sm' className='me-1' />
            <Settings.NavTitle>
              Acessos
            </Settings.NavTitle>
          </div>

          <NavLink
            as={ExternalLink}
            href={legacySettingsUrl('Privacidade')}
            target='_blank'
            className='mb-2 ms-4'
          >
            Privacidade da conta
          </NavLink>

          <hr className='opacity-50 mb-3' />

          <div className='d-flex align-items-center'>
            <Icon name='notification' size='sm' className='me-1' />
            <Settings.NavTitle>
              Notificações
            </Settings.NavTitle>
          </div>

          <NavLink
            as={ExternalLink}
            href={legacySettingsUrl('Email')}
            target='_blank'
            className='ms-4'
          >
            Tarefas
          </NavLink>

          <NavLink
            as={ExternalLink}
            href={legacySettingsUrl('Summary')}
            target='_blank'
            className='ms-4'
          >
            Sumários
          </NavLink>

          <NavLink
            as={ExternalLink}
            href={legacySettingsUrl('Dropbox')}
            target='_blank'
            className='ms-4'
          >
            E-mail da conta
          </NavLink>
        </div>
      </Nav>

      <Outlet />
    </div>
  );
}

export default SettingsBase;
