import { useQuery, useInfiniteQuery, useMutation, useQueryClient } from 'react-query';
import { get, patch, post, _delete } from '@/lib/fetch';
import { backlogItemsKeys, organizationValidationKeys, peopleKeys, searchesKeys } from '@/api';

export const organizationsKeys = {
  all: ['organizations'],
  lists: () => [...organizationsKeys.all, 'list'],
  list: (params) => [...organizationsKeys.lists(), params],
  infiniteAll: ['infinite-organizations'],
  infiniteLists: () => [...organizationsKeys.infiniteAll, 'list'],
  infiniteList: (params) => ([...organizationsKeys.infiniteLists(), params]),
  details: () => [...organizationsKeys.all, 'detail'],
  detail: (organizationId) => [...organizationsKeys.details(), `${organizationId}`]
};

export async function getOrganizations(params = {}) {
  const { data } = await get('/organizations', params);

  return data;
}

export async function getOrganization(organizationId, params = {}) {
  const { data } = await get(`/organizations/${organizationId}`, params);

  return data;
}

export async function createOrganization(params = {}) {
  const { data } = await post('/organizations', params);

  return data;
}

export async function updateOrganization({ organizationId, params = {} }) {
  const { data } = await patch(`/organizations/${organizationId}`, params);

  return data;
}

export async function deleteOrganization({ organizationId }) {
  const { data } = await _delete(`/organizations/${organizationId}`);

  return data;
}

export function useInfiniteOrganizations({ params = {}, config = {} } = {}) {
  return useInfiniteQuery(
    organizationsKeys.infiniteList(params),
    ({ pageParam: page = 1 }) => getOrganizations({ ...params, page }),
    {
      ...config,
      getNextPageParam: (lastPage) => lastPage.meta.next
    }
  );
}

export function useOrganization({ organizationId, params = {}, config = {} } = {}) {
  return useQuery({
    ...config,
    queryKey: organizationsKeys.detail(organizationId),
    queryFn: () => getOrganization(organizationId, params)
  });
}

export function useOrganizations({ params = {}, config = {} }) {
  return useQuery({
    ...config,
    queryKey: organizationsKeys.list(params),
    queryFn: () => getOrganizations(params)
  });
}

export function useCreateOrganization({ config = {} } = {}) {
  return useMutation(
    createOrganization,
    { ...config }
  );
}

export function useUpdateOrganization({ config = {} } = {}) {
  const queryClient = useQueryClient();

  const onSuccess = async ({ data }) => {
    const promises = [
      queryClient.invalidateQueries(organizationsKeys.all),
      queryClient.invalidateQueries(organizationsKeys.infiniteAll)
    ];

    const peopleQueries = data
      .peopleIds
      .map(
        (personId) => queryClient.invalidateQueries(peopleKeys.detail(personId))
      );

    await Promise.all(promises.concat(peopleQueries));
  };

  return useMutation(
    updateOrganization,
    {
      onSuccess,
      ...config
    }
  );
}

export function useDeleteOrganization({ config = {} } = {}) {
  const queryClient = useQueryClient();

  const onSuccess = async () => {
    await Promise.all([
      queryClient.invalidateQueries(organizationsKeys.infiniteAll),
      queryClient.invalidateQueries(organizationValidationKeys.all),
      queryClient.invalidateQueries(searchesKeys.infiniteAll),
      queryClient.invalidateQueries(backlogItemsKeys.infiniteAll)
    ]);
  };

  return useMutation(
    deleteOrganization,
    {
      onSuccess,
      ...config
    }
  );
}
