import React from 'react';
import { useQueryClient } from 'react-query';

import DetailsContact from '@/components/EntityModal/DetailsContact';
import { usePerson, useOnUpdate } from '@/contexts/personModal';
import { personEditPath } from '@/routes';
import {
  peopleKeys,
  usePeopleCustomFields,
  useUpdatePerson,
  useCategories,
  useLeadOrigins,
  useOrganizations,
  useVoipVerify
} from '@/api';
import { canEditOrganization } from '@/policies';
import APIErrorMessage from '@/components/APIErrorMessage';
import Actions from '@/components/EntityModal/Person/Sidebar/Actions';
import PersonBasicData from '@/components/EntityModal/Person/Sidebar/PersonBasicData';
import Skeleton from '@/components/Skeleton';

function Sidebar() {
  const person = usePerson();
  const onUpdate = useOnUpdate();
  const entityEditPath = personEditPath(person.id);
  const queryClient = useQueryClient();
  const updatePersonMutation = useUpdatePerson();
  const entityType = 'person';

  const {
    data: voip
  } = useVoipVerify({ config: { staleTime: 1000 } });

  const {
    data: { data: personCustomFields } = {},
    isLoadingPersonCustomFields
  } = usePeopleCustomFields({ config: { staleTime: 1000 } });

  const {
    data: categoriesData,
    isLoading: isLoadingCategories
  } = useCategories({ config: { staleTime: 1000 } });

  const {
    data: leadOriginsData,
    isLoading: isLoadingleadOrigins
  } = useLeadOrigins({ config: { staleTime: 1000 } });

  const {
    data: organizationsData,
    isLoading: isLoadingOrganizations
  } = useOrganizations({ config: { staleTime: 1000 } });

  const isLoading = isLoadingCategories || isLoadingleadOrigins || isLoadingOrganizations;

  const onRefreshModal = () => {
    queryClient.invalidateQueries(
      peopleKeys.detail(person.id)
    );
  };

  const onUpdatePerson = (params, done, personData) => {
    updatePersonMutation.mutate({ personId: personData.id, params }, {
      onError: (err) => {
        alert.show(
          <APIErrorMessage err={err} resource='person' action='update' />,
          { variant: 'danger', timeout: 5000 }
        );
        done?.(err);
      },
      onSuccess: () => {
        alert.show(
          'Pessoa atualizada com sucesso!',
          { variant: 'success', timeout: 5000 }
        );
        onRefreshModal();
        done?.();
      }
    });
  };

  const entityCustomFields = personCustomFields || [];

  return (
    <>
      <Actions />
      { isLoading
        ? <Skeleton width={398.668} height={279} className='mb-3' />
        : <PersonBasicData
          person={person}
          onEditEntity={onUpdate}
          categories={categoriesData.data}
          leadOrigins={leadOriginsData.data}
          organizations={organizationsData.data}
          entityType={entityType}
          entityCustomFields={entityCustomFields}
        />
      }
      <DetailsContact
        onEditEntity={onUpdate}
        entity={person}
        entityType={entityType}
        entityTypeModal={entityType}
        entityEditPath={entityEditPath}
        entityCustomFields={entityCustomFields}
        canEditPolicy={canEditOrganization}
        loading={isLoadingPersonCustomFields}
        onCreatePerson={onRefreshModal}
        onEditPerson={onUpdatePerson}
        showTitle={false}
        voip={voip}
      />
    </>
  );
}

export default Sidebar;
