import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Icon from '@/components/Icon';
import Button from '@/components/Button';
import CreateEmailModal from '@/components/Email/CreateEmailModal';
import { useActivityEmail } from '@/api';
import { EMAIL_STATUSES } from '@/utils';

const propTypes = {
  email: PropTypes.object,
  entityId: PropTypes.number,
  small: PropTypes.bool,
  className: PropTypes.string
};

const defaultProps = {
  email: {},
  entityId: null,
  entityType: 'deal',
  small: false,
  className: ''
};

const ACTIONS = {
  REPLY: 'arrow-respond',
  REPLY_ALL: 'answer-everyone',
  FORWARD: 'arrow-to-forward'
};

function EmailActionButtons({ email, entityId, entityType, small, className }) {
  const [actionModal, setActionModal] = useState(null);
  const [loadEmailComplete, setLoadEmailComplete] = useState(false);

  const {
    data: completeEmail,
    isLoading: isLoadingCompleteEmail
  } = useActivityEmail({
    activityId: email?.activityId,
    config: {
      enabled: Boolean(email?.id && email?.activityId && loadEmailComplete)
    }
  });

  const disableAction = isLoadingCompleteEmail;
  const received = email?.status === EMAIL_STATUSES.RECEIVED;
  const from = email.from?.split?.(',') ?? [];
  const to = email.to ?? [];
  const cc = email.cc ?? [];
  const bcc = email.bcc ?? [];

  const handleCloseModal = () => {
    setActionModal(null);
    setLoadEmailComplete(false);
  };

  const handleOpenModal = (action, canComplete = false) => {
    setActionModal(action);
    setLoadEmailComplete(canComplete);
  };

  return (
    <>
      {
        actionModal === ACTIONS.REPLY
          ? (
            <CreateEmailModal
              show
              subject={email?.subject}
              to={received ? from : to}
              onClose={handleCloseModal}
              entityId={entityId}
              entityType={entityType}
            />
          )
          : null
      }
      {
        actionModal === ACTIONS.REPLY_ALL
          ? (
            <CreateEmailModal
              show
              subject={email?.subject}
              to={received ? from : to}
              cc={cc}
              cco={bcc}
              onClose={handleCloseModal}
              entityId={entityId}
              entityType={entityType}
            />
          )
          : null
      }
      {
        actionModal === ACTIONS.FORWARD && completeEmail
          ? (
            <CreateEmailModal
              show
              subject={email?.subject}
              body={completeEmail?.body}
              onClose={handleCloseModal}
              entityId={entityId}
              entityType={entityType}
            />
          )
          : null
      }
      <div className={className}>
        <Button
          disabled={disableAction}
          variant='link'
          className={classnames(
            'text-dark-gray',
            { 'mt-3 pb-0': small }
          )}
          onClick={() => handleOpenModal(ACTIONS.REPLY)}
        >
          <Icon name='arrow-respond' />
          {small ? '' : ' Responder'}
        </Button>
        <Button
          disabled={disableAction}
          variant='link'
          className={classnames(
            'text-dark-gray',
            { 'mt-3 pb-0': small }
          )}
          onClick={() => handleOpenModal(ACTIONS.REPLY_ALL)}
        >
          <Icon name='answer-everyone' />
          {small ? '' : ' Responder todos'}
        </Button>
        <Button
          disabled={disableAction}
          variant='link'
          className={classnames(
            'text-dark-gray',
            { 'mt-3 pb-0': small }
          )}
          onClick={() => handleOpenModal(ACTIONS.FORWARD, true)}
        >
          <Icon name={isLoadingCompleteEmail ? 'loading' : 'arrow-to-forward'} />
          {small ? '' : ' Encaminhar'}
        </Button>
      </div>
    </>
  );
}

EmailActionButtons.propTypes = propTypes;
EmailActionButtons.defaultProps = defaultProps;

export default EmailActionButtons;
