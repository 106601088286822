import React from 'react';
import { useQueryClient } from 'react-query';
import Button from '@/components/Button';
import { useSwitchTab, useVoipPopoverContext } from '@/contexts/voipPopover';
import { activitiesKeys, organizationsKeys, peopleKeys, dealsKeys } from '@/api';

const PER_PAGE = 30;

function Calling() {
  const queryClient = useQueryClient();

  const { entity, entityType } = useVoipPopoverContext();
  const switchTab = useSwitchTab();

  const onClick = () => {
    const detailsKeysMap = {
      organization: organizationsKeys,
      person: peopleKeys,
      deal: dealsKeys
    };
    Promise.all([
      queryClient.invalidateQueries(detailsKeysMap[entityType].detail(entity.id)),
      queryClient.invalidateQueries(activitiesKeys.infiniteLists({
        per_page: PER_PAGE,
        entity_id: entity.id,
        entity: entityType
      }))
    ]);

    switchTab('contacts');
  };

  return (
    <div className='px-5 py-4 text-center'>
      <p className='fw-bold mb-4'>Recarregue a página para visualizar a ligação</p>

      <hr className='my-2 text-light-gray opacity-50' />

      <p>
        Caso a ligação tenha sido bem sucedida,
        atualize clicando no botão abaixo ou pressionando F5.
      </p>

      <Button onClick={onClick}>Recarregar página</Button>
    </div>
  );
}

export default Calling;
