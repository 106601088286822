import { ptBR as LOCALE } from 'date-fns/locale';
import {
  startOfToday,
  addMonths,
  addDays,
  addYears,
  compareAsc,
  differenceInDays,
  format,
  formatISO,
  formatDistanceToNowStrict as dateFnFormatDistanceToNowStrict,
  formatDuration as dateFnFormatDuration,
  parseISO,
  parse,
  isValid,
  isThisYear,
  differenceInCalendarDays
} from 'date-fns';
import { isBlank, pluralize } from '@/utils';
export { isPast, isFuture, isToday } from 'date-fns';

const SHORT_DATE_FORMAT = 'dd/MM';
export const DATE_FORMAT = `${SHORT_DATE_FORMAT}/yyyy`;
export const DATE_FORMAT_SLASH = 'dd-MM-yyyy';
export const DATE_FORMAT_SLASH_INVERSE = 'yyyy-MM-dd';
export const TIME_FORMAT = 'HH:mm';
export const FULL_TIME_FORMAT = `${TIME_FORMAT}:ss`;
const DATE_TIME_FORMAT = `${DATE_FORMAT} ${TIME_FORMAT}`;
const TEXTUAL_DATE_FORMAT = 'dd \'de\' MMMM \'de\' yyyy';
const DAY_OF_WEEK_FORMAT = 'EEEE';
const SHORT_TEXTUAL_DATE_FORMAT = `${DAY_OF_WEEK_FORMAT}, ${DATE_FORMAT}`;
const TEXTUAL_MONTH_DATE_FORMAT = 'dd/MMM/yyyy';

export const MIN_YEAR = 1000;
export const MAX_YEAR = 3000;
/*
 * No caso de datas de aniversário, é permitido não informar o ano.
 * Para essas datas, é usado como referência o ano 2000, por ser bissexto e,
 * portanto, permitir a data de 29 de fevereiro.
 */
export const REFERENCE_YEAR = 2000;
export const MIN_IN_SECS = 60000;

export function now() {
  return new Date();
}

export function currentDateInSlash() {
  return format(now(), DATE_FORMAT_SLASH);
}

export function isCurrentYear(date) {
  return date.getYear() === now().getYear();
}

export function yesterday() {
  return addDays(now(), -1);
}

export function tomorrow() {
  return addDays(now(), 1);
}

export const nextWeek = () => {
  const ONE_WEEK = 7;

  return addDays(new Date(), ONE_WEEK);
};

export function nextMonth(date = now()) {
  return addMonths(new Date(date), 1);
}

export function nextYear(date = now()) {
  return addYears(new Date(date), 1);
}

export function parseDate(string) {
  return parseISO(string);
}

export function toDateTimeString(date) {
  return format(date, DATE_TIME_FORMAT);
}

export function convertDateTimeString(string) {
  if (isBlank(string)) {
    return null;
  }

  return toDateTimeString(parseDate(string));
}

export function toDateString(date, hideCurrentYear = false) {
  const hideYear = hideCurrentYear && isCurrentYear(date);

  return format(date, hideYear ? SHORT_DATE_FORMAT : DATE_FORMAT);
}

export function toShortDateString(date) {
  return format(date, SHORT_DATE_FORMAT);
}

export function toTimeString(date) {
  return format(date, TIME_FORMAT);
}

export function toTimeStringWithSeparator(dayRangeText, date) {
  return format(date, `${dayRangeText ? `'${dayRangeText}'` : DATE_FORMAT} 'às' ${TIME_FORMAT}`);
}

export function toTextualDateString(date) {
  return format(date, TEXTUAL_DATE_FORMAT, { locale: LOCALE });
}

export function toTextualMonthDateString(date) {
  return format(date, TEXTUAL_MONTH_DATE_FORMAT, { locale: LOCALE });
}

export function toShortTextualDateString(date) {
  return format(date, SHORT_TEXTUAL_DATE_FORMAT, { locale: LOCALE });
}

export function toDayOfWeek(date) {
  return format(date, DAY_OF_WEEK_FORMAT, { locale: LOCALE });
}

export function fromDMA(date) {
  if (!date) {
    return;
  }

  const dateValue = parse(date, DATE_FORMAT, new Date());

  if (!isValid(dateValue)) {
    return;
  }

  // O parse algumas vezes retorna ano menor que 1000 enquanto está sendo digitado no input
  if (dateValue.getFullYear() < MIN_YEAR) {
    return;
  }

  return dateValue;
}

export function formatDuration(duration, options = {}) {
  return dateFnFormatDuration(duration, { locale: LOCALE, ...options });
}

export function formatDurationMessage(value) {
  const number = Number(value?.number);
  const unit = value?.unit;

  if (!number || !unit) {
    return 'Para o mesmo dia';
  }

  return `Para ${formatDuration({ [unit]: number })} depois`;
}

export function formatDistanceToNowStrict(date, options = {}) {
  let dateLeft;
  let dateRight;
  const dateNow = now();
  const comparison = compareAsc(dateNow, date);

  if (comparison > 0) {
    dateLeft = date;
    dateRight = dateNow;
  } else {
    dateLeft = dateNow;
    dateRight = date;
  }

  if (differenceInDays(dateLeft, dateRight) === 0) {
    return formatDuration({ days: 1 });
  }

  return dateFnFormatDistanceToNowStrict(
    date,
    { ...options, locale: LOCALE }
  );
}

export function formatRange(from, to, formatter = toDateString) {
  if (!from && !to) {
    return '';
  }

  if (from === to) {
    return formatter(from);
  }

  if (from && !to) {
    return `A partir de ${formatter(from)}`;
  }

  if (!from && to) {
    return `Até ${formatter(to)}`;
  }

  return `${formatter(from)} → ${formatter(to)}`;
}

export function toISOString(dateString = '') {
  try {
    const parsedDate = parseDate(dateString);
    return parsedDate?.toISOString();
  } catch {
    return null;
  }
}

export function parseWithTimezoneOffset(dateString = '') {
  if (isBlank(dateString)) {
    return null;
  }
  const date = parseDate(dateString);
  const timestamp = date.valueOf() + (date.getTimezoneOffset() * MIN_IN_SECS);
  return new Date(timestamp);
}

export function formatUTCDate(value) {
  return isBlank(value) ? null : toDateString(parseWithTimezoneOffset(value));
}

export function formatDate(value) {
  return isBlank(value) ? null : toDateString(parseDate(value));
}

export function formatTime(value) {
  return isBlank(value) ? null : toTimeString(parseDate(value));
}

// Extrai timezone do formatISO, para que "2000-02-01T23:00:00-02:00" seja "2000-02-01T23:00:00"
export function toUTCISOString(date) {
  const dateString = formatISO(date, { representation: 'date' });
  const timeString = formatISO(date, { representation: 'time' }).split('-')[0];
  return `${dateString}T${timeString}`;
}

export const DATE_DEFAULT_PRESETS = [
  { label: 'Hoje', getValue: now },
  { label: 'Amanhã', getValue: tomorrow },
  { label: '1 semana depois', getValue: nextWeek }
];

const MS_REGEX = /\.[\d]+Z/u;

export function parseDateNoMs(dateString = '') {
  return dateString ? parseDate(dateString?.replace(MS_REGEX, '')) : null;
}
/*
 * Recebe uma data em formato string e retorna data no formato dd/MMM HH:mm para o ano atual
 * e quando não for o ano atual o formato deve ser dd/MMM/yyyy HH:mm
 */
export const formatDateDDMmm = (date) => {
  const formatoData = isThisYear(date) ? 'dd/MMM HH:mm' : 'dd/MMM/yyyy HH:mm';
  const dataFormatada = format(date, formatoData, { locale: LOCALE });

  return dataFormatada;
};

export const compareDates = (dateA, dateB) => {
  if (differenceInDays(dateA, dateB) < 1 && differenceInDays(dateA, dateB) >= -1) {
    return dateA && dateA.getDay() - dateB.getDay();
  }
  return differenceInDays(dateA, dateB);
};

export const getTimelineDayLabel = (date) => {
  const DATE_MSG = {
    '-1': 'Ontem',
    '0': 'Hoje',
    '1': 'Amanhã'
  };

  const today = startOfToday();

  const comparedIndex = compareDates(date, today);
  return DATE_MSG[comparedIndex];
};

export const getTimelineDateLabel = (date) => {
  const timelineDayLabel = getTimelineDayLabel(date);
  return timelineDayLabel ? `${timelineDayLabel} ${toTimeString(date)}` : formatDateDDMmm(date);
};

export function secondsToDuration(totalSeconds = 0) {
  const initialDate = new Date(0);
  initialDate.setUTCSeconds(totalSeconds);

  const hours = initialDate.getUTCHours();
  const minutes = initialDate.getUTCMinutes();
  const seconds = initialDate.getUTCSeconds();

  return `${hours ? `${hours}h` : ''} ${minutes ? `${minutes}m` : ''} ${seconds}s`;
}

export function distanceFromNowInCalendarDays(date) {
  const dateParsed = parseDate(date);

  const today = new Date();

  return differenceInCalendarDays(dateParsed, today);
}

export function pluralizeDaysRemainingText(daysRemaining) {
  if (daysRemaining === 0) {
    return 'Termina hoje';
  }

  const pluralizedRemaining = daysRemaining > 1 ? 'Restam' : 'Resta';

  return `${pluralizedRemaining} ${daysRemaining} ${pluralize('dia', daysRemaining)}`;
}

export function formatQueryStringDate(value) {
  try {
    const parsedDate = parse(value, DATE_FORMAT_SLASH_INVERSE, new Date());
    return format(parsedDate, DATE_FORMAT_SLASH_INVERSE);
  } catch (error) {
    return null;
  }
}
