import React from 'react';
import { Col } from 'react-bootstrap';

import Sidebar from '@/components/EntityModal/Organization/Sidebar/Sidebar';
import DealTab from '@/components/EntityModal/DealTab';
import Activities from '@/components/EntityModal/Organization/Activities/Activities';
import { useActiveTab } from '@/contexts/organizationModal';
import { useScreenSize } from '@/hooks';

function ModalContent({ organizationId }) {
  const activeTab = useActiveTab();
  const { isMD } = useScreenSize();

  if (activeTab === 'deals') {
    return (
      <>
        <Col sm='12'>
          <DealTab entityId={organizationId} entityType='organization' />
        </Col>
      </>
    );
  }

  return (
    <>
      <Col lg='8' sm='12'>
        <Activities />
      </Col>
      { isMD
        ? null
        : (
          <Col md='4'>
            <div className='me-3'>
              <Sidebar/>
            </div>
          </Col>
        ) }
    </>
  );
}

export default ModalContent;
