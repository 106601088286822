import React from 'react';

import {
  useOrganizationCustomFields,
  useCategories,
  useSectors,
  useLeadOrigins,
  useVoipVerify
} from '@/api';
import {
  useOrganization,
  useOnUpdate,
  useOnUpdatePerson
} from '@/contexts/organizationModal';
import { organizationEditPath } from '@/routes';
import { canEditOrganization } from '@/policies';
import DetailsContact from '@/components/EntityModal/DetailsContact';
import Actions from '@/components/EntityModal/Organization/Sidebar/Actions';
import OrganizationBasicData
  from '@/components/EntityModal/Organization/Sidebar/OrganizationBasicData';
import Skeleton from '@/components/Skeleton';

function Sidebar() {
  const organization = useOrganization();
  const onUpdate = useOnUpdate();
  const onUpdatePerson = useOnUpdatePerson();

  const {
    data: voip
  } = useVoipVerify({ config: { staleTime: 1000 } });

  const {
    data: { data: organizationsCustomFields } = {},
    isLoading: isLoadingOrganizationsCustomFields
  } = useOrganizationCustomFields({ config: { staleTime: 1000 } });

  const {
    data: categoriesData,
    isLoading: isLoadingCategories
  } = useCategories({ config: { staleTime: 1000 } });

  const {
    data: sectorsData,
    isLoading: isLoadingSectors
  } = useSectors({ config: { staleTime: 1000 } });

  const {
    data: leadOriginsData,
    isLoading: isLoadingleadOrigins
  } = useLeadOrigins({ config: { staleTime: 1000 } });

  const isLoading = isLoadingCategories || isLoadingSectors || isLoadingleadOrigins;

  const entityCustomFields = organizationsCustomFields || [];
  const entityEditPath = organizationEditPath(organization.id);
  const entityType = 'organization';

  return (
    <>
      <Actions />
      { isLoading
        ? <Skeleton width={398.668} height={279} className='mb-3' />
        : <OrganizationBasicData
          organization={organization}
          onEditEntity={onUpdate}
          categories={categoriesData.data}
          sectors={sectorsData.data}
          leadOrigins={leadOriginsData.data}
          entityCustomFields={entityCustomFields}
          entityType={entityType}
        />
      }
      <DetailsContact
        onEditEntity={onUpdate}
        entity={organization}
        entityType={entityType}
        entityTypeModal={entityType}
        entityEditPath={entityEditPath}
        entityCustomFields={entityCustomFields}
        canEditPolicy={canEditOrganization}
        loading={isLoadingOrganizationsCustomFields}
        onEditPerson={onUpdatePerson}
        showTitle={false}
        voip={voip}
      />
    </>
  );
}

export default Sidebar;
