import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { useAlert } from 'react-alert';
import { useNavigate } from 'react-router-dom';
import { useFormikContext } from 'formik';
import first from 'lodash/first';
import { useEmailRecipients, useEmailTemplates, useCreateEmailTemplate } from '@/api';
import { settingsEmailTemplatesPath } from '@/routes';
import Button from '@/components/Button';
import Icon from '@/components/Icon';
import Dropdown from '@/components/Dropdown';
import EmailTemplateModal from '@/components/Email/EmailTemplateModal';
import Authorization from '@/components/Authorization';
import { canCreateEmailTemplate } from '@/policies';

const propTypes = {
  entityId: PropTypes.number,
  entityType: PropTypes.oneOf(['organization', 'person', 'deal']),
  onSelect: PropTypes.func
};

const defaultProps = {
  entityId: null,
  entityType: 'deal',
  onSelect: () => {}
};

function EmailTemplateList({ entityId, entityType, onSelect }) {
  const alert = useAlert();
  const createEmailTemplate = useCreateEmailTemplate();
  const formikProps = useFormikContext();
  const navigate = useNavigate();
  const [showEmailTemplateModal, setShowEmailTemplateModal] = useState(false);
  const [showTemplateList, setShowTemplateList] = useState(false);

  const { data: emailRecipientsResult } = useEmailRecipients({
    config: { enabled: Boolean(entityId) },
    params: { entity_id: entityId, entity_type: entityType }
  });

  const { data: emailTemplatesResult } = useEmailTemplates();

  const emailTemplates = useMemo(() => (
    emailTemplatesResult?.data ?? []
  ), [emailTemplatesResult]);

  const emailRecipients = useMemo(() => (
    emailRecipientsResult?.data ?? []
  ), [emailRecipientsResult]);

  const handleTemplateCancel = () => {
    setShowEmailTemplateModal(false);
  };

  const handleTemplateSubmit = (formData) => {
    createEmailTemplate.mutate(formData, {
      onSuccess: () => {
        setShowEmailTemplateModal(false);
        alert.show(
          'Modelo de e-mail salvo com sucesso',
          { variant: 'success', timeout: 5000 }
        );
      },
      onError: () => {
        alert.show(
          'Falha ao adicionar modelo de e-mail',
          { variant: 'danger', timeout: 5000 }
        );
      }
    });
  };

  const setBodyAndSubjectValue = (body, subject) => {
    let dt = {
      name: '{{nome}}',
      firstName: '{{nome-completo}}',
      orgName: '{{nome-empresa}}'
    };

    const recipientEmail = first(formikProps.values.to);
    if (recipientEmail) {
      for (const recipientData of emailRecipients) {
        if (recipientData.email === recipientEmail) {
          dt = recipientData;
          break;
        }
      }
    }

    let bodyr = formikProps.values.body;
    bodyr = bodyr + body.replaceAll('{{nome-completo}}', dt.name);
    bodyr = bodyr.replaceAll('{{nome}}', dt.firstName);
    bodyr = bodyr.replaceAll('{{nome-empresa}}', dt.orgName);

    let subjectr = subject.replaceAll('{{nome-completo}}', dt.name);
    subjectr = subjectr.replaceAll('{{nome}}', dt.firstName);
    subjectr = subjectr.replaceAll('{{nome-empresa}}', dt.orgName);

    formikProps.setFieldValue('body', bodyr);
    formikProps.setFieldValue('subject', subjectr);
  };

  return (
    <>
      <EmailTemplateModal
        onCancel={handleTemplateCancel}
        onSubmit={handleTemplateSubmit}
        show={showEmailTemplateModal}
        renderName={false}
      />
      <Dropdown className='justify-content-end' >
        <Dropdown.Toggle
          size='md'
          as={Button}
          variant='outline-dark-gray'
        >
          <Icon name='plus' className='me-1' />
          Modelos
        </Dropdown.Toggle>

        <Dropdown.Menu
          renderOnMount
          className='width-5'
        >
          <Authorization policy={canCreateEmailTemplate}>
            <Dropdown.Item
              as='button'
              type='button'
              onClick={() => setShowEmailTemplateModal(true)}
            >
              <Icon name='plus' className='me-1' />
              Criar novo modelo
            </Dropdown.Item>
          </Authorization>

          <Dropdown.Item
            as='button'
            type='button'
            onClick={() => navigate(settingsEmailTemplatesPath())}
          >
            <Icon name='config' className='me-1' />
            Gerenciar modelos
          </Dropdown.Item>
          <Dropdown.Divider />

          <div className='max-height-5 overflow-auto'>
            {
              showTemplateList
                ? (
                  <Dropdown.ItemText className='text-medium-gray p-2 ms-3'>
                    Nenhum modelo criado.
                  </Dropdown.ItemText>
                )
                : (
                  emailTemplates.map((template) => (
                    <Dropdown.Item
                      as='div'
                      key={template.id}
                      className={classnames(
                        'border-bottom',
                        'border-1',
                        'border-light',
                        'text-wrap',
                        'word-wrap',
                        'py-2',
                        'justify-content-between',
                        'align-items-center',
                        'cursor-pointer'
                      )}
                      onClick={() => {
                        setBodyAndSubjectValue(
                          `${template.body}`,
                          `${template.subject}`
                        );
                        setShowTemplateList(false);
                        onSelect(template);
                      }}
                    >
                      <span className='flexible-width'>
                        {template.name}
                      </span>
                    </Dropdown.Item>
                  ))
                )
            }
          </div>
        </Dropdown.Menu>
      </Dropdown>
    </>
  );
}

EmailTemplateList.propTypes = propTypes;
EmailTemplateList.defaultProps = defaultProps;

export default EmailTemplateList;
