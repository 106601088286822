import React from 'react';
import HeaderPaymentWarning, { useHeaderPaymentWarning } from '@/components/HeaderPaymentWarning';
import HeaderPaymentMethod, { useHeaderPaymentMethod } from '@/components/HeaderPaymentMethod';
import { useCurrentPlan } from '@/api';
import { useAuth } from '@/lib/auth';

function HeaderWarning() {
  const { user } = useAuth();
  const { isLoading, data: { data: currentPlanData } = {} } = useCurrentPlan({
    config: { enabled: user.admin }
  });

  const {
    show: showPaymentWarning, onClose: onClosePaymentWarning
  } = useHeaderPaymentWarning();
  const {
    show: showPaymentMethod, onClose: onClosePaymentMethod
  } = useHeaderPaymentMethod(currentPlanData);

  let HeaderComponent;
  let onClose;

  if (showPaymentWarning) {
    HeaderComponent = HeaderPaymentWarning;
    onClose = onClosePaymentWarning;
  } else if (showPaymentMethod) {
    HeaderComponent = HeaderPaymentMethod;
    onClose = onClosePaymentMethod;
  }

  if (isLoading || !HeaderComponent) {
    return null;
  }

  return (
    <HeaderComponent currentPlanData={currentPlanData} onClose={onClose} />
  );
}

export default HeaderWarning;
