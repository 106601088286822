const trackingEvents = {
  organization: {
    create: 'Empresa adicionada (Neo)'
  },
  person: {
    create: 'Pessoa adicionada (Neo)'
  },
  deal: {
    create: 'Negocio adicionado (Neo)'
  },
  dealModalViewed: {
    create: 'Nova ficha de negocio visualizada'
  },
  automation: {
    create: 'Automação criada'
  },
  leadsGenerationPresented: 'Geracao de leads apresentada',
  leadsGenerationStarted: 'Geracao de leads iniciada',
  leadsGenerationPaymentStarted: 'Geracao de leads pagamento iniciado',
  proposalCreated: 'Proposta gerada',
  proposalNotCreated: 'Proposta não gerada'
};

const defaultMetadata = {
  Ambiente: 'Neo'
};

export class IntercomTracker {
  #trackerFn;

  constructor(trackerFn) {
    this.#trackerFn = trackerFn;
  }

  trackOrganizationCreated(metadata = {}) {
    return (
      this.#trackerFn(
        trackingEvents.organization.create,
        this.#buildMetadata(metadata)
      )
    );
  }

  trackPersonCreated(metadata = {}) {
    return (
      this.#trackerFn(
        trackingEvents.person.create,
        this.#buildMetadata(metadata)
      )
    );
  }

  trackDealCreated(metadata = {}) {
    return (
      this.#trackerFn(
        trackingEvents.deal.create,
        this.#buildMetadata(metadata)
      )
    );
  }

  trackDealModalViewed(metadata = {}) {
    return (
      this.#trackerFn(
        trackingEvents.dealModalViewed.create,
        this.#buildMetadata(metadata)
      )
    );
  }

  trackAutomationCreated(metadata = {}) {
    return (
      this.#trackerFn(
        trackingEvents.automation.create,
        this.#buildMetadata(metadata)
      )
    );
  }

  trackLeadsGenerationPresented(data) {
    const { user } = data;
    const eventMetadata = {
      'ID do usuario': user.id,
      'ID do cliente': user.account.id
    };

    return (
      this.#trackerFn(
        trackingEvents.leadsGenerationPresented,
        this.#buildMetadata(eventMetadata)
      )
    );
  }

  trackLeadsGenerationStarted(data) {
    const { user } = data;
    const eventMetadata = {
      'ID do usuario': user.id,
      'ID do cliente': user.account.id
    };

    return (
      this.#trackerFn(
        trackingEvents.leadsGenerationStarted,
        this.#buildMetadata(eventMetadata)
      )
    );
  }

  trackLeadsGenerationPaymentStarted(data) {
    const { user } = data;
    const eventMetadata = {
      'ID do usuario': user.id,
      'ID do cliente': user.account.id
    };

    return (
      this.#trackerFn(
        trackingEvents.leadsGenerationPaymentStarted,
        this.#buildMetadata(eventMetadata)
      )
    );
  }

  trackProposalCreated(data) {
    const { user } = data;
    const eventMetadata = {
      'ID do usuario': user.id,
      'ID do cliente': user.account.id
    };

    return (
      this.#trackerFn(
        trackingEvents.proposalCreated,
        this.#buildMetadata(eventMetadata)
      )
    );
  }

  trackProposalNotCreated(data) {
    const { user } = data;
    const eventMetadata = {
      'ID do usuario': user.id,
      'ID do cliente': user.account.id
    };

    return (
      this.#trackerFn(
        trackingEvents.proposalNotCreated,
        this.#buildMetadata(eventMetadata)
      )
    );
  }

  #buildMetadata(metadata) {
    return { ...defaultMetadata, ...metadata };
  }
}
