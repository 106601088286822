import React, { useState, useEffect, useRef, useMemo } from 'react';
import { Col, Row } from 'react-bootstrap';
import linkify from 'linkify-string';

import IconLabel from '@/components/IconLabel';
import { EditInPlace, TextArea } from '@/components/Inputs';
import Button from '@/components/Button';

function ShowMoreDescription({
  description,
  onEditEntity
}) {
  const [isShowingMore, setIsShowingMore] = useState(false);
  const [isTruncated, setIsTruncated] = useState(false);
  const textRef = useRef(null);

  const formattedDescription = useMemo(() => {
    if (!description) {
      return '';
    }

    return breakLine(injectLinks(description));
  }, [description]);

  const truncateStyle = {
    display: '-webkit-box',
    WebkitLineClamp: 3,
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'normal',
    wordBreak: 'break-word'
  };

  const toggleShowMore = (event) => {
    event?.preventDefault?.();
    setIsShowingMore(!isShowingMore);
  };

  const showMoreLabel = 'Ver mais';
  const showLessLabel = 'Ver menos';

  useEffect(() => {
    const element = textRef.current;
    if (element) {
      const originalHeight = element.scrollHeight;
      const truncatedHeight = element.clientHeight;

      setIsTruncated(truncatedHeight !== originalHeight);
    }
  }, [description]);

  return (
    <>
      <Row className='align-items-center mb-3'>
        <Col sm={6}>
          <IconLabel
            text='Descrição'
            className='me-3 text-dark-gray'
          />
        </Col>
      </Row>
      <Row className='align-items-center mb-3'>
        <EditInPlace
          name='description'
          as={TextArea}
          value={description}
          onConfirm={(...args) => {
            onEditEntity(...args);
            setIsShowingMore(false);
          }}
          className={'h-auto'}
        >
          {description
            ? (
              <div
                ref={textRef}
                className='w-100'
                dangerouslySetInnerHTML={{ __html: formattedDescription }}
                style={isShowingMore ? { wordBreak: 'break-word' } : truncateStyle}
              />
            )
            : (
              <span className='text-medium-gray'>Adicionar descrição</span>
            )}

        </EditInPlace>
      </Row>
      {isTruncated && (
        <Row className='align-items-center mb-3'>
          <Col sm={6}>
            <Button
              variant='link'
              className='fw-bold p-0'
              onClick={toggleShowMore}
            >
              {isShowingMore ? showLessLabel : showMoreLabel}
            </Button>
          </Col>
        </Row>
      )}
    </>
  );
}

function breakLine(text = '') {
  return text.replaceAll('\n', '<br />');
}

function injectLinks(text = '') {
  return linkify(text, { target: '_blank', className: 'word-wrap' });
}

export default ShowMoreDescription;
