/* eslint complexity: 0 */
import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import sortBy from 'lodash/sortBy';
import { applyMask } from '@/masks';
import {
  PROPOSAL_SECTIONS_TYPE, getDocType, PROPOSAL_SECTIONS_TITLE
} from '@/utils';
import { useTracking } from '@/lib/tracking';
import Icon from '@/components/Icon';
import Button from '@/components/Button';
import Form from '@/components/Form';
import FormField from '@/components/FormField';
import LoadingButton from '@/components/LoadingButton';
import BannerArea from '@/components/BannerArea';
import Tooltip from '@/components/Tooltip';
import ProposalLoading from '@/components/Proposal/ProposalLoading';
import ProposalLogo from '@/components/Proposal/ProposalLogo';
import {
  EditInPlace, DatePickerInput, ImagePicker, TextInput, MaskedInput
} from '@/components/Inputs';
import { ProposalProductsInput } from '@/components/Proposal/ProposalProducts';
import { ProposalRichTextInput } from '@/components/Proposal/ProposalRichText';
import ProposalAddSection from '@/components/Proposal/ProposalAddSection';
import ProposalDeleteSection from '@/components/Proposal/ProposalDeleteSection';
import strings from '@/strings';
import logoImage from 'images/logo-proposal-footer.png';

ProposalForm.propTypes = {
  user: PropTypes.object,
  proposal: PropTypes.object,
  version: PropTypes.string,
  processing: PropTypes.bool,
  layoutMode: PropTypes.bool,
  currentDate: PropTypes.string,
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
  onChange: PropTypes.func
};

ProposalForm.defaultProps = {
  user: {},
  proposal: {},
  version: '',
  processing: false,
  layoutMode: false,
  currentDate: '',
  onSubmit: () => { },
  onCancel: () => { },
  onChange: () => { }
};

const SectionInputComponent = {
  [PROPOSAL_SECTIONS_TYPE.RICH_TEXT]: ProposalRichTextInput,
  [PROPOSAL_SECTIONS_TYPE.PRODUCTS]: ProposalProductsInput
};

function ProposalForm({
  user, processing, proposal, version, currentDate,
  onSubmit, onChange, onCancel, layoutMode
}) {
  const tracker = useTracking();
  const docType = getDocType(proposal?.cpfCnpj);
  const maskedCpfCnpj = applyMask(proposal?.cpfCnpj, docType);
  const docTypeLabel = docType ? `${docType.toUpperCase()}: ` : '';
  const titleLabel = proposal?.title || 'Título não informado';
  const cpfCnpjLabel = maskedCpfCnpj || 'CPF/CNPJ não informado';
  const addressLabel = proposal?.address || 'Endereço não informado';
  const legalNameLabel = proposal?.legalName || 'Razão social não informada';
  const allProposalModelSections = proposal?.proposalModelSections ?? [];
  const proposalModelSections = sortBy(allProposalModelSections, 'position');
  const hidePoweredBy = user.account.legacyPro || user.account.performanceOrHigher;
  const visibleSectionsCount = proposalModelSections
    .filter((section) => !section.hidden).length;
  const hasProducts = Boolean(proposalModelSections?.find(
    ({ sectionType, hidden }) => sectionType === PROPOSAL_SECTIONS_TYPE.PRODUCTS && !hidden
  ));

  const titleColorClass = layoutMode ? 'text-dark-gray' : 'text-darker-gray';
  const contentColorClass = layoutMode ? 'text-medium-gray' : 'text-darker-gray';
  const versionCodeClass = layoutMode ? 'cursor-pointer' : 'cursor-default';
  const output = layoutMode ? 'o modelo' : 'a proposta';

  const logo = proposal?.logo;
  const banner = proposal?.banner;
  const handleCancelWithIntercomTracker = () => {
    tracker.trackProposalNotCreated({ user });
    onCancel();
  };

  return (
    <Form
      name='proposal-form'
      initialValues={proposal}
      defaultValues={proposal}
      onSubmit={onSubmit}
    >
      {({ isSubmitting, values, handleSubmit }) => (
        <>
          {
            (processing || isSubmitting)
              ? (
                <ProposalLoading
                  title={`Preparando ${output}`}
                  subtitle={`Aguarde só um pouco, estamos preparando ${output}.`}
                />
              )
              : (
                <>
                  <FormField
                    as={BannerArea}
                    name='banner'
                    value={banner}
                    className='mx-0 p-0'
                    hide={!layoutMode}
                    layoutMode={layoutMode}
                    bgColor={proposal?.bgColor}
                    textColor={proposal?.textColor}
                    onChangeColors={(newColors) => onChange({ ...values, ...newColors })}
                  >
                    <div className='w-100 mt-6 pe-8 text-end'>
                      <Tooltip
                        content={strings.labels.proposal_models.layout_mode.code}
                        placement='top'
                        hide={!layoutMode}
                      >
                        <div className={classnames(versionCodeClass)}>
                          {layoutMode ? 'Código da versão' : version}
                        </div>
                      </Tooltip>
                    </div>
                    <Col sm={3} className='text-center mx-auto'>
                      {
                        layoutMode
                          ? (
                            <FormField
                              as={ImagePicker}
                              name='logo'
                              value={logo}
                              label='logotipo'
                              iconName='organization'
                              className='d-inline-block ms-4'
                            />
                          )
                          : logo && <ProposalLogo src={logo?.preview} />
                      }
                    </Col>
                    <Col sm={9} className='mt-n4'>
                      <EditInPlace
                        as={TextInput}
                        name='title'
                        hideEdit={!layoutMode}
                        value={proposal?.title}
                        disabled={isSubmitting}
                        className='d-block width-5'
                        onCancel={(oldParams) => onChange({ ...values, ...oldParams })}
                        onConfirm={(newParams, done) => {
                          onChange({ ...values, ...newParams });
                          done();
                        }}
                      >
                        <span className='fw-bold mb-2 d-block'>
                          {titleLabel}
                        </span>
                      </EditInPlace>
                      <EditInPlace
                        as={TextInput}
                        hideEdit={!layoutMode}
                        name='legalName'
                        value={values?.legalName}
                        className='d-block width-6'
                        onCancel={(oldParams) => onChange({ ...values, ...oldParams })}
                        onConfirm={(newParams, done) => {
                          onChange({ ...values, ...newParams });
                          done();
                        }}
                      >
                        <h1 className='fw-bold mb-2 d-block'>
                          {legalNameLabel}
                        </h1>
                      </EditInPlace>
                      <EditInPlace
                        as={MaskedInput}
                        name='cpfCnpj'
                        maskType='cpf_cnpj'
                        hideEdit={!layoutMode}
                        value={proposal?.cpfCnpj}
                        className='d-block width-5'
                        onCancel={(oldParams) => onChange({ ...values, ...oldParams })}
                        onConfirm={(newParams, done) => {
                          onChange({ ...values, ...newParams });
                          done();
                        }}
                      >
                        <span className='fw-bold mb-1 d-block'>
                          {docTypeLabel}{cpfCnpjLabel}
                        </span>
                      </EditInPlace>
                      <EditInPlace
                        as={TextInput}
                        name='address'
                        hideEdit={!layoutMode}
                        value={proposal?.address}
                        className='d-block width-6'
                        onCancel={(oldParams) => onChange({ ...values, ...oldParams })}
                        onConfirm={(newParams, done) => {
                          onChange({ ...values, ...newParams });
                          done();
                        }}
                      >
                        <span className='fw-bold mb-1 d-block'>
                          {addressLabel}
                        </span>
                      </EditInPlace>
                      {
                        layoutMode
                          ? null
                          : (
                            <div className='d-inline-flex fw-bold mb-1'>
                              <span className='text-capitalize'>{currentDate}</span>
                              &nbsp;|&nbsp;Válida até:&nbsp;
                              <EditInPlace
                                initialShow
                                isDatePicker
                                label=''
                                as={DatePickerInput}
                                placement='right-end'
                                placeholder='00/00/0000'
                                name='expiredAt'
                                value={proposal?.expiredAt}
                                className='width-4'
                                onCancel={(oldParams) => onChange({ ...values, ...oldParams })}
                                onConfirm={(newParams, done) => {
                                  onChange({ ...values, ...newParams });
                                  done();
                                }}
                              >
                                {proposal?.expiredAt}
                              </EditInPlace>
                            </div>
                          )
                      }
                    </Col>
                  </FormField>
                  <Tooltip
                    content={strings.labels.proposal_models.layout_mode.sections}
                    placement='top'
                    hide={!layoutMode}
                  >
                    <Row className='m-8 py-2 cursor-pointer'>
                      <Col sm={4} className='ps-6'>
                        <div className={classnames(titleColorClass, 'fw-bold')}>
                          {
                            layoutMode
                              ? 'Dados do vendedor'
                              : `Proposta enviada por ${user?.name || 'não informado'}`
                          }
                        </div>
                        <div className={classnames(contentColorClass, 'd-flex align-items-center')}>
                          <Icon className='me-1' name='email' size='sm' />
                          <span>
                            {
                              layoutMode
                                ? 'E-mail do vendedor'
                                : `${user?.email || 'Indefinido'}`
                            }
                          </span>
                        </div>
                        <div className={classnames(contentColorClass, 'd-flex align-items-center')}>
                          <Icon className='me-1' name='call' size='sm' />
                          <span>
                            {
                              layoutMode
                                ? 'Telefone do vendedor'
                                : applyMask(user?.workPhone, 'phone') ?? 'Indefinido'
                            }
                          </span>
                        </div>
                      </Col>
                      <Col sm={4}>
                        {
                          layoutMode
                            ? (
                              <span className={classnames(titleColorClass, 'fw-bold')}>
                                Dados do cliente
                              </span>
                            )
                            : (
                              <EditInPlace
                                as={TextInput}
                                name='entityName'
                                value={proposal?.entityName}
                                className='width-5'
                                onCancel={(oldParams) => onChange({ ...values, ...oldParams })}
                                onConfirm={(newParams, done) => {
                                  onChange({ ...values, ...newParams });
                                  done();
                                }}
                              >
                                <span className='text-darker-gray fw-bold text-wrap word-wrap'>
                                  Para:&nbsp;{proposal?.entityName || 'não informado'}
                                </span>
                              </EditInPlace>
                            )
                        }
                        {
                          layoutMode
                            ? (
                              <div className={classnames(contentColorClass, 'max-width-5')}>
                                <Icon className='me-1' name='email' size='sm' />
                                <span className='d-inline text-wrap word-wrap'>
                                  E-mail do cliente
                                </span>
                              </div>
                            )
                            : (
                              <EditInPlace
                                as={TextInput}
                                name='entityEmail'
                                value={proposal?.entityEmail}
                                className='width-5'
                                readableClassName='text-nowrap'
                                placeholder='Informe o e-mail de contato'
                                onCancel={(oldParams) => onChange({ ...values, ...oldParams })}
                                onConfirm={(newParams, done) => {
                                  onChange({ ...values, ...newParams });
                                  done();
                                }}
                              >
                                <div className='max-width-5 text-darker-gray'>
                                  <Icon className='me-1' name='email' size='sm' />
                                  <span className='d-inline text-wrap word-wrap'>
                                    {proposal?.entityEmail || 'Indefinido'}
                                  </span>
                                </div>
                              </EditInPlace>
                            )
                        }
                        {
                          layoutMode
                            ? (
                              <div className={classnames(contentColorClass, 'max-width-5')}>
                                <Icon className='me-1' name='call' size='sm' />
                                <span className='d-inline text-wrap word-wrap'>
                                  Telefone do cliente
                                </span>
                              </div>
                            )
                            : (
                              <EditInPlace
                                as={MaskedInput}
                                name='entityWorkPhone'
                                value={proposal?.entityWorkPhone}
                                maskType='phone'
                                autoComplete='off'
                                placeholder='Informe o telefone de contato'
                                className='width-5'
                                onCancel={(oldParams) => onChange({ ...values, ...oldParams })}
                                onConfirm={(newParams, done) => {
                                  onChange({ ...values, ...newParams });
                                  done();
                                }}
                              >
                                <div className='max-width-5 text-darker-gray'>
                                  <Icon className='me-1' name='call' size='sm' />
                                  <span className='d-inline text-wrap word-wrap'>
                                    {
                                      proposal?.entityWorkPhone
                                        ? applyMask(proposal?.entityWorkPhone, 'phone')
                                        : 'Indefinido'
                                    }
                                  </span>
                                </div>
                              </EditInPlace>
                            )
                        }
                      </Col>
                      {
                        proposal?.deal?.organization
                          ? (
                            <Col sm={4}>
                              <div className={classnames(titleColorClass, 'fw-bold')}>
                                Dados da empresa
                              </div>
                              {
                                layoutMode
                                  ? (
                                    <div className={classnames(contentColorClass, 'max-width-5')}>
                                      <Icon className='me-1' name='organization' size='sm' />
                                      <span className='d-inline text-wrap word-wrap'>
                                        Nome da empresa
                                      </span>
                                    </div>
                                  )
                                  : (
                                    <EditInPlace
                                      as={TextInput}
                                      name='entityName'
                                      value={proposal?.entityName}
                                      className='width-5'
                                      placeholder='Informe nome da empresa'
                                      onCancel={(oldParams) => onChange(
                                        { ...values, ...oldParams }
                                      )}
                                      onConfirm={(newParams, done) => {
                                        onChange({ ...values, ...newParams });
                                        done();
                                      }}
                                    >
                                      <div className='max-width-5 text-darker-gray'>
                                        <Icon className='me-1' name='organization' size='sm' />
                                        <span className='d-inline text-wrap word-wrap'>
                                          {proposal?.entityName || 'Indefinido'}
                                        </span>
                                      </div>
                                    </EditInPlace>
                                  )
                              }
                              {
                                layoutMode
                                  ? (
                                    <div className={classnames(contentColorClass, 'max-width-5')}>
                                      <Icon className='me-1' name='organization' size='sm' />
                                      <span className='d-inline text-wrap word-wrap'>
                                        CNPJ da empresa
                                      </span>
                                    </div>
                                  )
                                  : (
                                    <EditInPlace
                                      as={MaskedInput}
                                      name='entityCnpj'
                                      value={proposal?.entityCnpj}
                                      maskType='cnpj'
                                      autoComplete='off'
                                      placeholder='Informe CNPJ da empresa'
                                      className='width-5'
                                      onCancel={(oldParams) => onChange(
                                        { ...values, ...oldParams }
                                      )}
                                      onConfirm={(newParams, done) => {
                                        onChange({ ...values, ...newParams });
                                        done();
                                      }}
                                    >
                                      <div className='max-width-5 text-darker-gray'>
                                        <Icon className='me-1' name='organization' size='sm' />
                                        <span className='d-inline text-wrap word-wrap'>
                                          {
                                            proposal?.entityCnpj
                                              ? applyMask(proposal?.entityCnpj, 'cnpj')
                                              : 'Indefinido'
                                          }
                                        </span>
                                      </div>
                                    </EditInPlace>
                                  )
                              }
                              {
                                layoutMode
                                  ? (
                                    <div className={classnames(contentColorClass, 'max-width-5')}>
                                      <Icon className='me-1' name='visit' size='sm' />
                                      <span className='d-inline text-wrap word-wrap'>
                                        Endereço da empresa
                                      </span>
                                    </div>
                                  )
                                  : (
                                    <EditInPlace
                                      as={TextInput}
                                      name='entityAddress'
                                      value={proposal?.entityAddress}
                                      className='width-5'
                                      placeholder='Informe endereço completo'
                                      onCancel={(oldParams) => onChange(
                                        { ...values, ...oldParams }
                                      )}
                                      onConfirm={(newParams, done) => {
                                        onChange({ ...values, ...newParams });
                                        done();
                                      }}
                                    >
                                      <div className='max-width-5 text-darker-gray'>
                                        <Icon className='me-1' name='visit' size='sm' />
                                        <div className='d-inline text-wrap word-wrap'>
                                          {proposal?.entityAddress || 'Indefinido'}
                                        </div>
                                      </div>
                                    </EditInPlace>
                                  )
                              }
                            </Col>
                          )
                          : null
                      }
                    </Row>
                  </Tooltip>
                  {
                    proposalModelSections?.map((section, index) => {
                      const { sectionType, title, content, hidden } = section;
                      const InputComponent = SectionInputComponent[sectionType];
                      if (hidden) {
                        return null;
                      }

                      return (
                        <div key={`${sectionType}_${index}`}>
                          <InputComponent
                            name={`proposalModelSections.${index}`}
                            title={title}
                            content={content}
                            layoutMode={layoutMode}
                            actions={
                              <ProposalDeleteSection
                                layoutMode={layoutMode}
                                section={section}
                                disabled={visibleSectionsCount <= 1}
                                onDeleteSection={(deletedSection) => {
                                  proposalModelSections[index] = deletedSection;
                                  onChange({
                                    ...values,
                                    proposalModelSections
                                  });
                                }}
                              />
                            }
                            onChangeTitle={(newTitle) => {
                              proposalModelSections[index].title = newTitle;
                              onChange({ ...values, proposalModelSections });
                            }}
                            onChangeContent={(newContent) => {
                              proposalModelSections[index].content = newContent;
                              onChange({ ...values, proposalModelSections });
                            }}
                            onChangeProducts={(newProducts) => {
                              onChange({ ...values, entity_products: newProducts });
                            }}
                          />
                          <ProposalAddSection
                            layoutMode={layoutMode}
                            onAddSection={(newSectionType) => {
                              const position = index + 1;
                              proposalModelSections.splice(position, 0, {
                                sectionType: newSectionType,
                                title: PROPOSAL_SECTIONS_TITLE[newSectionType],
                                content: '',
                                position
                              });
                              const updated = proposalModelSections.map((item, newPos) => ({
                                ...item,
                                position: newPos
                              }));
                              onChange({ ...values, proposalModelSections: updated });
                            }}
                            hasProducts={hasProducts}
                          />
                        </div>
                      );
                    })
                  }

                  <Row className='m-6 text-darker-gray text-small'>
                    <Col xs={7} className='ps-8'>
                      <span>
                        {legalNameLabel}&nbsp;|&nbsp;{cpfCnpjLabel}
                        <br />
                        Endereço:&nbsp;{proposal?.address}
                      </span>
                    </Col>
                    <Col
                      xs={5}
                      className='d-inline-flex pe-8 justify-content-end align-items-center'
                    >
                      {
                        hidePoweredBy
                          ? null
                          : (
                            <span className='text-medium-gray'>
                              Powered by <img src={logoImage} width={67} height={17} />
                            </span>
                          )
                      }
                    </Col>
                  </Row>
                </>
              )
          }
          <div className='position-sticky bg-white shadow-up bottom-0 left-0 w-100 z-index-1030'>
            <div className='d-flex justify-content-end px-8 me-2 py-6'>
              <Button
                onClick={handleCancelWithIntercomTracker}
                disabled={processing || isSubmitting}
                className='me-2'
                variant='light'
              >
                Voltar
              </Button>
              <LoadingButton
                disabled={processing || isSubmitting}
                isLoading={processing || isSubmitting}
                loadingText='Aguarde...'
                onClick={handleSubmit}
              >
                Pré-visualizar
              </LoadingButton>
            </div>
          </div>
        </>
      )}
    </Form>
  );
}

export default ProposalForm;
