import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useAlert } from 'react-alert';
import { useNavigate, useLocation } from 'react-router-dom';
import { useQueryClient } from 'react-query';
import { useAuth } from '@/lib/auth';
import { useTracking } from '@/lib/tracking';
import sortBy from 'lodash/sortBy';
import APIErrorMessage from '@/components/APIErrorMessage';
import Modal from '@/components/Modal';
import ModalContent from '@/components/EntityModal/Deal/ModalContent';
import { useScreenSize } from '@/hooks';
import {
  ACTIVITY_TYPES,
  ENTITIES_TRACKER_SCREEN,
  getScreenSizeLabel,
  prepareTrackerIntentsFrom
} from '@/utils';
import {
  useHistoricSuggestions,
  useCreateActivityIntents,
  useInfiniteActivities,
  useDeal,
  useDealsCustomFields,
  usePeopleCustomFields,
  useOrganizationCustomFields,
  useOrganization,
  usePerson,
  useDeleteDeal,
  useUpdateDeal,
  useUpdatePerson,
  useUpdateOrganization,
  useStages,
  useCreateActivity,
  useUpdateActivity,
  useDeleteActivity,
  useVoipVerify,
  historicSuggestionKeys,
  funnelStagesKeys,
  activitiesKeys,
  dealsKeys,
  peopleKeys,
  organizationsKeys
} from '@/api';
import ModalError from '@/components/EntityModal/Deal/ModalError';

const PER_PAGE = 30;
const POLLING_TIMES = 15;
const POLLING_SWITCH = 5;
const POLLING_SECS_MAX = 5000;
const POLLING_SECS_MIN = 1000;

DealModal.defaultProps = {
  id: 0,
  show: false,
  onClose: () => {}
};

DealModal.propTypes = {
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  show: PropTypes.bool,
  onClose: PropTypes.func
};

/*
 * Funcao que cria uma key  dinamica, que é usada como query otimista para:
 * useCreateActivity e useCreateEmail, pois ambos retornam uma nova activity
 */
function createActivityListParams(entity, id) {
  return { per_page: PER_PAGE, entity_id: Number(id), entity };
}

function getEntityTypeByDeal(deal) {
  if (deal?.organization?.id) {
    return 'organization';
  }
  if (deal?.person?.id) {
    return 'person';
  }
  return '';
}

// eslint-disable-next-line complexity
function DealModal({ id, show, onClose }) {
  const [isModified, setIsModified] = useState(false);
  const [pollingTimes, setPollingTimes] = useState(0);
  const alert = useAlert();
  const screenSize = useScreenSize();
  const navigate = useNavigate();
  const location = useLocation();
  const { user } = useAuth();
  const tracker = useTracking();
  const { smartSuggestionsEnabled } = user?.features ?? {};
  const enabled = Boolean(show && id);
  const listParams = createActivityListParams('deal', id);
  const createActivityMutation = useCreateActivity({ listParams });
  const updateActivityMutation = useUpdateActivity();
  const deleteActivityMutation = useDeleteActivity();
  const updateDealMutation = useUpdateDeal();
  const deleteDealMutation = useDeleteDeal();
  const updatePersonMutation = useUpdatePerson();
  const updateOrganizationMutation = useUpdateOrganization();
  const createActivityIntentsMutation = useCreateActivityIntents();

  const {
    data: { data: deal } = {},
    isLoading: isLoadingDeal,
    isSuccess: isSuccessDeal,
    error: dealError
  } = useDeal({ dealId: id, config: { enabled, staleTime: 1000 } });
  const {
    data: voip,
    isLoading: isLoadingVoip
  } = useVoipVerify({ config: { enabled, staleTime: 1000 } });


  const {
    data: { data: dealCustomFields } = {},
    isLoading: isLoadingDealCustomFields
  } = useDealsCustomFields({ config: { enabled, staleTime: 1000 } });

  const isOrganizationEnabled = Boolean(deal?.organization?.id && enabled);
  const isPersonEnabled = Boolean(deal?.person?.id && enabled);
  const isFunnelStageEnabled = Boolean(deal?.funnel?.id && enabled);
  const isActivitiesEnabled = Boolean(deal?.id && enabled);
  const isSuggestionsEnabled = isActivitiesEnabled && pollingTimes < POLLING_TIMES;
  const suggestionsParams = { entity: 'deal', entity_id: Number(id) };
  const suggestionsPolling = pollingTimes < POLLING_SWITCH ? POLLING_SECS_MIN : POLLING_SECS_MAX;

  const {
    data: { data: organization } = {},
    isLoading: isLoadingOrganization,
    isSuccess: isOrganizationSuccess
  } = useOrganization({
    organizationId: deal?.organization?.id,
    config: { enabled: isOrganizationEnabled, staleTime: 1000 }
  });

  const {
    data: { data: person } = {},
    isLoading: isLoadingPerson,
    isSuccess: isPersonSuccess
  } = usePerson(
    { personId: deal?.person?.id,
      config: { enabled: isPersonEnabled, staleTime: 1000 } }
  );

  const {
    data: { data: organizationsCustomFields } = {},
    isLoading: isLoadingOrganizationsCustomFields
  } = useOrganizationCustomFields(
    { config: { enabled: isOrganizationEnabled, staleTime: 1000 } }
  );

  const {
    data: { data: peopleCustomFields } = {},
    isLoading: isLoadingPeopleCustomFields
  } = usePeopleCustomFields(
    { config: { enabled: isPersonEnabled, staleTime: 1000 } }
  );

  const {
    data: { data: stages } = {},
    isLoading: isLoadingFunnelStages
  } = useStages({
    params: { funnel_id_eq: deal?.funnel?.id },
    config: { enabled: isFunnelStageEnabled, staleTime: 1000 }
  });

  const {
    data: { pages: activityPages } = {},
    isLoading: isLoadingActivities,
    isFetching: isFetchingActivities,
    isFetchingNextPage: isFetchingNextPageActivities,
    fetchNextPage: fetchNextPageActivities,
    hasNextPage: hasNextPageActivities
  } = useInfiniteActivities({
    params: listParams,
    config: { enabled: isActivitiesEnabled, staleTime: 1000 }
  });

  const {
    data: { data: historicSuggestions } = {},
    isLoading: isLoadingHistoricSuggestions,
    isRefetching: isRefetchingHistoricSuggestions
  } = useHistoricSuggestions({
    params: suggestionsParams,
    config: {
      enabled: isSuggestionsEnabled,
      staleTime: 1000,
      refetchInterval: suggestionsPolling
    }
  });

  useEffect(() => {
    if (!isLoadingHistoricSuggestions && !isRefetchingHistoricSuggestions) {
      if (isActivitiesEnabled) {
        const refresh = (historicSuggestions ?? []).find(({ processing }) => processing);
        if (refresh) {
          setPollingTimes(pollingTimes + 1);
        } else {
          setPollingTimes(POLLING_TIMES);
        }
      }
    }
  }, [
    isRefetchingHistoricSuggestions,
    isLoadingHistoricSuggestions,
    isActivitiesEnabled
  ]);

  useEffect(() => {
    if (isSuccessDeal) {
      tracker.trackModalViewed({
        entityType: 'deal',
        user
      });
    }
  }, [isSuccessDeal]);

  const updateEntityMutation = (entity) => {
    if (entity.entityType === 'organization') {
      return updateOrganizationMutation;
    }
    if (entity.entityType === 'person') {
      return updatePersonMutation;
    }
    return null;
  };

  const getEntityId = (entity) => {
    if (entity.entityType === 'organization') {
      return { organizationId: entity.id };
    }
    if (entity.entityType === 'person') {
      return { personId: entity.id };
    }
    return null;
  };

  const getEntityQueryDetailKey = (entity) => {
    if (entity.entityType === 'organization') {
      return organizationsKeys.detail(entity.id);
    }
    if (entity.entityType === 'person') {
      return peopleKeys.detail(entity.id);
    }
    return null;
  };

  const activities = sortBy(activityPages?.flatMap((page) => page.data), 'createdAt').reverse();

  const entity = person || organization;
  const entityCustomFields = peopleCustomFields || organizationsCustomFields || [];
  const entityType = getEntityTypeByDeal(deal);
  const isLoadingEntity = isLoadingPerson || isLoadingOrganization;
  const isLoadingEntityCustomFields = (
    isLoadingPeopleCustomFields || isLoadingOrganizationsCustomFields
  );

  const isLoading = (
    isLoadingDeal || isLoadingDealCustomFields || isLoadingFunnelStages ||
    isLoadingEntity || isLoadingEntityCustomFields || isLoadingActivities ||
    isLoadingVoip
  );

  const queryClient = useQueryClient();
  const handleClose = () => {
    navigate(location?.pathname, { replace: true });
    onClose?.();
    /**
     * Para que eventuais alterações em atividades que são processadas
     * assincronamente (ex: cálculo de esquecidos) sejam refletidas depois
     * que a ficha for fechada.
     */
    if (isModified) {
      Promise.all([
        queryClient.invalidateQueries(dealsKeys.infiniteAll),
        queryClient.invalidateQueries(funnelStagesKeys.funnelList(deal?.funnel?.id)),
        queryClient.invalidateQueries(funnelStagesKeys.infiniteAll)
      ]);
    }
  };

  const canAnalyzeIntents = (activity) => (
    smartSuggestionsEnabled &&
    (activity?.finished || activity.type === ACTIVITY_TYPES.NOTE) &&
    (activity?.text || '').trim().split(' ').length >= 2
  );

  const handleCreateActivityIntents = (activity) => {
    if (canAnalyzeIntents(activity)) {
      const intentParams = { ...suggestionsParams, activity_id: activity.id };

      createActivityIntentsMutation.mutate(intentParams, {
        onSuccess: async ({ data: suggestions }) => {
          if (suggestions?.length) {
            setPollingTimes(0);
            const historicSuggestionListKey = historicSuggestionKeys.list(suggestionsParams);
            await queryClient.invalidateQueries(historicSuggestionListKey);

            tracker.trackSmartSuggestionPresented({
              user,
              activity,
              screen: ENTITIES_TRACKER_SCREEN.deal,
              intents: prepareTrackerIntentsFrom(suggestions)
            });
          }
        }
      });
    }
  };

  const onCreateActivity = (params, done) => {
    createActivityMutation.mutate(params, {
      onError: (err) => {
        alert.show(
          <APIErrorMessage err={err} resource='activities' action='create' />,
          { variant: 'danger', timeout: 5000 }
        );
        done?.(err);
      },
      onSuccess: ({ data }) => {
        alert.show(
          'Atividade cadastrada com sucesso!',
          { variant: 'success', timeout: 5000 }
        );
        handleCreateActivityIntents(data);
        setIsModified(true);
        done?.();
      }
    });
  };

  const onUpdateActivity = (activityId, params, done) => {
    updateActivityMutation.mutate({ activityId, params }, {
      onError: (err) => {
        alert.show(
          <APIErrorMessage err={err} resource='activities' action='update' />,
          { variant: 'danger', timeout: 5000 }
        );
        done?.(err);
      },
      onSuccess: ({ data }) => {
        alert.show(
          'Atividade atualizada com sucesso!',
          { variant: 'success', timeout: 5000 }
        );
        handleCreateActivityIntents(data);
        setIsModified(true);
        done?.();
      }
    });
  };

  const onDeleteActivity = (activityId, done) => {
    deleteActivityMutation.mutate({ activityId }, {
      onError: (err) => {
        alert.show(
          <APIErrorMessage err={err} resource='activities' action='destroy' />,
          { variant: 'danger', timeout: 5000 }
        );
        done?.(err);
      },
      onSuccess: () => {
        alert.show(
          'Atividade excluída com sucesso!',
          { variant: 'success', timeout: 5000 }
        );
        setIsModified(true);
        done?.();
      }
    });
  };

  const onCreateProposal = async (params, done) => {
    await queryClient.invalidateQueries(dealsKeys.detail(deal.id));
    onCreateActivity(params, done);
  };

  const onUpdate = (params, done) => {
    updateDealMutation.mutate({ dealId: id, params }, {
      onError: (err) => {
        alert.show(
          <APIErrorMessage err={err} resource='deal' action='update' />,
          { variant: 'danger', timeout: 5000 }
        );
        done?.(err);
      },
      onSuccess: () => {
        alert.show(
          'Negócio atualizado com sucesso!',
          { variant: 'success', timeout: 5000 }
        );
        setIsModified(true);
        done?.();
      }
    });
  };

  const onDelete = (_params, done) => {
    deleteDealMutation.mutate(
      { dealId: deal.id },
      {
        onError: (err) => {
          alert.show(
            <APIErrorMessage err={err} resource='deal' action='delete' />,
            { variant: 'danger', timeout: 5000 }
          );
          done?.(err);
        },
        onSuccess: () => {
          alert.show(
            'Negócio removido com sucesso!',
            { variant: 'success', timeout: 5000 }
          );
          setIsModified(true);
          done?.();
          handleClose();
        }
      }
    );
  };

  const onRefreshModal = () => {
    Promise.all([
      queryClient.invalidateQueries(dealsKeys.detail(deal.id)),
      queryClient.invalidateQueries(activitiesKeys.infiniteLists(listParams)),
      queryClient.invalidateQueries(organizationsKeys.detail(deal?.organization?.id))
    ]);
  };

  const onUpdateEntity = (params, done, entityData) => {
    const entityParamsId = getEntityId(entityData);
    const entityMutation = updateEntityMutation(entityData);
    const entityQueryKey = getEntityQueryDetailKey(entityData);

    entityMutation?.mutate({ ...entityParamsId, params }, {
      onMutate: async () => {
        await queryClient.cancelQueries(entityQueryKey);
      },
      onSettled: async () => {
        await queryClient.invalidateQueries(entityQueryKey);
      },
      onError: (error) => {
        alert.show(
          <APIErrorMessage err={error} resource={entityData.entityType} action='update' />,
          { variant: 'danger', timeout: 5000 }
        );
        done?.({ error });
      },
      onSuccess: async ({ data }) => {
        alert.show(
          'Contato atualizado com sucesso!',
          { variant: 'success', timeout: 5000 }
        );
        await Promise.all([
          queryClient.invalidateQueries(peopleKeys.detail(data?.id)),
          queryClient.invalidateQueries(organizationsKeys.detail(data?.id)),
          queryClient.invalidateQueries(organizationsKeys.detail(data?.organizationId))
        ]);
        done?.();
      }
    });
  };

  const modalSize = getScreenSizeLabel(screenSize);

  return (
    <Modal
      id='entity-modal'
      size={modalSize}
      baseClassName='entity-modal'
      bodyClassName='bg-light p-0 rounded'
      show={show}
      onHide={handleClose}
      onEntered={() => setIsModified(false)}
    >
      {
        dealError
          ? <ModalError onHide={handleClose} error={dealError} />
          : (
            <ModalContent
              activities={activities}
              deal={deal}
              voip={voip}
              historicSuggestions={historicSuggestions}
              dealCustomFields={dealCustomFields}
              entity={entity}
              entityType={entityType}
              entityCustomFields={entityCustomFields}
              isFetchingActivities={isFetchingActivities}
              isFetchingNextPageActivities={isFetchingNextPageActivities}
              fetchNextPageActivities={fetchNextPageActivities}
              hasNextPageActivities={hasNextPageActivities}
              stages={stages}
              loading={isLoading}
              onCreateProposal={onCreateProposal}
              onCreateActivity={onCreateActivity}
              onUpdateActivity={onUpdateActivity}
              onDeleteActivity={onDeleteActivity}
              onDelete={onDelete}
              onUpdate={onUpdate}
              isOrganizationSuccess={isOrganizationSuccess}
              isPersonSuccess={isPersonSuccess}
              onRefreshModal={onRefreshModal}
              onUpdateEntity={onUpdateEntity}
            />
          )
      }
    </Modal>
  );
}

export default DealModal;
