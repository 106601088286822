import React from 'react';
import EmptyContact from '@/components/EntityModal/EmptyContact';
import { isEmpty } from 'lodash';
import ContactListOrganization from '@/feature/whatsapp/ContactListOrganization';
import ContactListPerson from '@/feature/whatsapp/ContactListPerson';

function ContactListDeal({ entity, relatedEntity }) {
  if (isEmpty(relatedEntity)) {
    return <EmptyContact deal={entity} />;
  }

  const deepRelatedEntity = relatedEntity?.people || relatedEntity?.organization;

  const isRelatedEntityOrganization = entity?.relatedEntityType === 'organization';

  return (
    <>
      {
        isRelatedEntityOrganization
          ? <ContactListOrganization entity={relatedEntity} relatedEntity={deepRelatedEntity} />
          : <ContactListPerson entity={relatedEntity} relatedEntity={deepRelatedEntity} />
      }
    </>
  );
}

export default ContactListDeal;
