import React from 'react';
import PropTypes from 'prop-types';
import Link from '@/components/Link';
import Icon from '@/components/Icon';
import { dealPath, organizationPath, personPath } from '@/routes';

const propTypes = {
  activity: PropTypes.shape({
    deal: PropTypes.shape({
      id: PropTypes.number,
      title: PropTypes.string
    }),
    organization: PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string
    }),
    person: PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string
    })
  }).isRequired,
  entity: PropTypes.shape({
    id: PropTypes.number
  }).isRequired
};

const getEntityData = ({ entityData, entityType }) => {
  const entity = {
    deal: {
      path: dealPath(entityData.id),
      icon: 'deal',
      name: entityData.title
    },

    organization: {
      path: organizationPath(entityData.id),
      icon: 'organization',
      name: entityData.name
    },

    person: {
      path: personPath(entityData.id),
      icon: 'person',
      name: entityData.name
    }
  };

  return { ...entity[entityType], id: entityData.id };
};

const getEntityType = (activity) => {
  if (activity.deal) {
    return 'deal';
  }
  if (activity.organization) {
    return 'organization';
  }
  if (activity.person) {
    return 'person';
  }
};

function OriginalHeader(props) {
  const {
    activity,
    entity: currentEntity
  } = props;


  const entityType = getEntityType(activity);
  const entityData = activity[entityType] || {};

  const activityEntity = getEntityData({ entityData, entityType });

  if (activity.deal && (activityEntity.id === currentEntity.id)) {
    return null;
  }

  return (
    <div className='px-4 py-1 border-bottom border-1 text-small'>
      <RelatedEntityLink
        entityData={entityData}
      />
      <EntityLink
        path={activityEntity.path}
        icon={activityEntity.icon}
        name={activityEntity.name}
      />
    </div>
  );
}

const EntityLink = ({ path, icon, name }) => (
  <Link
    href={path}
    className='fw-bold link-dark-gray hover-text-primary'
  >
    <Icon name={icon} size='sm' className='me-2' />

    <span>{name}</span>
  </Link>
);

const RelatedEntityLink = ({ entityData }) => {
  const relatedEntityType = getEntityType(entityData);

  if (!relatedEntityType) {
    return null;
  }

  const relatedEntityData = getEntityData({
    entityType: relatedEntityType,
    entityData: entityData[relatedEntityType]
  });

  return (
    <>
      <EntityLink
        path={relatedEntityData.path}
        icon={relatedEntityData.icon}
        name={relatedEntityData.name}
      />
      <span className='mx-1'>|</span>
    </>
  );
};

OriginalHeader.propTypes = propTypes;

export default OriginalHeader;
