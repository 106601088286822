import React from 'react';
import PropTypes from 'prop-types';
import { useAlert } from 'react-alert';
import { useUpdateCurrentAccount } from '@/api';
import UpdateCpfCnpjForm from '@/feature/voip/UpdateCpfCnpjForm';
import Modal from '@/components/Modal';
import APIErrorMessage from '@/components/APIErrorMessage';
import cellphoneRinging from 'images/cellphone-ringing.png';

const propTypes = {
  show: PropTypes.bool,
  onHide: PropTypes.func,
  onUpdate: PropTypes.func,
  accountData: PropTypes.object,
  activatingVoip: PropTypes.bool
};

function VoipUpdateAccountModal({ show, onHide, onUpdate, accountData, activatingVoip }) {
  const alert = useAlert();

  const currentAccountMutation = useUpdateCurrentAccount();
  const isLoading = currentAccountMutation.isLoading || activatingVoip;

  const onSubmit = (values) => {
    currentAccountMutation.mutate(values, {
      onError: (err) => {
        alert.show(
          <APIErrorMessage err={err} />,
          { variant: 'danger', timeout: 5000 }
        );
      },
      onSuccess: () => {
        onUpdate?.();
      }
    });
  };

  const title = isLoading
    ? 'O telefone virtual está sendo ativado'
    : 'Ative o telefone virtual na sua conta';
  const subtitle = isLoading
    ? 'Adicionando R$ 25 de créditos para você experimentar...'
    : 'Preencha os dados abaixo para finalizar a ativação.';

  return (
    <Modal
      show={show}
      onHide={onHide}
      size='md'
      bodyClassName='p-8'
      backdrop='static'
    >
      <div className='d-flex flex-column'>
        <img src={cellphoneRinging} className='width-4 align-self-center mb-4' />
        <p className='h2 align-self-center mb-2'>{title}</p>
        <p className='align-self-center'>
          {subtitle}
        </p>

        <UpdateCpfCnpjForm
          accountData={accountData}
          onSubmit={onSubmit}
          onCancel={onHide}
          isSubmitting={isLoading}
        />
      </div>
    </Modal>
  );
}

VoipUpdateAccountModal.propTypes = propTypes;

export default VoipUpdateAccountModal;
